<section class="margem-top container">
  <h3>Procuração Eletrônica</h3>
  <p>Cidadãos com conta Gov.br com nível de confiabilidade Prata ou Ouro podem
    cadastrar uma procuração digital que permite que outra pessoa (outorgado)
    possa representá-lo (outorgante) ou a sua empresa a utilizar os serviços digitais
    disponíveis no Domicílio Eletrônico do Contribuinte em seu nome.</p>
  <div class="acoes">
    <div class="acoes__botoes">
      <button class="btn btn-primary" (click)="outorgar()">Outorgar</button>
    </div>
  </div>
  <div class="filtros">
    <div class="filtros__botoes">
      <span class="filtros__label">Filtros: </span>
      <div ngbDropdown>
        <button class="btn btn-primary" ngbDropdownToggle>Situação</button>
        <div class="modal_escuro" ngbDropdownMenu>
          <div class="margin-dropdown dropdown-tamanho">
              <div class="br-radio">
                <input
                  id="valida"
                  type="radio"
                  name="situacao"
                  value="0"
                  [(ngModel)]="situacao">
                <label for="valida">Válida</label>
              </div>
              <div class="br-radio">
                <input
                  id="revogada"
                  type="radio"
                  name="situacao"
                  value="1"
                  [(ngModel)]="situacao">
              <label for="revogada">Revogada</label>
            </div>
          </div>
          <button class="btn btn-primary btn-filtro" [disabled]="!situacao" (click)="filtroSituacao()">Aplicar</button>
        </div>
      </div>
      <div ngbDropdown>
        <button class="btn btn-primary" ngbDropdownToggle>Período Outorga</button>
        <div ngbDropdownMenu>
          <div class="titulo">
            <span class="titulo__string">Período</span>
          </div>
          <div class="margin-dropdown dropdown-tamanho__datepicker datepicker-flex">
            <dp-day-calendar
              theme="dp-material"
              [(ngModel)]="dataInicio"
              (onSelect)="getDataInicio($event)"
              [displayDate]="estadoDataInicial"
              #dayPicker
            ></dp-day-calendar>
            <dp-day-calendar
              theme="dp-material"
              [(ngModel)]="dataFim"
              (onSelect)="getDataFim($event)"
              [displayDate]="estadoDataInicial"
              (minDate)="dataInicio"
              #dayPicker
            ></dp-day-calendar>
          </div>
          <button class="btn btn-primary btn-filtro" [disabled]="(!dataFim || !dataInicio)" (click)="filtroSituacao()">Aplicar</button>
        </div>
      </div>
      <div ngbDropdown>
        <button class="btn btn-primary" ngbDropdownToggle>Outorgado</button>
        <div class="modal_escuro" ngbDropdownMenu>
          <div class="margin-dropdown dropdown-tamanho">
            <div class="br-input">
              <label for="input-medium">Outorgado</label>
              <input type="text" id="input-medium" placeholder="Digite aqui..." [(ngModel)]="outorgado" />
            </div>
          </div>
          <button class="btn btn-primary btn-filtro" [disabled]="!outorgado" (click)="filtroSituacao()">Aplicar</button>
        </div>
      </div>
      <div ngbDropdown>
        <button class="btn btn-primary" ngbDropdownToggle>Relação</button>
        <div class="modal_escuro" ngbDropdownMenu>
          <div class="margin-dropdown dropdown-tamanho">
            <select class="select" [(ngModel)]="relacao">
              <option value="null" disabled>Selecione a Relação</option>
              <option *ngFor="let item of relacoes" [ngValue]="item.codTipoProcuracao">{{ item.dscTipoProcuracao }}</option>
            </select>
          </div>
          <button class="btn btn-primary btn-filtro" [disabled]="relacao == null || undefined || ''" (click)="filtroSituacao()">Aplicar</button>
        </div>
      </div>
        <button class="btn btn-secondary btn-filtro" (click)="limparFiltro()" *ngIf="filtroAtivo"> Limpar Filtro</button>
    </div>
  </div>
  <div class="br-table margin-table">
    <div class="table-header">
      <div class="top-bar">
        <div class="table-title">Tabela de Procurações</div>
      </div>
    </div>
  </div>
  <table>
    <thead>
      <tr>
        <th scope="col">Protocolo</th>
        <th scope="col">Situação</th>
        <th scope="col">Data Outorga</th>
        <th scope="col">Data Início Vigência</th>
        <th scope="col">Data da Revogação</th>
        <th scope="col">Data Fim Vigência</th>
        <th scope="col">Outorgante</th>
        <th scope="col">Outorgado</th>
        <th scope="col">Relação</th>
        <th scope="col">Documento</th>
        <th scope="col">Ações</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="procuracoes?.length; else semDados">
        <tr *ngFor="let procuracao of procuracoes">
          <td>{{ procuracao?.id }}</td>
          <td>{{ procuracao?.numeracaoRevogada == 0 ? 'Válida' : 'Revogada' }}</td>
          <td>{{ procuracao?.dataOutorga | date: 'dd/MM/yyyy' }}</td>
          <td>{{ procuracao?.dataInicio ? (procuracao.dataInicio | date: 'dd/MM/yyyy'): 'Não Informado' }}</td>
          <td>{{ procuracao?.dataRevoga ? (procuracao.dataRevoga | date: 'dd/MM/yyyy') : 'Não Informado' }}</td>
          <td>{{ procuracao?.dataFim ? (procuracao.dataFim | date: 'dd/MM/yyyy') : 'Não Informado' }}</td>
          <td>{{ procuracao?.contribuinte.dscRazaoSocial }}</td>
          <td>{{ procuracao?.outorgado.dscNomeCadastro }}</td>
          <td>{{ procuracao?.tipoProcuracao.dscTipoProcuracao }}</td>
          <td>
            <img 
              class="image"
              [src]="modoEscuro ? '../../../assets/images/icones/white-file-pdf-regular.svg' : '../../../assets/images/icones/file-pdf-regular.svg'"
              alt="Baixa PDF"
              title="Baixar Procuração"
              (click)="baixarDocumento(procuracao)"
              [ngClass]="{ 'disabled': procuracao.numeracaoRevogada == 1}"></td>
          <td>
            <img 
            class="image__revogar" 
            [src]="modoEscuro ? '../../../assets/images/icones/white-thumbs-down-regular.svg' : '../../../assets/images/icones/thumbs-down-regular.svg'" 
            alt="Revogar Procuração" 
            title="Revogar Procuração" 
            (click)="revogar(procuracao)"></td>
        </tr>
      </ng-container>
      <ng-template #semDados>
        <tr>
          <td colspan="10" align="center">Sem dados para serem exibidos</td>
        </tr>
      </ng-template>
    </tbody>
  </table>
  <div class="container-paginacao">
    <button class="br-button secondary mt-5 button-first" (click)="primeiraPagina()" [disabled]="pagina == 1 || pagina == 0" [ngClass]="{ disabled: pagina == 1, 'botao-proibido': pagina == 1}">Primeira</button>
    <button class="br-button secondary mt-5 button-first" (click)="paginaAnterior()" [disabled]="pagina == 1 || pagina == 0" [ngClass]="{ disabled: pagina == 1, 'botao-proibido': pagina == 1}">Anterior</button>
    <p class="mt-5">{{ pagina }} de {{ quantidadePaginas }}</p>
    <button class="br-button secondary mt-5  button-first" (click)="proximaPagina()" [disabled]="pagina == quantidadePaginas || quantidadePaginas == 0" [ngClass]="{ disabled: pagina == quantidadePaginas, 'botao-proibido': pagina == quantidadePaginas }">Próxima</button>
    <button class="br-button secondary mt-5 button-first" (click)="ultimaPagina()" [disabled]="pagina == quantidadePaginas || quantidadePaginas == 0" [ngClass]="{ disabled: pagina == quantidadePaginas, 'botao-proibido': pagina == quantidadePaginas }">Última</button>
  </div>
  <div class="carregamento" *ngIf="loading">
    <img src="../../../assets/images/icones/logo-animado.svg">
  </div>
  <div class="div-botao">
    <button class="br-button secondary mt-5 mr-4 posicao-botao" type="button" (click)="scroll()" [routerLink]="['/inicio']">Retornar</button>
  </div>
</section>
<div class="backdrop" *ngIf="modalAtivo">
  <div class="div br-modal xx-large position-modal">
    <div class="br-modal-header">Documento da Revogação
    </div>
      <div class="br-modal-body">
        <div class="pdf-container">
          <pdf-viewer
            [src]="docArrayBuffer"
            [rotation]="0"
            [original-size]="false"
            [show-all]="true"
            [fit-to-page]="false"
            [zoom]="0.5"
            [zoom-scale]="'page-width'"
            [stick-to-page]="false"
            [render-text]="true"
            [external-link-target]="'blank'"
            [autoresize]="true"
            [show-borders]="false"
            style="width: 100%; height: 600px;"
          >
          </pdf-viewer>
        </div>
      <div class="br-modal-footer justify-content-center">
        <button class="br-button secondary" type="button" (click)="fecharModal()">Retornar para Procurações</button>
      </div>
  </div>
</div>