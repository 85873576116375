<div class="ml-5">
  <h3>Identificação do Produtor Rural</h3>
  <form [formGroup]="form">
    <div class="row">
      <div class="col-6">
        <label class="mb-3">Tipo de Contribuinte<span class="obrigatorio label">*</span></label>
        <div class="br-radio">
          <input class="radio-disabled" id="fisica" type="radio" [value]="1" formControlName="tipoContribuinte">
          <label class="mr-5" for="fisica">Pessoa Física</label>
          <input id="juridica" type="radio" [value]="2" formControlName="tipoContribuinte">
          <label for="juridica">Pessoa Jurídica</label>
        </div>
      </div>
      <div class="col-6">
        <div class="br-input">
          <label>Informar o Cadastro de Pessoa Física (C.P.F)<span class="obrigatorio label">*</span></label>
          <input 
            type="text" formControlName="inscricaoCpf" mask="CPF_CNPJ" 
            (blur)="verificarExistenciaCpfCnpj(form.controls.inscricaoCpf.value); validarDigitoCpf()"
            maxlength="14"
          >
        </div>
        <p 
          class="mensagem-erro" 
          *ngIf="form.controls.inscricaoCpf.invalid && form.controls.inscricaoCpf.touched"
        >
          Campo obrigatório / C.P.F Inválido
        </p>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col">
        <div class="br-input">
          <label>Nome Completo<span class="obrigatorio label">*</span></label>
          <input type="text" formControlName="nomeRazaoSocial" maxlength="400">
        </div>
        <p 
          class="mensagem-erro" 
          *ngIf="form.controls.nomeRazaoSocial.invalid && form.controls.nomeRazaoSocial.touched"
        >
          Campo obrigatório / Mínimo 2 dígitos
        </p>
      </div>
    </div>
    <h5>Atividade</h5>
    <div class="row">
      <div class="col-4">
        <div class="br-input">
          <label>Data de Início<span class="obrigatorio label">*</span></label>
        </div>
        <dp-date-picker 
            theme="dp-material"
            [config]="datePickerConfig"
            formControlName="dataInicio"
            [(ngModel)]="datInicio"
            (onChange)="getDataInicio($event)"
          >
        </dp-date-picker>
        <p 
          class="mensagem-erro" 
          *ngIf="form.controls.dataInicio.invalid && form.controls.dataInicio.touched"
        >
          Campo obrigatório
        </p>
      </div>
      <div class="col-4">
        <div class="br-input">
          <label>Término da Atividade<span class="igualar-altura label">*</span></label>
        </div>
        <dp-date-picker 
            theme="dp-material"
            [config]="datePickerConfig"
            formControlName="dataTermino"
            [(ngModel)]="datFim"
            (onChange)="getDataFim($event)"
          >
        </dp-date-picker>
      </div>
    </div>
    <div class="div-botao mt-4">
      <button class="br-button primary posicao-botao" type="button" (click)="proximaEtapa()" [disabled]="!form.valid">Próxima Etapa</button>
      <button class="br-button secondary posicao-botao mr-4" type="button" (click)="retornarEtapa()">Voltar Etapa</button>
      <button class="br-button primary posicao-botao mr-4 desistir" type="button" (click)="desistir()">Desistir</button>
    </div>
  </form>
</div>


<div class="carregamento" *ngIf="loading">
  <img src="../../../assets/images/icones/logo-animado.svg">
</div>
<app-loading
  *ngIf="loadingReceita"
  [mensagem]="'Processando análise junto a Receita Federal, aguarde...'"
>
</app-loading>