import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class nfaService {

  modalNfa = new BehaviorSubject<boolean>(false);
  constructor() {}

  abrirFecharNfa() {
    this.modalNfa.next(true);
  }
}