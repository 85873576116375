import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})

export class BreadcrumbComponent implements OnInit {

  config: any = null;
  breadcrumbs: any = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {

  }

  ngOnInit(): void {
    this.config = this.router.config;    
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        this.breadcrumbs = [];
        let currentRoute = this.activatedRoute.root, url = '';      
        do {
          const childrenRoutes = currentRoute.children;
          currentRoute = null as any;
          childrenRoutes.forEach(route => {
            if (route.outlet === 'primary') {
              const routeSnapshot = route.snapshot;
              url += '/' + routeSnapshot.url.map(segment => segment.path).join('/');
              let label = null;
              if (route.snapshot.data.breadcrumb) {
                label = route.snapshot.data.breadcrumb;
                this.breadcrumbs.push({
                  label: label,
                  url: url
                });
                console.log(this.breadcrumbs);
                
              }
              currentRoute = route; 
            }
          });
        } while (currentRoute);
      })      
  }

  redirecionarHome() {
    if (this.router.url === '/pre-acesso') {
      this.router.navigate(['/home']);
    } else {
      this.router.navigate(['/inicio']);
    }
  }
}