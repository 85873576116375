import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { IDatePickerConfig } from "ng2-date-picker";
import { ProdutorRuralAlterar, SocioDTO } from "src/app/features/common/interface/produtorRuralAlterar";
import { ProdutorRuralService } from "src/app/services/produtor-rural.service";
import Swal from "sweetalert2";
import * as dayjs from "dayjs";
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { ProcuracaoService } from "src/app/services/procuracao.service";

@Component({
  selector: 'app-alteracao-produtor-etapa-9',
  templateUrl: './etapa-9.component.html',
  styleUrls: ['./etapa-9.component.scss']
})
export class Etapa9Component  implements OnInit {
  @ViewChild('cep') cep: ElementRef;

  @Output() passarEtapa = new EventEmitter();

  @Output() voltarEtapa = new EventEmitter();

  @Output() sair = new EventEmitter();

  @Input() produtorRural: any;

  @Input() listaPaises: any;

  @Input() listaGed: any;

  @Input() listaComplemento: any;

  @Input() dadosEditados: ProdutorRuralAlterar;

  datePickerConfig: IDatePickerConfig = {
    disableKeypress: true,
    drops: "down",
    format: 'DD/MM/YYYY'
  }
  liberarAdicao: boolean = true;
  participacaoCounter: number = 0;
  sociosAberto: boolean = false;
  socios: any = [];
  pais: number = 30;
  dataAtual: Date = new Date();
  dataInicio: any;
  dataFim: any;
  form: FormGroup;
  loading: boolean = false;
  loadingReceita: boolean = false;

  constructor(
    private produtorRuralService: ProdutorRuralService,
    private procuracaoService: ProcuracaoService,
    private formBuilder: FormBuilder
  ) {

  }

  ngOnInit(): void {
    this.criarFormulario();
    if (this.dadosEditados.passo > 9) {
      this.socios = this.dadosEditados.socioDTO;
    } else {
      this.socios = this.produtorRural.socioDTO;
    }
    this.calcularParticipacao();
  }

  calcularParticipacao() {
    for (let i = 0; i < this.socios.length; i++) {
      this.participacaoCounter += this.socios[i].participacao;
    }
    if (this.participacaoCounter == 100) {
      this.liberarAdicao = false;
    }
  }

  criarFormulario() {
    this.form = this.formBuilder.group({
      pais: ['', Validators.required],
      tipoDocumento: ['', Validators.required],
      documento: ['', Validators.required],
      cpfCnpj: ['', Validators.required],
      nome: ['', Validators.required],
      participacao: ['', Validators.required],
      tipoPessoa: ['', Validators.required],
      administrador: ['', Validators.required],
      dataEntrada: ['', Validators.required],
      dataSaida: {value: null, disabled: true},
      enderecoCompleto: null,
      cep: [null, Validators.required],
      logradouro: '',
      numero: [null, Validators.required],
      tipoComplemento: null,
      complemento: null,
      latitude: [null, [Validators.min(-90), Validators.max(90)]],
      longitude: [null, [Validators.min(-180), Validators.max(180)]]
    })
  }

  abrirModal() {
    this.sociosAberto = true;
  }

  fecharModal() {
    this.sociosAberto = false;
      this.form.reset();
      this.pais = 30;
      this.verificarPais();
      // if (this.informacoesExploracaoForm.controls.categoriaOcupacao.value == 1) {
      //   this.form.controls.tipoPessoa.setValue('1');
      //   this.form.controls.participacao.setValue(100);
      //   this.form.controls.tipoPessoa.disable();
      //   this.form.controls.participacao.disable();
      // }
  }

  validarDigitoCpfCnpj() {
    if (cpf.isValid(this.form.controls.cpfCnpj.value) == false && this.form.controls.cpfCnpj.value.length == 11) {
      this.form.controls.cpfCnpj.setErrors({'invalid': true});
      this.form.controls.nome.reset();
      this.form.controls.nome.enable();
    } else if (
      (this.form.controls.cpfCnpj.value.length > 0 && this.form.controls.cpfCnpj.value.length < 11
      || this.form.controls.cpfCnpj.value.length > 12 && this.form.controls.cpfCnpj.value.length < 14)
    ) {
      this.form.controls.cpfCnpj.setErrors({'invalid': true});
      this.form.controls.nome.reset();
      this.form.controls.nome.enable()
    } else if (cnpj.isValid(this.form.controls.cpfCnpj.value) == false && this.form.controls.cpfCnpj.value.length == 14) {
      this.form.controls.cpfCnpj.setErrors({'invalid': true});
      this.form.controls.nome.reset();
      this.form.controls.nome.enable();
    } else {
      this.form.controls.cpfCnpj.setErrors(null);
      this.form.controls.nome.setValue('');
      this.form.controls.nome.enable();
      this.verificarExistenciaCpfCnpj(this.form.controls.cpfCnpj.value);
    }
  }
  
  cpfCnpjMask(cpfCnpj) {
    if (cpfCnpj.length == 11) {
      return cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
    } else {
      return cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");
    }
  }

  verificarExistenciaCpfCnpj(cpfCnpj) {
    if ((cpfCnpj.length == 11 || cpfCnpj.length == 14) && !this.form.controls.cpfCnpj.invalid) {
      this.loadingReceita = true;
      this.produtorRuralService.consultarCpfReceita(cpfCnpj).subscribe(
        res => {
          this.loadingReceita = false;
        },
        err => {
          this.loadingReceita = false;
          Swal.fire({
            icon: 'warning',
            text: 'Análise junto a Receita Federal não foi possível.',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.procuracaoService.getCpf(cpfCnpj).subscribe(
                res => {
                  let data: any = res;
                  if (data.length != 0) {
                    let data: any = res;
                    let cpf = this.cpfCnpjMask(data[0].cpfCnpj);
                    Swal.fire({
                      icon: 'info',
                      html: `
                      <p>Atenção: Foi encontrado o C.P.F. com os dados informados na SEFAZ TO.</p>
                      <p>C.P.F: ${cpf}</p>
                      <p>Nome: ${data[0].dscNomeCadastro}</p>
                    `,
                    confirmButtonText: 'Utilizar os dados encontrados',
                    showCancelButton: true,
                    cancelButtonText: 'Estes dados estão inconsistentes, não utilizar',
                    allowOutsideClick: false
                    }).then((result) => {
                      if (result.isConfirmed) {
                        this.loading = false;
                        this.form.controls.nome.setValue(data[0].dscNomeCadastro);
                        this.form.controls.nome.disable();
                      } else if (result.isDismissed) {
                        Swal.fire({
                          icon: 'info',
                          text: `Atenção: Será permitida a digitação dos dados que
                            serão validados posteriormente pela Auditoria Fiscal.`
                        })
                      }
                    })
                  }
                },
                err => {
                  Swal.fire({
                    icon: 'error',
                    text: 'Ocorreu um erro ao validar os dados, tente novamente.'
                  })
                }
              )
            }
          })
        }
      )
    }
  }

  adicionarSocio() {
    let obj = {
      tipoGed: {
        codigo: this.form.controls.tipoDocumento.value
      },
      dscDocumento: this.form.controls.documento.value,
      dscCpfCnpj: this.form.controls.cpfCnpj.value,
      dscNomeSocio: this.form.controls.nome.value,
      participacao: this.form.controls.participacao.value,
      datInicio: dayjs(this.dataInicio).format('YYYY-MM-DD'),
      administrador: this.form.controls.administrador.value,
      numTipoPessoa: this.form.controls.tipoPessoa.value,
      logradouro: this.form.controls.logradouro.value,
      numero: this.form.controls.numero.value,
      socioEndereco: {
        endereco: {
          dscNumero: this.form.controls.numero.value,
          dscComplemento: this.form.controls.complemento.value,
          dscEnderecoExterior: this.form.controls.enderecoCompleto.value,
          cep: {
            codCep: this.form.controls.cep.value
          },
          pais: {
            codigo: this.form.controls.pais.value
          },
          tipoComplemento: {
            codigo: null
          },
          latitude: this.form.controls.latitude.value,
          longitute: this.form.controls.longitude.value,
          naturezaImovel: {
            codigo: 2
          },
        },
        tipoEndereco: 2
      }
    }
    this.form.controls.tipoComplemento.value
    ? obj.socioEndereco.endereco.tipoComplemento.codigo = this.listaComplemento[this.form.controls.tipoComplemento.value].codigo
    : '';
    
    this.participacaoCounter += this.form.controls.participacao.value;

    if (this.participacaoCounter > 100) {
      this.participacaoCounter -= this.form.controls.participacao.value;
      this.form.controls.participacao.setErrors({invalid: true});
      return
    }
    this.socios.push(obj);
    this.fecharModal();
    this.form.reset();
    this.pais = 30;
    this.verificarPais();

    if (this.dadosEditados.imovelDTO.tipoUsoImovel.codigo == 1 && this.socios.length == 1) {
      this.liberarAdicao = false;
      console.log('passou');
      
    }

    if (this.participacaoCounter == 100) {
      this.liberarAdicao = false;
    }
  }
  
  deletarSocio(index) {
    this.participacaoCounter -= this.socios[index].participacao;
    this.socios.splice(index, 1);

    if (this.participacaoCounter < 100) {
      this.liberarAdicao = true;
    }

    if (this.dadosEditados.imovelDTO.tipoUsoImovel.codigo == 1 && this.socios.length == 0) {
      this.liberarAdicao = true;
    }

    if (this.dadosEditados.imovelDTO.tipoUsoImovel.codigo == 1) {
      this.form.controls.tipoPessoa.setValue('1');
      this.form.controls.participacao.setValue(100);
      this.form.controls.tipoPessoa.disable();
      this.form.controls.participacao.disable();
    }
  }

  verificarPais() {
    if (this.pais !== 30) {
      this.form.controls.enderecoCompleto.addValidators(Validators.required);
      this.form.controls.cep.removeValidators(Validators.required);
      this.form.controls.numero.removeValidators(Validators.required);
      this.form.controls.cep.updateValueAndValidity();
      this.form.controls.numero.updateValueAndValidity();
      this.form.controls.enderecoCompleto.updateValueAndValidity();
    } else {
      this.form.controls.cep.addValidators(Validators.required);
      this.form.controls.numero.addValidators(Validators.required);
      this.form.controls.enderecoCompleto.removeValidators(Validators.required);
      this.form.controls.cep.updateValueAndValidity();
      this.form.controls.numero.updateValueAndValidity();
      this.form.controls.enderecoCompleto.updateValueAndValidity();
    }
  }

  getDataInicio(event) {
    if (this.dataInicio && this.dataInicio >= this.dataAtual) {
      this.form.controls.dataEntrada.reset();
      this.form.controls.dataSaida.reset();
      Swal.fire({
        icon: 'warning',
        text: 'Data de Início não pode ser maior que a data atual.'
      })
    } else {
      this.dataInicio = event;
    }
  }

  getDataFim(event) {
    if (this.dataFim && (this.dataFim <= this.dataInicio || this.dataFim >= this.dataAtual)) {
      this.form.controls.dataSaida.reset();
      Swal.fire({
        icon: 'warning',
        text: 'Término da Atividade deve ser maior que a Data de Início e menor que a data atual.'
      })
    } else {
      this.dataFim = event;
    }
  }

  validarCep() {
    this.loading = true;
    this.produtorRuralService.consultarCep(this.form.controls.cep.value).subscribe(
      res => {
        this.form.controls.logradouro.setValue(res['dscLogradouro']);
        this.form.controls.logradouro.disable();
      },
      err => {
        Swal.fire({
          icon: 'error',
          text: 'Atenção: C.E.P não encontrado/válido. Informar um próximo ou genérico da cidade.'
        }).then((result) => {
          if (result.isConfirmed) {
            this.form.controls.cep.reset();
            this.cep.nativeElement.focus();
          }
        })
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    )
  }

  validarTipoComplemento() {
    this.form.controls.complemento.setValidators(Validators.required);
    this.form.controls.complemento.updateValueAndValidity();
  }

  proximoPasso() {
    this.dadosEditados.socioDTO = this.socios;
    this.dadosEditados.passo = 10;
    this.passarEtapa.emit();
  }

  voltarPasso() {
    this.voltarEtapa.emit();
  }

  desistir() {
    this.sair.emit();
  }

}