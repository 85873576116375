<div class="ml-5">
  <h3>Informações sobre a exploração rural</h3>
  <form [formGroup]="form">
    <div class="row">
      <div class="col-4">
        <div class="br-input">
          <label>Categoria de Ocupação<span class="obrigatorio label">*</span></label>
          <input type="text" class="input" disabled />
          <select class="select" formControlName="categoriaOcupacao" (change)="validarCategoriaOcupacao()">
            <option value="" selected disabled>Selecione a Categoria de Ocupação...</option>
            <option *ngFor="let item of listaOcupacao" [ngValue]="item.codigo">{{ item.descricao }}</option>
          </select>
        </div>
        <p 
          class="mensagem-erro" 
          *ngIf="form.controls.categoriaOcupacao.invalid && form.controls.categoriaOcupacao.touched"
        >
          Campo obrigatório
        </p>
      </div>
      <div class="col-4">
        <div class="br-input">
          <label>Data de aquisição<span class="obrigatorio label">*</span></label>
        </div>
        <dp-date-picker
          theme="dp-material"
          [config]="datePickerConfig"
          formControlName="dataAquisicao"
          [(ngModel)]="dataAquisicao"
          (onChange)="getDataInicio($event)"
        >
        </dp-date-picker>
        <p 
          class="mensagem-erro" 
          *ngIf="form.controls.dataAquisicao.invalid && form.controls.dataAquisicao.touched"
        >
          Campo obrigatório
        </p>
      </div>
      <div class="col-4">
        <div class="br-input">
          <label>Inscrição no INCRA<span class="obrigatorio label">*</span></label>
          <input type="text" formControlName="inscricaoIncra" maxlength="13">
        </div>
        <p 
          class="mensagem-erro" 
          *ngIf="form.controls.inscricaoIncra.invalid && form.controls.inscricaoIncra.touched"
        >
          Campo obrigatório
        </p>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-6">
        <div class="br-input">
          <label>Nome do Imóvel<span class="obrigatorio label">*</span></label>
          <input type="text" formControlName="nomeImovel" maxlength="150">
        </div>
        <p 
          class="mensagem-erro" 
          *ngIf="form.controls.nomeImovel.invalid && form.controls.nomeImovel.touched"
        >
          Campo obrigatório / Mínimo 2 dígitos
        </p>
      </div>
      <div class="col-3">
        <div class="br-input">
          <label>Área do Imóvel (ha)<span class="obrigatorio label">*</span></label>
          <input type="text" formControlName="areaImovel" mask="separator.1" thousandSeparator="." decimalMarker=",">
        </div>
        <p 
          class="mensagem-erro" 
          *ngIf="form.controls.areaImovel.invalid && form.controls.areaImovel.touched
            && !form.controls.areaImovel.hasError('soma')"
        >
          Campo obrigatório
        </p>
        <p 
          class="mensagem-erro" 
          *ngIf="form.controls.areaImovel.hasError('soma')"
        >
          Soma das demais áreas não deve ser maior que a Área do Imóvel.
        </p>
      </div>
      <div class="col-3">
        <div class="br-input">
          <label>Área de pastagem natural<span class="igualar-altura label">*</span></label>
          <input type="text" formControlName="areaPastagemNatural" mask="separator.1" thousandSeparator="." decimalMarker=",">
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-3">
        <div class="br-input">
          <label>Área de pastagem formada<span class="igualar-altura label">*</span></label>
          <input type="text" formControlName="areaPastagemFormada" mask="separator.1" thousandSeparator="." decimalMarker=",">
        </div>
      </div>
      <div class="col-3">
        <div class="br-input">
          <label>Área inexplorada<span class="igualar-altura label">*</span></label>
          <input type="text" formControlName="areaInexplorada" mask="separator.1" thousandSeparator="." decimalMarker=",">
        </div>
      </div>
      <div class="col-3">
        <div class="br-input">
          <label>Área cultivada<span class="igualar-altura label">*</span></label>
          <input type="text" formControlName="areaCultivada" mask="separator.1" thousandSeparator="." decimalMarker=",">
        </div>
      </div>
      <div class="col-3">
        <div class="br-input">
          <label>Valor estimado do imóvel<span class="obrigatorio label">*</span></label>
          <input type="text" formControlName="valorEstimadoImovel" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }">
        </div>
        <p 
          class="mensagem-erro" 
          *ngIf="form.controls.valorEstimadoImovel.invalid && form.controls.valorEstimadoImovel.touched"
        >
          Campo obrigatório
        </p>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-6">
        <div class="br-input">
          <label>Observação<span class="igualar-altura label">*</span></label>
          <input type="text" formControlName="observacao">
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-8">
        <div class="br-upload">
          <label class="upload-label">
            Anexar Documentos CAR, CCIR (Certificado de Cadastro de Imóvel Rural) e outros para agilizar a aprovação da solicitação
          </label>
          <input class="upload-input" type="file" multiple="multiple" accept="image/jpg, image/jpeg, .pdf, .gif" #file_input (change)="anexo($event)" />
          <button class="upload-button" type="button" (click)="file_input.click()">
            <fa-icon [icon]="['fas', 'upload']">
            </fa-icon>
            <span>Selecione o arquivo</span>
          </button>
          <p class="mensagem-erro mt-2" *ngIf="!arquivoValido">Formato de arquivo inválido(Formatos válidos: PDF, JPEG, JPG, GIF)</p>
          <div class="upload-list mt-2" *ngFor="let item of arquivos">{{ item.ged.nomeArquivo }} 
          </div>
          <div class="upload-list mt-2" *ngFor="let item of arquivosNovos; let i = index">{{ item.name }} 
            <fa-icon class="lixeira" [icon]="['fas', 'trash']" (click)="deletarArquivo(i)"></fa-icon></div>
          </div>
      </div>
    </div>
    <div class="div-botao mt-4">
      <button class="br-button primary posicao-botao" type="button" (click)="proximoPasso()" [disabled]="!form.valid">Próxima Etapa</button>
      <button class="br-button secondary posicao-botao mr-4" type="button" (click)="voltarPasso()">Voltar Etapa</button>
      <button class="br-button primary posicao-botao mr-4 desistir" type="button" (click)="desistir()">Desistir</button>
    </div>
  </form>
</div>

<div class="backdrop" *ngIf="carAberto">
  <div class="div br-modal large position-modal">
    <div class="br-modal-header">Consultar demonstrativo do CAR</div>
    <div class="br-modal-body overflow">
      <form>
        <div class="row">
          <div class="col">
            <div class="br-input pb-1">
              <label>Informe o número de registro do Cadastro Ambiental Rural (CAR) ou número de protocolo:</label>
              <input type="text" class="input" [(ngModel)]="car" />
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="br-modal-footer justify-content-center">
      <button class="br-button primary desistir mr-4" type="button" (click)="fecharModal()">Desistir</button>
      <button class="br-button primary" type="button" (click)="buscarCar()">Consultar</button>
    </div>
  </div>
</div>