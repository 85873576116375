import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { DpDatePickerModule } from "ng2-date-picker";
import { NgxMaskModule } from "ngx-mask";
import { DropdownModule } from "primeng/dropdown";
import { CommonAppModule } from "src/app/features/common/common.module";
import { SuspensaoProdutorRuralComponent } from "./suspensao.component";
import { Etapa1Component } from "./etapa-1/etapa-1.component";
import { Etapa2Component } from "./etapa-2/etapa-2.component";
import { CadastroRoutingModule } from "../../../cadastro.routing.module";
import { Etapa3Component } from "./etapa-3/etapa-3.component";
import { Etapa4Component } from "./etapa-4/etapa-4.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DpDatePickerModule,
    DropdownModule,
    NgxMaskModule,
    CurrencyMaskModule,
    FontAwesomeModule,
    CommonAppModule,
    CadastroRoutingModule
  ],
  declarations: [
    SuspensaoProdutorRuralComponent,
    Etapa1Component,
    Etapa2Component,
    Etapa3Component,
    Etapa4Component
  ]
})
export class SuspensaoProdutorRuralModule {

}