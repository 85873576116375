import { Component, OnInit } from "@angular/core";
import { UsuarioLogadoService } from "src/app/services/usuarioLogado.service";

@Component({
  selector: 'app-produtor-rural-menu',
  templateUrl: './produtor-rural-menu.component.html',
  styleUrls: ['./produtor-rural-menu.component.scss']
})
export class ProdutorRuralMenuComponent implements OnInit {

  cadastroEmUso: boolean = false;

  constructor(
    private usuarioLogadoService: UsuarioLogadoService
  ) {

  }

  ngOnInit(): void {
    let obj = this.usuarioLogadoService.getUsuarioLogado();
    this.cadastroEmUso = obj.cadastroEmUso;
  }

}