<div class="container">
  <h1>Validar Comunicado Eletrônico</h1>
  <div class="br-input">
    <input
      placeholder="Digite o hash para ser consultado"
      [(ngModel)]="hash"
      type="text"
      class="input"
    >
  </div>
  <div>
    <button 
      class="br-button primary mt-3" 
      type="button" 
      (click)="validarHash()"
      [disabled]="hash.length == 0"
    >Validar
    </button>
  </div>
  <div class="mt-5" *ngIf="hashConsultado && !hashInvalido; else elseblock">
    <p class="dados"><strong>Tipo de Protocolo: </strong>{{ dadosProtocolo.tipoProtocolo }}</p>
    <p class="dados"><strong>Número do Protocolo: </strong>{{ dadosProtocolo.seqCaixaPostal }}</p>
    <p class="dados"><strong>Data do Protocolo: </strong>{{ dadosProtocolo.datCaixaPostal }}</p>
  </div>
  <ng-template #elseblock>
    <strong class="invalido mt-5">{{ dadosProtocolo.msg }}</strong>
  </ng-template>
</div>