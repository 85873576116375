import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { DatePickerComponent, IDatePickerConfig } from "ng2-date-picker";
import Swal from "sweetalert2";
import * as dayjs from "dayjs";
import { cpf } from "cpf-cnpj-validator";
import { ProdutorRuralService } from "src/app/services/produtor-rural.service";
import { ProcuracaoService } from "src/app/services/procuracao.service";
import { ProdutorRuralAlterar } from "src/app/features/common/interface/produtorRuralAlterar";

@Component({
  selector: 'app-alteracao-produtor-etapa-3',
  templateUrl: './etapa-3.component.html',
  styleUrls: ['etapa-3.component.scss']
})
export class Etapa3Component implements OnInit{
  @ViewChild('datePicker') datePicker: DatePickerComponent;

  @Output() passarEtapa = new EventEmitter();

  @Output() sair = new EventEmitter();

  @Output() voltarEtapa = new EventEmitter();

  @Input() produtorRural: any;

  @Input() dadosEditados: ProdutorRuralAlterar

  form: FormGroup;
  datePickerConfig: IDatePickerConfig = {
    disableKeypress: true,
    drops: "down",
    format: 'DD/MM/YYYY'
  }
  datInicio: any = null;
  datFim: any = null;
  dataAtual: Date = new Date();
  loading: boolean = false;
  loadingReceita: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private produtorRuralService: ProdutorRuralService,
    private procuracaoService: ProcuracaoService
  ) {

  }

  ngOnInit(): void {
    this.criarFormulario();
    if (this.dadosEditados.passo > 3) {
      this.datInicio = dayjs(this.dadosEditados.contribuinte.datInicioAtiv);
      if (this.dadosEditados.contribuinte.datFimAtiv != null) {
        this.datFim = dayjs(this.dadosEditados.contribuinte.datFimAtiv);
      }
    } else {
      this.datInicio = dayjs(this.produtorRural.contribuinte.datInicioAtiv);
      if (this.produtorRural.contribuinte.datFimAtiv != 'null') {
        this.datFim = dayjs(this.produtorRural.contribuinte.datFimAtiv);
      }
    }
  }

  criarFormulario() {
    if (this.dadosEditados.passo > 3) {
      this.form = this.formBuilder.group({
        tipoContribuinte: [{ value: 1, disabled: true }, Validators.required],
        inscricaoCpf: [this.dadosEditados.cadastro.cpfCnpj, Validators.required],
        nomeRazaoSocial: [{value: this.dadosEditados.cadastro.dscNomeCadastro, disabled: true }, [Validators.required, Validators.minLength(2)]],
        dataInicio: [this.dadosEditados.contribuinte.datInicioAtiv, Validators.required],
        dataTermino: this.dadosEditados.contribuinte.datFimAtiv,
      })        
    } else {
      this.form = this.formBuilder.group({
        tipoContribuinte: [{ value: 1, disabled: true }, Validators.required],
        inscricaoCpf: [this.produtorRural.cadastro.cpfCnpj, Validators.required],
        nomeRazaoSocial: [{value: this.produtorRural.cadastro.dscNomeCadastro, disabled: true }, [Validators.required, Validators.minLength(2)]],
        dataInicio: [this.produtorRural.contribuinte.datInicioAtiv, Validators.required],
        dataTermino: this.produtorRural.contribuinte.datFimAtiv,
      })
    }
  }

  cpfMask(cpf) {
    cpf = cpf.replace(/\D/g,"")
    cpf = cpf.replace(/(\d{3})(\d)/,"$1.$2")
    cpf = cpf.replace(/(\d{3})(\d)/,"$1.$2")
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/,"$1-$2")
    return cpf
  }

  verificarExistenciaCpfCnpj(cpf) {
    if (cpf.length == 11) {
      this.loadingReceita = true;
      this.produtorRuralService.consultarCpfReceita(cpf).subscribe(
        res => {
          this.loadingReceita = false;
        },
        err => {
          this.loadingReceita = false;
          Swal.fire({
            icon: "warning",
            text: 'Análise junto a Receita Federal não foi possível. Processando junto a base SEFAZ TO aguarde...',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.procuracaoService.getCpf(cpf).subscribe(
                res => {
                  let data: any = res;
                  let cpf = this.cpfMask(data[0].cpfCnpj);
                  Swal.fire({
                    icon: 'info',
                    html: `
                      <p>Atenção: Foi encontrado o C.P.F. com os dados informados na SEFAZ TO.</p>
                      <p>C.P.F: ${cpf}</p>
                      <p>Nome: ${data[0].dscNomeCadastro}</p>
                    `,
                    confirmButtonText: 'Utilizar os dados encontrados',
                    showCancelButton: true,
                    cancelButtonText: 'Estes dados estão inconsistentes, não utilizar',
                    allowOutsideClick: false
                  }).then((result) => {
                    if (result.isConfirmed) {
                      this.loading = false;
                      this.form.controls.nomeRazaoSocial.setValue(data[0].dscNomeCadastro);
                      this.form.controls.nomeRazaoSocial.disable();
                    } else if (result.isDismissed) {
                      this.form.controls.nomeRazaoSocial.setValue('');
                      this.form.controls.nomeRazaoSocial.enable();
                      Swal.fire({
                        icon: 'info',
                        text: `Atenção: Será permitida a digitação dos dados que
                        serão validados posteriormente pela Auditoria Fiscal.`
                      })
                    }
                  })
                },
                err => {
                  Swal.fire({
                    icon: 'error',
                    text: 'Ocorreu um erro ao validar os dados, tente novamente.'
                  })
                }
              )
            }
          })
        }
      )
    } else {
      this.form.controls.nomeRazaoSocial.setValue('');
      this.form.controls.nomeRazaoSocial.enable();
    }
  }

  validarDigitoCpf() {
    if (cpf.isValid(this.form.controls.inscricaoCpf.value) == false && this.form.controls.inscricaoCpf.value.length == 11) {
      this.form.controls.inscricaoCpf.setErrors({'invalid': true});
      this.form.controls.nomeRazaoSocial.reset();
      this.form.controls.nomeRazaoSocial.enable();
    }
  }

  getDataInicio(event) {
    if (this.datInicio && this.datInicio >= this.dataAtual) {
      this.form.controls.dataInicio.reset();
      this.form.controls.dataTermino.reset();
      Swal.fire({
        icon: 'warning',
        text: 'Data de Início não pode ser maior que a data atual.'
      })
    } else {
      this.datInicio = event;
    }
  }

  getDataFim(event) {
    if (this.datFim && (this.datFim <= this.datInicio || this.datFim >= this.dataAtual)) {
      this.form.controls.dataTermino.reset();
      Swal.fire({
        icon: 'warning',
        text: 'Término da Atividade deve ser maior que a Data de Início e menor que a data atual.'
      })
    } else {
      this.datFim = event;
    }
  }

  proximaEtapa() {
    this.dadosEditados.cadastro.codigo = this.produtorRural.cadastro.codigo;
    this.dadosEditados.cadastro.cpfCnpj = this.form.controls.inscricaoCpf.value;
    this.dadosEditados.cadastro.dscNomeCadastro = this.form.controls.nomeRazaoSocial.value;
    this.dadosEditados.cadastro.emails = this.produtorRural.cadastro.emails;
    this.dadosEditados.contribuinte.dscRazaoSocial = this.form.controls.nomeRazaoSocial.value;
    this.dadosEditados.contribuinte.datInicioAtiv = dayjs(this.datInicio).format('YYYY-MM-DD');
    this.dadosEditados.contribuinte.datFimAtiv = dayjs(this.datFim).format('YYYY-MM-DD');
    this.dadosEditados.contribuinte.cadastro.codigo = this.produtorRural.contribuinte.cadastro.codigo;
    this.dadosEditados.contribuinte.codCategEstabelecimento = this.produtorRural.contribuinte.codCategEstabelecimento;
    this.dadosEditados.contribuinte.codigo = this.produtorRural.contribuinte.codigo;
    this.dadosEditados.passo = 4;
    this.passarEtapa.emit();
  }

  retornarEtapa() {
    this.voltarEtapa.emit();
  }

  desistir() {
    this.sair.emit();
  }

}