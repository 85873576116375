import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { TokenService } from "src/app/services/token.service";

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(
    private tokenService: TokenService
  ) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.tokenService.hasToken()) {
      const token = this.tokenService.getToken();
      req = req.clone({
        setHeaders: {
          'Authorization': token
        }
      });
    }
    return next.handle(req).pipe(catchError((err: HttpErrorResponse) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          this.tokenService.removeToken();
          localStorage.removeItem('sidemenu');
          localStorage.removeItem('dadosIntegracao');
          localStorage.removeItem('contribuinteEmUso');
        };
      }
      return throwError(err);
    }));
  }
}