import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class ModoEscuroService {

  private modoEscuroSubject = new BehaviorSubject<boolean>(false);
  modoEscuro$ = this.modoEscuroSubject.asObservable()

  constructor(private http: HttpClient) { }

  setModoEscuro(modo: boolean) {
    this.modoEscuroSubject.next(modo);
  }
}
