<div class="ml-5">
  <h3>Confirmação</h3>
  <div>
    <h4>Solicitação Cadastral</h4>
    <div class="container">
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">Motivo:</label>
          <p class="texto pt-1 ml-2">Alteração</p>
        </div>
        <div class="col-6 d-flex">
          <label class="titulo">C.P.F/CNPJ:</label>
          <p class="texto pt-1 ml-2">{{ dadosSolicitante.cpfSolicitante | mask: 'CPF_CNPJ' }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">Nome:</label>
          <p class="texto pt-1 ml-2">{{ dadosSolicitante.solicitante }}</p>
        </div>
      </div>
    </div>
  </div>
  <div>
    <h4>Identificador do Produtor Rural</h4>
    <div class="container">
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">Tipo de Contribuinte:</label>
          <p class="texto pt-1 ml-2">Pessoa Física</p>
        </div>
        <div class="col-6 d-flex">
          <label class="titulo">Inscrição no C.N.P.J ou C.P.F:</label>
          <p class="texto pt-1 ml-2">{{ dadosEditados.cadastro.cpfCnpj | mask: 'CPF_CNPJ' }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">Nome ou Razão Social:</label>
          <p class="texto pt-1 ml-2">{{ dadosEditados.contribuinte.dscRazaoSocial }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">Data de Início:</label>
          <p class="texto pt-1 ml-2">{{ dadosEditados.contribuinte.datInicioAtiv | date: 'dd/MM/yyyy' }}</p>
        </div>
        <div class="col-6 d-flex">
          <label class="titulo">Término da Atividade:</label>
          <p class="texto pt-1 ml-2">
            {{ 
              dadosEditados.contribuinte.datFimAtiv != null 
              ? (dadosEditados.contribuinte.datFimAtiv | date: 'dd/MM/yyyy')
              : 'Não Informado'
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div>
    <h4>Endereço da Propriedade Rural</h4>
    <div class="container">
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">C.E.P:</label>
          <p class="texto pt-1 ml-2">{{ dadosEditados.imovelDTO.endereco.cep.codCep | mask: '00000-000' }} </p>
        </div>
        <div class="col-6 d-flex">
          <label class="titulo">Logradouro:</label>
          <p class="texto pt-1 ml-2">{{ dadosEditados.imovelDTO.endereco.cep.logradouro }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">Município:</label>
          <p class="texto pt-1 ml-2">{{ dadosEditados.imovelDTO.endereco.cep.municipio }} </p>
        </div>
        <div class="col-6 d-flex">
          <label class="titulo">Estado:</label>
          <p class="texto pt-1 ml-2">{{ dadosEditados.imovelDTO.endereco.cep.estado }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">Número:</label>
          <p class="texto pt-1 ml-2">{{ dadosEditados.imovelDTO.endereco.dscNumero }}</p>
        </div>
        <div class="col-6 d-flex">
          <label class="titulo">Tipo Complemento:</label>
          <p class="texto pt-1 ml-2">
              Teste
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">Complemento:</label>
          <p class="texto pt-1 ml-2">
            {{ 
              dadosEditados.imovelDTO.endereco.dscComplemento != null
              ? dadosEditados.imovelDTO.endereco.dscComplemento
              : 'Não Informado'
            }}
          </p>
        </div>
        <div class="col-6 d-flex">
          <label class="titulo">Localização:</label>
          <p class="texto pt-1 ml-2">Zona Rural</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">Latitude:</label>
          <p class="texto pt-1 ml-2">
            {{ 
              this.dadosEditados.imovelDTO.endereco.latitude != null
              ? this.dadosEditados.imovelDTO.endereco.latitude
              : 'Não Informado'
            }}
          </p>
        </div>
        <div class="col-6 d-flex">
          <label class="titulo">Longitude:</label>
          <p class="texto pt-1 ml-2">
            {{
              this.dadosEditados.imovelDTO.endereco.longitute != null
              ? this.dadosEditados.imovelDTO.endereco.longitute
              : 'Não Informado'
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div>
    <h4>Endereço da Correspondência Fiscal</h4>
    <div class="container">
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">C.E.P:</label>
          <p class="texto pt-1 ml-2">{{ dadosEditados.contribuinteEndereco.endereco.cep.codCep | mask: '00000-000' }}</p>
        </div>
        <div class="col-6 d-flex">
          <label class="titulo">Logradouro:</label>
          <p class="texto pt-1 ml-2">{{ dadosEditados.contribuinteEndereco.endereco.cep.logradouro }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">Município:</label>
          <p class="texto pt-1 ml-2">{{ dadosEditados.contribuinteEndereco.endereco.cep.municipio }}</p>
        </div>
        <div class="col-6 d-flex">
          <label class="titulo">Estado:</label>
          <p class="texto pt-1 ml-2">{{ dadosEditados.contribuinteEndereco.endereco.cep.estado }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">Número:</label>
          <p class="texto pt-1 ml-2">{{ dadosEditados.contribuinteEndereco.endereco.dscNumero }}</p>
        </div>
        <div class="col-6 d-flex">
          <label class="titulo">Tipo Complemento:</label>
          <p class="texto pt-1 ml-2">
            Teste
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">Complemento:</label>
          <p class="texto pt-1 ml-2">
            {{
              dadosEditados.contribuinteEndereco.endereco.dscComplemento != null
              ? dadosEditados.contribuinteEndereco.endereco.dscComplemento
              : 'Não Informado'
            }}
          </p>
        </div>
        <div class="col-6 d-flex">
          <label class="titulo">Localização:</label>
          <p class="texto pt-1 ml-2">
            {{ 
              dadosEditados.contribuinteEndereco.endereco.naturezaImovel.codigo == 1
              ? 'Zona Urbana'
              : 'Zona Rural'
            }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">Latitude:</label>
          <p class="texto pt-1 ml-2">
            {{
              dadosEditados.contribuinteEndereco.endereco.latitude != null
              ? dadosEditados.contribuinteEndereco.endereco.latitude
              : 'Não Informado'
            }}
          </p>
        </div>
        <div class="col-6 d-flex">
          <label class="titulo">Longitude:</label>
          <p class="texto pt-1 ml-2">
            {{
              dadosEditados.contribuinteEndereco.endereco.longitute != null
              ? dadosEditados.contribuinteEndereco.endereco.longitute
              : 'Não Informado'
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div>
    <h4>Informações Econômicas e Fiscais</h4>
    <div class="container">
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">Classificação do Contribuinte:</label>
          <p class="texto pt-1 ml-2">Produtor Rural</p>
        </div>
        <div class="col-6 d-flex">
          <label class="titulo">Protocolo Registro na Junta Comercial:</label>
          <p class="texto pt-1 ml-2">
            Não Informado
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">Data do Registro na Junta Comercial:</label>
          <p class="texto pt-1 ml-2">
            Não Informado
          </p>
        </div>
        <div class="col-6 d-flex">
          <label class="titulo">Capital Social:</label>
          <p class="texto pt-1 ml-2">{{ dadosEditados.contribuinte.vrlCapitalSocial }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">Regime de Recolhimento:</label>
          <p class="texto pt-1 ml-2">Por Operação</p>
        </div>
      </div>
      <div class="row">
        <div class="col d-flex">
          <label class="titulo">Natureza Jurídica:</label>
          <p class="texto pt-1 ml-2">Produtor Rural (Pessoa Física)</p>
        </div>
      </div>
      <div class="row">
        <label class="titulo">CNAEs({{ cnae.length }})</label>
      </div>
      <div *ngFor="let item of cnae">
        <div class="row">
          <div class="col-12 d-flex">
            <label class="titulo">CNAE - Primária - Exercido no endereço do estabelecimento:</label>
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex">
            <p class="texto pt-1 ml-2">{{ item.cnae.dscCnae }} - {{ item.numPrincipal == 1 ? 'Sim' : 'Não' }} - {{ item.numExerceNoEnd == 1 ? 'Sim' : 'Não' }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <h4>Informações sobre a exploração rural</h4>
    <div class="container">
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">Categoria de Ocupação:</label>
          <p class="texto pt-1 ml-2">{{ listaOcupacao[dadosEditados.imovelDTO.codTipoUsoImovel - 1].descricao }}</p>
        </div>
        <div class="col-6 d-flex">
          <label class="titulo">Data de aquisição:</label>
          <p class="texto pt-1 ml-2">{{ dadosEditados.imovelDTO.datAquisicao | date: 'dd/MM/yyyy' }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">Inscrição no INCRA:</label>
          <p class="texto pt-1 ml-2">{{ dadosEditados.imovelDTO.dscInscricao }}</p>
        </div>
        <div class="col-6 d-flex">
          <label class="titulo">Nome do Imóvel:</label>
          <p class="texto pt-1 ml-2">{{ dadosEditados.imovelDTO.dscNomeImovel }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">Área do Imóvel (ha):</label>
          <p class="texto pt-1 ml-2">{{ dadosEditados.imovelDTO.numAreaTerreno }}</p>
        </div>
        <div class="col-6 d-flex">
          <label class="titulo">Área de pastagem natural:</label>
          <p class="texto pt-1 ml-2">
            {{
              dadosEditados.imovelDTO.numAreaPastagemNatural != null
              ? dadosEditados.imovelDTO.numAreaPastagemNatural
              : 'Não Informado'
            }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">Área de pastagem formada:</label>
          <p class="texto pt-1 ml-2">
            {{
              dadosEditados.imovelDTO.numAreaPastagemFormada != null
              ? dadosEditados.imovelDTO.numAreaPastagemFormada
              : 'Não Informado'
            }}
          </p>
        </div>
        <div class="col-6 d-flex">
          <label class="titulo">Área inexplorada:</label>
          <p class="texto pt-1 ml-2">
            {{
              dadosEditados.imovelDTO.numAreaInexplorada != null
              ? dadosEditados.imovelDTO.numAreaInexplorada
              : 'Não Informado'
            }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">Área cultivada:</label>
          <p class="texto pt-1 ml-2">
            {{
              dadosEditados.imovelDTO.numAreaCultivada != null
              ? dadosEditados.imovelDTO.numAreaCultivada
              : 'Não Informado'
            }}
          </p>
        </div>
        <div class="col-6 d-flex">
          <label class="titulo">Valor estimado do imóvel:</label>
          <p class="texto pt-1 ml-2">{{ dadosEditados.imovelDTO.vlrImovel }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col d-flex">
          <label class="titulo">Observação:</label>
          <p class="texto pt-1 ml-2">
            {{
              dadosEditados.imovelDTO.observacao != null
              ? dadosEditados.imovelDTO.observacao
              : 'Não Informado'
            }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col d-flex">
          <label class="titulo">Arquivos({{ arquivos.length }}):</label>
          <div *ngFor="let item of arquivos">
            <p class="pt-1 ml-2">{{ item.ged.nomeArquivo }} | </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <h4>Contabilista Responsável</h4>
    <div class="container">
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">C.P.F:</label>
          <p class="texto pt-1 ml-2">
            {{
              dadosEditados.contabilistaResponsavel.cadastro.cpfCnpj != null
              ? (dadosEditados.contabilistaResponsavel.cadastro.cpfCnpj | mask: 'CPF_CNPJ')
              : 'Não Informado'
            }}
          </p>
        </div>
        <div class="col-6 d-flex">
          <label class="titulo">Nome:</label>
          <p class="texto pt-1 ml-2">
            {{
              dadosEditados.contabilistaResponsavel.cadastro.dscNomeCadastro != null
              ? dadosEditados.contabilistaResponsavel.cadastro.dscNomeCadastro
              : 'Não Informado'
            }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">C.R.C:</label>
          <p class="texto pt-1 ml-2">
            {{
              dadosEditados.contabilistaResponsavel.dscDocumento != null
              ? dadosEditados.contabilistaResponsavel.dscDocumento
              : 'Não Informado'
            }}
          </p>
        </div>
        <div class="col-6 d-flex">
          <label class="titulo">E-mail:</label>
          <p class="texto pt-1 ml-2">
            {{
              dadosEditados.contabilistaResponsavel.cadastro.emails != null
              ? dadosEditados.contabilistaResponsavel.cadastro.emails[0].descricao
              : 'Não Informado'
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div>
    <h4>Sócios({{ socios.length }})</h4>
    <div class="container" *ngFor="let item of socios">
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">País:</label>
          <p class="texto pt-1 ml-2">{{ listaPaises[item.socioEndereco.endereco.pais.codigo - 1].dscPais }}</p>
        </div>
        <div class="col-6 d-flex">
          <label class="titulo">Tipo de Documento:</label>
          <p class="texto pt-1 ml-2">{{ listaGed[item.tipoGed.codigo - 7].descricaoGed }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">Documento:</label>
          <p class="texto pt-1 ml-2">{{ item.dscDocumento }}</p>
        </div>
        <div class="col-6 d-flex">
          <label class="titulo">Nome:</label>
          <p class="texto pt-1 ml-2">{{ item.dscNomeSocio }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">Participação:</label>
          <p class="texto pt-1 ml-2">{{ item.participacao }}%</p>
        </div>
        <div class="col-6 d-flex">
          <label class="titulo">Tipo de Pessoa:</label>
          <p class="texto pt-1 ml-2">{{ item.numTipoPessoa == 1 ? 'Pessoa Física' : 'Pessoa Jurídica' }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">É o Administrador?:</label>
          <p class="texto pt-1 ml-2">{{ item.administrador == 0 ? 'Não' : 'Sim' }}</p>
        </div>
        <div class="col-6 d-flex">
          <label class="titulo">Data da Entrada na sociedade:</label>
          <p class="texto pt-1 ml-2">{{ item.datInicio | date: 'dd/MM/yyyy' }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">Data de Saída da sociedade:</label>
          <p class="texto pt-1 ml-2">
            Não Informado
          </p>
        </div>
      </div>
      <div class="row mt-2" *ngIf="item.socioEndereco.endereco.pais.codigo != 30">
        <div class="col-6 d-flex">
          <label class="titulo">Endereço completo no País de origem:</label>
          <p class="texto pt-1 ml-2">{{ item.socioEndereco.endereco.dscEnderecoExterior }}</p>
        </div>
      </div>
      <div class="divisoria" *ngIf="item.socioEndereco.endereco.pais.codigo != 30"></div>
      <div *ngIf="item.socioEndereco.endereco.pais.codigo == 30">
        <div class="row mt-2">
          <div class="col-6 d-flex">
            <label class="titulo">C.E.P:</label>
            <p class="texto pt-1 ml-2">{{ item.socioEndereco.endereco.cep.codCep | mask: '00000-000' }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Logradouro:</label>
            <p class="texto pt-1 ml-2">{{ item.socioEndereco.endereco.cep.dscLogradouro }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Número:</label>
            <p class="texto pt-1 ml-2">{{ item.socioEndereco.endereco.dscNumero }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Tipo Complemento:</label>
            <p class="texto pt-1 ml-2">
              Teste
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Complemento:</label>
            <p class="texto pt-1 ml-2">
              {{
                item.socioEndereco.endereco.dscComplemento
                ? item.socioEndereco.endereco.dscComplemento
                : 'Não Informado'
              }}
            </p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Latitude:</label>
            <p class="texto pt-1 ml-2">
              {{
                item.socioEndereco.endereco.latitude
                ? item.socioEndereco.endereco.latitude
                : 'Não Informado'
              }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Longitude:</label>
            <p class="texto pt-1 ml-2">
              {{
                item.socioEndereco.endereco.longitute 
                ? item.socioEndereco.endereco.longitute
                : 'Não Informado'
              }}
            </p>
          </div>
        </div>
        <div class="divisoria"></div>
      </div>
    </div>
  </div>
  <div class="div-botao mt-4">
    <button class="br-button primary posicao-botao" type="button" (click)="cadastrarProdutorRural()">Enviar para SEFAZ</button>
    <button class="br-button secondary posicao-botao mr-4" type="button" (click)="voltarPasso()">Voltar Etapa</button>
    <button class="br-button primary posicao-botao mr-4 desistir" type="button" (click)="desistir()">Desistir</button>
  </div>
</div>