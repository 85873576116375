import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: 'app-pre-acesso',
  templateUrl: './pre-acesso.component.html',
  styleUrls: ['./pre-acesso.component.scss']
})

export class PreAcessoComponent implements OnInit {

  characters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  randomState: string = '';
  randomNonce: string = '';
  urlIntegracao: string = '';

  constructor(
    private router: Router
    ) {
    }

    ngOnInit(): void {
      localStorage.setItem("sidemenu", "true");
      let prod = 'https%3A%2F%2Fdfe%2Esefaz%2Eto%2Egov%2Ebr';
      let nonce = (Math.random() + 1).toString(36).substring(7);
      let state = (Math.random() + 1).toString(36).substring(7);
      this.urlIntegracao="https://sso.acesso.gov.br/authorize?response_type=code&client_id=dfe.sefaz.to.gov.br&scope=openid+email+phone+profile+govbr_empresa+govbr_confiabilidades&redirect_uri="+prod+"&nonce="+nonce+"&state="+state;

  }

  redirecionar() {
    this.router.navigate(["inicio"])
  }
}
