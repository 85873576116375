import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class TokenService {


  hasToken() {
    return !!this.getToken();
  }

  setToken(tokenValue) {
    localStorage.setItem('token', `Bearer ${tokenValue}`);
  }

  getToken() {
    return localStorage.getItem('token');
  }

  removeToken() {
    return localStorage.removeItem('token');
  }
}