import { Component } from "@angular/core";
import { CaixaPostalService } from "src/app/services/caixa-postal.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-validar',
  templateUrl: './validar.component.html',
  styleUrls: ['./validar.component.scss']
})
export class ValidarComponent {

  hash: string = '';
  dadosProtocolo: any;
  hashConsultado: boolean = false;
  hashInvalido: boolean = false;

  constructor(
    private caixaPostalService: CaixaPostalService
  ) {

  }

  validarHash() {
    this.caixaPostalService.validarHash(this.hash).subscribe(
      res => {
        this.dadosProtocolo = res;
      },
      err => {
        Swal.fire({
          icon: 'error',
          text: 'Ops! Ocorreu uma falha de comunicação. Tente novamente mais tarde.'
        })
      },
      () => {
        this.dadosProtocolo ? this.hashConsultado = true : this.hashConsultado = false;
        this.dadosProtocolo.msg ? this.hashInvalido = true : this.hashInvalido = false;
      }
    )
  }
}