<div class="ml-5">
  <h3>{{ titulo }}</h3>
  <div class="row">
    <div class="col-4">
      <h5>Motivo</h5>
      <div class="br-input">
        <label>Motivo<span class="obrigatorio label">*</span></label>
        <input type="text" class="input"/>
        <select class="select" disabled>
          <option selected>{{ listaBic[motivo].id }} - {{ listaBic[motivo].descricao }}</option>
        </select>
      </div>
      <h4 class="label"></h4>
    </div>
  </div>
  <h5>Solicitante</h5>
  <div class="row">
    <div class="col-4">
      <div class="br-input">
        <label>C.P.F</label>
        <input type="text" [(ngModel)]="cpfSolicitante" disabled mask="CPF_CNPJ">
      </div>
    </div>
    <div class="col-4">
      <div class="br-input">
        <label>Nome</label>
        <input type="text" [(ngModel)]="solicitante" disabled>
      </div>
    </div>
  </div>
  <div class="row ml-1 mt-3">
    <span class="alerta-solicitante">O Solicitante é quem fez a solicitação, obtido pelo acesso à plataforma. O produtor rural que será identificado
      na próxima etapa.
    </span>
  </div>
  <div class="div-botao mt-4">
    <button class="br-button primary posicao-botao" type="button" (click)="enviarDados()" [disabled]="motivo == null">Próxima Etapa</button>
    <button class="br-button primary posicao-botao mr-4 desistir" type="button" (click)="desistir()">Desistir</button>
  </div>
</div>