import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { HomeComponent } from "./home/home.component";
import { LoginGuard } from "../core/login.guard";

const routes: Routes = [{
  path: 'home',
  component: HomeComponent,
  canActivate: [LoginGuard]
}]

@NgModule({
  imports: [
    RouterModule.forChild(routes,)
  ],
  exports: [
    RouterModule
  ]
})
export class HomeRoutingModule {

}