export interface ProdutorRuralAlterar {
  passo?: number;
  cadastro: {
    codigo: number;
    cpfCnpj: string;
    dscNomeCadastro: string;
    emails: Emails[];
  }
  bic: {
    cadastro: {
      codigo: number;
    }
    listaBic: {
      id: number;
    }
  }
  contribuinte: {
    codigo: number;
    cadastro: {
      codigo: number;
    }
    codCategEstabelecimento: number;
    codClassificacao: number;
    datInicioAtiv: string;
    datFimAtiv: string;
    dscRazaoSocial: string;
    vrlCapitalSocial: number;
  }
  contribuinteEndereco: {
    mesmoEndereco: boolean;
    codigo: number;
    seqContribuinte: number;
    dtInicio: string;
    endereco: {
      cep: {
        codCep: string;
        logradouro?: string;
        municipio?: string;
        estado?: string;
      }
      dscComplemento: string;
      dscNumero: number;
      latitude: string;
      longitute: string;
      naturezaImovel: {
        codigo: number;
      }
      pais: {
        codigo: number;
      }
      tipoComplemento: {
        codigo: number;
        index?: number;
      }
    }
  }
  informacoesFiscaisContribuinte: {
    cnaeContribuinteDTO: CnaeArray[];
    capitalSocial: number;
    contribuinteRegimeRecolhimentoDTO: {
      datInicio: string;
      id: number;
      tipoRegimeRecolhimento: {
        id: number;
      }
    }
    naturezaJuridicaContribuinteDTO: {
      datRegistro: string;
      id: number;
      naturezaJuridica: {
        id: number;
      }
    }
    histRedeSimContribuinteDTO: {
      id: number;
      datRegistro: string;
      datOcorrencia: string;
      protocoloJuntaComercial: string;
    }
  }
  imovelDTO: {
    codigo: number;
    codNaturezaImovel: number;
    codTipoUsoImovel: number;
    datAquisicao: string;
    datRegistro: string;
    dscCartorio: string;
    dscInscricao: string;
    dscNomeImovel: string;
    dscRegistroCartorio: string;
    
    observacao: string;
    vlrImovel: number;
    endereco: {
      codigo: number;
      cep: {
        codCep: string;
        logradouro?: string;
        municipio?: string;
        estado?: string;
      }
      dscComplemento: string;
      dscNumero: string;
      tipoComplemento: {
        codigo: number;
        index?: number;
      }
      latitude: string;
      longitute: string;
    }
    numAreaCultivada: number;
    numAreaInexplorada: number;
    numAreaPastagemFormada: number;
    numAreaPastagemNatural: number;
    numAreaTerreno: number;
    tipoUsoImovel: {
      codigo: number;
    }
    imoveisGed: ArquivosProdutorRural[];
  }
  contabilistaResponsavel: {
    id: number;
    cadastro: {
      codigo: number;
      cpfCnpj: string;
      dscNomeCadastro: string;
      emails: Emails[];
      tipoPessoa: number;
    }
    codCadastro: number;
    codContribuinte: number;
    contribuinte: {
      codClassificacao: number;
      codOrigemCadastro: number;
      codigo: number;
      dscRazaoSocial: string;
    }
    dataInicio: string;
    dscDocumento: string;
  }
  socioDTO: SocioDTO[]
}

export interface CnaeArray {
  id: number;
  cnae: {
    id: number;
  }
  datRegistro: string;
  numPrincipal: number;
  numExerceNoEnd: number;
}

export interface SocioDTO {
  codigo: number;
  administrador: number;
  datInicio: string;
  datFim: string;
  dscDocumento: string;
  dscNomeSocio: string;
  dscCpfCnpj: string;
  numTipoPessoa: number;
  outrasEmpresas: number;
  participacao: number;
  socioEndereco: {
    datInicio?: string;
    endereco: {
      cep: {
        codCep: string;
        logradouro?: string;
      }
      codigo?: number;
      dscComplemento: string;
      dscNumero: number;
      dscEnderecoExterior?: string;
      latitude: string;
      longitute: string;
      naturezaImovel: {
        codigo: number;
      }
      pais: {
        codigo: number;
      }
      tipoComplemento: {
        codigo: number;
      }
    }
  }
  tipoGed: {
    codigo: number;
  }
}

export interface ArquivosProdutorRural {
  ged: {
    nomeArquivo: string;
    arquivo: string;
  }
}

export interface Emails {
  codigo?: number;
  codCadastro?: number;
  descricao?: string;
  situacao?: number;
  tipoEmail?: number;
}

const produtor: ProdutorRuralAlterar = {
  passo: 3,
  cadastro: {
    codigo: null,
    cpfCnpj: '',
    dscNomeCadastro: '',
    emails: []
  },
  bic: {
    cadastro: {
      codigo: null
    },
    listaBic: {
      id: null
    }
  },
  contribuinte: {
    codigo: null,
    cadastro: {
      codigo: null
    },
    codCategEstabelecimento: null,
    codClassificacao: null,
    datInicioAtiv: '',
    datFimAtiv: '',
    dscRazaoSocial: '',
    vrlCapitalSocial: null
  },
  contribuinteEndereco: {
    mesmoEndereco: null,
    codigo: null,
    seqContribuinte: null,
    dtInicio: '',
    endereco: {
      cep: {
        codCep: '',
        logradouro: '',
        municipio: '',
        estado: ''
      },
      dscComplemento: '',
      dscNumero: null,
      latitude: '',
      longitute: '',
      naturezaImovel: {
        codigo: null,
      },
      pais: {
        codigo: null,
      },
      tipoComplemento: {
        codigo: null,
        index: null
      }
    }
  },
  informacoesFiscaisContribuinte: {
    cnaeContribuinteDTO: [],
    capitalSocial: null,
    contribuinteRegimeRecolhimentoDTO: {
      datInicio: '',
      id: null,
      tipoRegimeRecolhimento: {
        id: null,
      }
    },
    naturezaJuridicaContribuinteDTO: {
      datRegistro: '',
      id: null,
      naturezaJuridica: {
        id: null,
      }
    },
    histRedeSimContribuinteDTO: {
      id: null,
      datRegistro: '',
      datOcorrencia: '',
      protocoloJuntaComercial: ''
    }
  },
  imovelDTO: {
    codigo: null,
    codNaturezaImovel: null,
    codTipoUsoImovel: null,
    datAquisicao: '',
    datRegistro: '',
    dscCartorio: '',
    dscInscricao: '',
    dscNomeImovel: '',
    dscRegistroCartorio: '',
    observacao: '',
    vlrImovel: null,
    endereco: {
      codigo: null,
      cep: {
        codCep: '',
        logradouro: '',
        municipio: '',
        estado: ''
      },
      dscComplemento: '',
      dscNumero: '',
      tipoComplemento: {
        codigo: null,
        index: null
      },
      latitude: '',
      longitute: '',
    },
    numAreaCultivada: null,
    numAreaInexplorada: null,
    numAreaPastagemFormada: null,
    numAreaPastagemNatural: null,
    numAreaTerreno: null,
    tipoUsoImovel: {
      codigo: null,
    },
    imoveisGed: []
  },
  contabilistaResponsavel: {
    id: null,
    cadastro: {
      codigo: null,
      cpfCnpj: '',
      dscNomeCadastro: '',
      emails: [{
        descricao: ''
      }],
      tipoPessoa: null
    },
    codCadastro: null,
    codContribuinte: null,
    contribuinte: {
      codClassificacao: null,
      codOrigemCadastro: null,
      codigo: null,
      dscRazaoSocial: ''
    },
    dataInicio: '',
    dscDocumento: '',
  },
  socioDTO: []
}

export class Instanciar {
  private obj: ProdutorRuralAlterar;

  constructor() {
    this.obj = {...produtor}
  }

  public instanciar(obj) {
    return this.obj;
  }
}