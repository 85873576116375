import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";

import { ProcuracaoService } from "src/app/services/procuracao.service";
import * as dayjs from "dayjs";
import "dayjs/locale/pt-br";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import * as FileSaver from "file-saver";
import { ModoEscuroService } from "src/app/services/modoEscuro.service";

// require('dayjs/locale/pt-br');

@Component({
  selector: 'app-procuracao',
  templateUrl: './procuracao.component.html',
  styleUrls: ['./procuracao.component.scss'],
})
export class ProcuracaoComponent implements OnInit {

  contribuinteEmUso: string;
  contribuinteFormatado: any;
  procuracoes: any;
  situacao: any = '';
  dataInicio: any = null;
  dataFim: any = null;
  datasFiltro = {
    dataInicio: null,
    dataFim: null
  }
  outorgado: any = '';
  relacao: any = null;
  relacoes: any = [];
  filtroData = {
    dataInicio: null,
    dataFim: null
  }
  docArrayBuffer: any;
  modalAtivo: boolean = false;
  loading: boolean = true;
  searchParams: any = {};
  pagina: number = 1;
  tamanhoPagina:number = 10;
  quantidadePaginas: number = 30;
  totalElementos: number;
  filtroAtivo: boolean = false;
  estadoDataInicial: dayjs.Dayjs = dayjs();
  modoEscuro: boolean = false;

  constructor(
      private procuracaoService: ProcuracaoService,
      private router: Router,
      private datePipe: DatePipe,
      private modoEscuroService: ModoEscuroService) {
    dayjs.locale('pt-br');
  }

  ngOnInit(): void {
    this.loading = true;
    this.contribuinteEmUso = localStorage.getItem('contribuinteEmUso');
    this.contribuinteFormatado = JSON.parse(this.contribuinteEmUso);
    this.getProcuracoesFiltro();
    this.getRelacoes();

    this.modoEscuroService.modoEscuro$.subscribe(modo => {
      this.modoEscuro = modo;
    });
  }

  getRelacoes() {
    this.procuracaoService.getTipoProcuracao().subscribe(res => {
      this.relacoes = res;
    });
  }

  getDataInicio(event){
    this.filtroData.dataInicio = dayjs(this.dataInicio).format('YYYY-MM-DD');
    if(this.dataInicio && this.dataFim && this.dataInicio > this.dataFim){
      setTimeout(() => {
        this.filtroData.dataFim = null;
        this.dataFim = null;
      }, 150);
    }
  }
  getDataFim(event){
    this.filtroData.dataFim = dayjs(this.dataFim).format('YYYY-MM-DD');
    if(this.dataFim < this.dataInicio){
      this.dataFim = null;
      this.filtroData.dataFim = null;
    }
  }


  outorgar() {
    Swal.fire({
      icon: 'question',
      html: `<p>Prosseguir com a outorga de procuração digital para que outra pessoa acesse os serviços digitais da Secretaria da Fazenda por você?</p>
        <p>Você poderá selecionar os serviços que o procurador terá acesso. Se marcar todos os serviços, o procurador terá acesso até mesmo aos serviços
        que vierem a ser disponibilizados no futuro.</p>`,
      confirmButtonText: 'Prosseguir',
      showCancelButton: true,
      cancelButtonText: 'Desistir'
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['procuracao-outorgar'])
      } else if (result.isDenied) {
        Swal.close();
      }
    })
  }

  revogar(procuracao) {
    this.loading = true;
    let obj = {};
    let motivoRevoga;
    Swal.fire({
      icon: 'question',
      text: `Confirma revogar a procuração ${procuracao.id} outorgada para ${procuracao.contribuinte.cadastro.dscNomeCadastro}?`,
      confirmButtonText: 'Sim, revogar com efeito imediato',
      showCancelButton: true,
      cancelButtonText: 'Desistir'
    }).then((result) => {
      if (result.isConfirmed) {
        if (this.contribuinteFormatado.seqProcuracao) {
          motivoRevoga = 3;
        } else {
          motivoRevoga = 2;
        }
        this.procuracaoService.revogarProcuracao(procuracao.id, motivoRevoga, obj).subscribe(
          res => {
            this.docArrayBuffer = this.converterParaArrayBuffer(res);
            this.modalAtivo = true;
          },
          err => {
            Swal.fire({
              icon: 'error',
              text: 'Ops! Ocorreu um erro ao revogar a procuração.'
            })
          },
          () => {
            this.loading = false;
          }
        )
      } else {
        Swal.close();
        this.loading = false;
      }
    })
  }

  fecharModal() {
    this.modalAtivo = false;
  }

  converterParaArrayBuffer(base64) {
    var binaryString = atob(base64);
    var bytes = new Uint8Array(binaryString.length);
    for (var i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  baixarDocumento(procuracao) {
    if (procuracao.dscUriAlfresco) {
      let arquivo = this.converterParaArrayBuffer(procuracao.dscUriAlfresco);
      var blob = new Blob([arquivo], { type: 'application/pdf' });
      FileSaver.saveAs(blob, `procuracao_${procuracao.id}`);
    } else {
      Swal.fire({
        icon: 'error',
        text: 'Não há documento para baixar.'
      })
    }
  }

  filtroSituacao(){
    this.filtroAtivo = true;
    this.pagina = 1;
    if(this.situacao !== ''){
      this.searchParams['situacao'] = this.situacao;
    }
    if(this.outorgado !== ''){
      this.searchParams['outorgado'] = this.outorgado;
    }
    if(this.relacao !== null){
      this.searchParams['relacao'] = this.relacao;
    }
    if(this.filtroData.dataInicio !== null && this.filtroData.dataFim !== null){
      this.searchParams['datInicio'] = this.filtroData.dataInicio;
      this.searchParams['datFim'] = this.filtroData.dataFim;
    }

    this.getProcuracoesFiltro();
  }

  getProcuracoesFiltro(){
    this.loading = true;
    this.searchParams.pagina = this.pagina;
    this.searchParams.tamanhoPagina = this.tamanhoPagina;
  
    this.procuracaoService.getFiltro(this.contribuinteFormatado.codigo, this.searchParams).subscribe(
      res => {
        this.procuracoes = res.procuracoes;
        console.log(this.procuracoes);
        if (this.procuracoes.length == 0) {
          this.pagina = 1;
          this.quantidadePaginas = 1;
        } else{
          this.quantidadePaginas = res['totalRegistros'] / res['tamanhoPagina'];
          this.quantidadePaginas = Math.ceil(this.quantidadePaginas);
          return this.totalElementos = res['totalRegistros'];
        }
      },
      err => {
        Swal.fire({
          icon: 'error',
          text: 'Ops! Ocorreu um erro ao filtrar as Procurações.'
        });
        this.router.navigate(['/inicio']);
      },
      () => {
        this.loading = false;
      }
    );
  }

  limparFiltro(){
    this.pagina = 1;
    if(this.situacao !== ''){
      this.situacao = '';
      delete this.searchParams['situacao'];
    }
    if(this.outorgado !== ''){
      this.outorgado = '';
      delete this.searchParams['outorgado'];
    }
    if(this.relacao !== null){
      this.relacao = null;
      delete this.searchParams['relacao'];
    }
    if(this.filtroData.dataInicio !== null && this.filtroData.dataFim !== null){
      delete this.searchParams['datFim'];
      delete this.searchParams['datInicio'];
      this.filtroData.dataInicio = null;
      this.filtroData.dataFim = null;
    }
    this.filtroAtivo = false;
    this.alterarCalendario();
    this.getProcuracoesFiltro();
  }

  alterarCalendario(){
    console.log(this.estadoDataInicial);
    this.dataInicio = this.estadoDataInicial;
    this.dataFim = this.estadoDataInicial;
    this.estadoDataInicial = dayjs();
  }
  

  primeiraPagina(){
    this.loading = true;
    this.pagina = 1;
    this.getProcuracoesFiltro();
    this.scroll();
  }

  paginaAnterior() {
    this.loading = true;
    this.pagina -= 1
    this.getProcuracoesFiltro();
    this.scroll();
  }

  proximaPagina() {
    this.loading = true;
    this.pagina += 1;
    this.getProcuracoesFiltro();
    this.scroll();
  }

  ultimaPagina(){
    this.loading = true;
    this.pagina = this.quantidadePaginas;
    this.getProcuracoesFiltro();
    this.scroll();
  }

  scroll() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  
}