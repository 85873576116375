import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { DatePickerComponent, IDatePickerConfig } from "ng2-date-picker";
import { ProdutorRuralAlterar } from "src/app/features/common/interface/produtorRuralAlterar";
import { ProdutorRuralService } from "src/app/services/produtor-rural.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-alteracao-produtor-etapa-6',
  templateUrl: './etapa-6.component.html',
  styleUrls: ['./etapa-6.component.scss']
})
export class Etapa6Component implements OnInit{
  @ViewChild('datePicker') datePicker: DatePickerComponent;

  @Output() passarEtapa = new EventEmitter();

  @Output() voltarEtapa = new EventEmitter();

  @Output() sair = new EventEmitter();

  @Input() produtorRural: any;

  @Input() listaClassificacao: any;

  @Input() listaRegimeRecolhimento: any;

  @Input() listaNaturezaJuridica: any;

  @Input() listaCnae: any;

  @Input() dadosEditados: ProdutorRuralAlterar;

  form: FormGroup;
  cnaeForm: FormGroup;
  datePickerConfig: IDatePickerConfig = {
    disableKeypress: true,
    drops: 'down',
    format: 'DD/MM/YYYY'
  }
  dataRegistro: any;
  cnaeAberto: boolean = false;
  cnaePrincipal: boolean = false
  nomeCnae: string;
  cnae: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private produtorRuralService: ProdutorRuralService

  ) {

  }

  ngOnInit(): void {
    this.criarFormulario();
    this.verificarCnaePrincipal();
  }

  criarFormulario() {
    if (this.dadosEditados.passo > 6) {
      this.cnae = this.dadosEditados.informacoesFiscaisContribuinte.cnaeContribuinteDTO;
      this.form = this.formBuilder.group({
        classificacaoContribuinte: [{value: 3, disabled: true}, Validators.required],
        protocoloRegistro: {value: '', disabled: true},
        dataRegistro: {value: '', disabled: true},
        capitalSocial: [this.dadosEditados.contribuinte.vrlCapitalSocial, [Validators.required, Validators.min(0.01)]],
        regimeRecolhimento: {value: 0, disabled: true},
        naturezaJuridica: {value: 89, disabled: true},
      });        
    } else {
      this.cnae = this.produtorRural.informacoesFiscaisContribuinte.cnaeContribuinteDTO;
      this.form = this.formBuilder.group({
        classificacaoContribuinte: [{value: 3, disabled: true}, Validators.required],
        protocoloRegistro: {value: '', disabled: true},
        dataRegistro: {value: '', disabled: true},
        capitalSocial: [this.produtorRural.contribuinte.vrlCapitalSocial, [Validators.required, Validators.min(0.01)]],
        regimeRecolhimento: {value: 0, disabled: true},
        naturezaJuridica: {value: 89, disabled: true},
      });
    }
    this.cnaeForm = this.formBuilder.group({
      cnae: '',
      atividadePrincipal: '',
      exercidoEndereco: ''
    })
  }

  abrirModal() {
    this.cnaeAberto = true;
  }

  fecharModal() {
    this.nomeCnae = '';
    this.cnaeAberto = false;
    this.cnaeForm.reset();
  }

  getNomeCnae() {
    let id = this.listaCnae[this.cnaeForm.controls.cnae.value].id;
    for (let i = 0; i < this.listaCnae.length; i++) {
      if (this.listaCnae[i].id == id) {
        this.nomeCnae = this.listaCnae[i].dscCnae;
        break
      }
    }
  }

  verificarCnaePrincipal() {
    for (let i = 0; i < this.cnae.length; i++) {
      if (this.cnae[i].numPrincipal == 1) {
        this.cnaePrincipal = true;
        return
      }
    }
  }

  adicionarCnae() {
    if (this.cnaePrincipal == true && this.cnaeForm.controls.atividadePrincipal.value == 1) {
      Swal.fire({
       icon: 'warning',
       text: `Atenção: Já tem um CNAE na lista marcado como Primária. Para prosseguir,
       altere a anterior para Secundária e depois mude esta para Primária.` 
      })
    } else {
      this.nomeCnae = '';
      let obj = {
        cnae: {
          id: this.listaCnae[this.cnaeForm.controls.cnae.value].id,
          dscCnae: this.listaCnae[this.cnaeForm.controls.cnae.value].dscCnae
        },
        numPrincipal: this.cnaeForm.controls.atividadePrincipal.value,
        numExerceNoEnd: this.cnaeForm.controls.exercidoEndereco.value
      }
      this.cnae.push(obj);
      this.verificarCnaePrincipal();
      this.cnaeAberto = false;
      this.cnaeForm.reset();
    }
  }

  deletarCnae(item) {
    this.cnae.splice(item, 1);
    if (this.cnae.length > 0) {
      for (let i = 0; i < this.cnae.length; i++) {
        if (this.cnae[i].numPrincipal == 1) {
          this.cnaePrincipal = true;
        } else {
          this.cnaePrincipal = false;
        }
      }
    } else {
      this.cnaePrincipal = false;
    }
  }

  proximoPasso() {
    this.dadosEditados.contribuinte.vrlCapitalSocial = this.form.controls.capitalSocial.value;
    this.dadosEditados.contribuinte.codClassificacao = this.form.controls.classificacaoContribuinte.value;
    this.dadosEditados.informacoesFiscaisContribuinte.capitalSocial = this.form.controls.capitalSocial.value;
    this.dadosEditados.informacoesFiscaisContribuinte.contribuinteRegimeRecolhimentoDTO.id = this.form.controls.regimeRecolhimento.value;
    this.dadosEditados.informacoesFiscaisContribuinte.contribuinteRegimeRecolhimentoDTO.datInicio = this.produtorRural.informacoesFiscaisContribuinte.contribuinteRegimeRecolhimentoDTO.datInicio;
    this.dadosEditados.informacoesFiscaisContribuinte.contribuinteRegimeRecolhimentoDTO.tipoRegimeRecolhimento.id = this.produtorRural.informacoesFiscaisContribuinte.contribuinteRegimeRecolhimentoDTO.tipoRegimeRecolhimento.id;
    this.dadosEditados.informacoesFiscaisContribuinte.histRedeSimContribuinteDTO.datOcorrencia = this.produtorRural.informacoesFiscaisContribuinte.histRedeSimContribuinteDTO.datOcorrencia;
    this.dadosEditados.informacoesFiscaisContribuinte.histRedeSimContribuinteDTO.datRegistro = this.produtorRural.informacoesFiscaisContribuinte.histRedeSimContribuinteDTO.datRegistro;
    this.dadosEditados.informacoesFiscaisContribuinte.histRedeSimContribuinteDTO.id = this.produtorRural.informacoesFiscaisContribuinte.histRedeSimContribuinteDTO.id;
    this.dadosEditados.informacoesFiscaisContribuinte.histRedeSimContribuinteDTO.protocoloJuntaComercial = this.produtorRural.informacoesFiscaisContribuinte.histRedeSimContribuinteDTO.protocoloJuntaComercial;
    this.dadosEditados.informacoesFiscaisContribuinte.naturezaJuridicaContribuinteDTO.naturezaJuridica.id = this.form.controls.naturezaJuridica.value;
    this.dadosEditados.informacoesFiscaisContribuinte.naturezaJuridicaContribuinteDTO.datRegistro = this.produtorRural.informacoesFiscaisContribuinte.naturezaJuridicaContribuinteDTO.datRegistro;
    this.dadosEditados.informacoesFiscaisContribuinte.naturezaJuridicaContribuinteDTO.id = this.produtorRural.informacoesFiscaisContribuinte.naturezaJuridicaContribuinteDTO.id;
    this.dadosEditados.informacoesFiscaisContribuinte.cnaeContribuinteDTO = this.cnae;
    this.dadosEditados.passo = 7;
    this.passarEtapa.emit();
  }

  voltarPasso() {
    this.voltarEtapa.emit();
  }

  desistir() {
    this.sair.emit();
  }
}