<div class="container">
  <div class="container-imagens">
    <img src="../../../assets/images/imagens/logo_sfera.png" alt="Logo SFERA" width="300px" height="200px">
    <img src="../../../assets/images/imagens/Gov.br_logo.svg" alt="Logo Gov.br">
  </div>
  <div class="container-conteudo">
    <p class="descricao">O acesso ao ambiente é integrado à sua conta gov.br, que é uma identificação que comprova em meios digitais que você é você.
      Com ela, você se identifica com segurança na hora de acessar serviços digitais. Ela é gratuita e está
      disponível para todos os cidadãos brasileiros. Para mais informações, acesse o site <a href="https://www.gov.br/governodigital/pt-br" class="azul">Governo Digital</a>
    </p>
    <a href={{urlIntegracao}}>
      <button class="br-sign-in primary large" type="button"><fa-icon [icon]="['fas', 'user']" aria-hidden="true"></fa-icon><span class="d-sm-inline bold">Entrar com o gov.br</span>
      </button>
    </a>
    <p class="problemas">Em caso de problemas com o acesso gov.br,<a href="https://atendimento.servicos.gov.br/pt" class="link"> clique aqui</a></p>
  </div>
</div>
