import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";

import { Solicitacao } from "src/app/features/common/interface/solicitacao";
import { ContribuinteService } from "src/app/services/contribuinte.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-suspensao-produtor-etapa-4',
  templateUrl: './etapa-4.component.html',
  styleUrls: ['./etapa-4.component.scss']
})
export class Etapa4Component implements OnInit{
  @Output() sair = new EventEmitter();
  
  @Input() buffer: any;

  @Input() contribuinte: any;

  solicitacao: string;
  objSolicitacao: Solicitacao;
  motivo: string = '';
  arquivos: any = [];
  loading: boolean = false;

  constructor(
    private contribuinteService: ContribuinteService,
    private router: Router
  ) {

  }

  ngOnInit(): void {
    this.solicitacao = this.buffer.motivo;
  }

  confirmarSolicitacao() {
    this.loading = true;
    this.objSolicitacao = {
      cadastro: this.contribuinte.codigoCadastro,
      contribuinte: this.buffer.codigoContribuinte,
      bic: {
        listaBic: {
          id: this.buffer.codMotivo
        },
        tipoBicOrigem: {
          codigo: 2
        }
      },
      bicObservacao: [
        {
          texto: this.motivo
        }
      ],
      bicDocumentos: []
    };
    
    for (let i = 0; i < this.arquivos.length; i++) {
      this.converterParaBase64(this.arquivos[i], i);
    }
    setTimeout(() => {
      this.contribuinteService.criarSolicitacao(this.objSolicitacao).subscribe(
        res => {
          
        },
        err => {
          this.loading = false;
          Swal.fire({
            icon: 'error',
            text: 'Ocorreu um erro ao enviar a solicitação.'
          })
        },
        () => {
          this.loading = false;
          Swal.fire({
            icon: 'success',
            text: 'Solicitação criada com sucesso!'
          });
          this.router.navigate(['cadastro/produtor-rural/inscricao']);
        }
      )  
    }, 550);
  }

  getAnexo(event) {
    for (let i = 0; i < event.target.files.length; i++) {
      this.arquivos.push(event.target.files[i]);
    }
  }

  converterParaBase64(arquivo, index) {
    let base64File: any;
    const file = arquivo;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      base64File = reader.result;
    }
    setTimeout(() => {
      base64File = base64File.split(',')[1];
      let arquivo = {
        dtoGed: {
          nomeArquivo: this.arquivos[index].name,
          arquivo: base64File
        }
      }
      this.objSolicitacao.bicDocumentos.push(arquivo);
    }, 500);
  }

  deletarArquivo(arquivo) {
    this.arquivos.splice(arquivo, 1);
  }

  desistir() {
    this.sair.emit();
  }

}