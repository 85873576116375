import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { DatePickerComponent, IDatePickerConfig } from "ng2-date-picker";
import { ProdutorRuralService } from "src/app/services/produtor-rural.service";
import * as dayjs from "dayjs";
import Swal from "sweetalert2";
import { ProdutorRuralAlterar } from "src/app/features/common/interface/produtorRuralAlterar";

@Component({
  selector: 'app-alteracao-produtor-etapa-7',
  templateUrl: './etapa-7.component.html',
  styleUrls: ['./etapa-7.component.scss']
})
export class Etapa7Component implements OnInit {
  @ViewChild('datePicker') datePicker: DatePickerComponent;

  @Output() passarEtapa = new EventEmitter();

  @Output() voltarEtapa = new EventEmitter();

  @Output() sair = new EventEmitter();

  @Input() produtorRural: any;

  @Input() listaOcupacao: any;

  @Input() dadosEditados: ProdutorRuralAlterar;

  form: FormGroup;
  dataAtual: Date = new Date();
  dataAquisicao: any;
  arquivos: any;
  arquivosNovos: any = [];
  arquivoValido: boolean = true;
  datePickerConfig: IDatePickerConfig = {
    disableKeypress: true,
    drops: 'down',
    format: 'DD/MM/YYYY'
  }
  car: string;
  carAberto: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private produtorRuralService: ProdutorRuralService
  ) {

  }

  ngOnInit(): void {
    if (this.dadosEditados.passo > 7) {
      this.dataAquisicao = dayjs(this.dadosEditados.imovelDTO.datAquisicao);  
    } else {
      this.dataAquisicao = dayjs(this.produtorRural.imovelDTO.datAquisicao);
    }
    this.criarFormulario();
  }

  criarFormulario() {
    console.log(this.dadosEditados.passo);
    
    if (this.dadosEditados.passo > 7) {
      this.form = this.formBuilder.group({
        categoriaOcupacao: [this.dadosEditados.imovelDTO.codTipoUsoImovel, Validators.required],
        dataAquisicao: [this.dadosEditados.imovelDTO.datAquisicao, Validators.required],
        inscricaoIncra: [this.dadosEditados.imovelDTO.dscInscricao, Validators.required],
        nomeImovel: [this.dadosEditados.imovelDTO.dscNomeImovel, Validators.required],
        areaImovel: [this.dadosEditados.imovelDTO.numAreaTerreno, Validators.required],
        areaPastagemNatural: this.dadosEditados.imovelDTO.numAreaPastagemNatural,
        areaPastagemFormada: this.dadosEditados.imovelDTO.numAreaPastagemFormada,
        areaInexplorada: this.dadosEditados.imovelDTO.numAreaInexplorada,
        areaCultivada: this.dadosEditados.imovelDTO.numAreaCultivada,
        valorEstimadoImovel: [this.dadosEditados.imovelDTO.vlrImovel, [Validators.required, Validators.min(0.01)]],
        observacao: this.dadosEditados.imovelDTO.observacao
      });
      this.arquivos = this.dadosEditados.imovelDTO.imoveisGed;
    } else {
      this.form = this.formBuilder.group({
        categoriaOcupacao: [this.produtorRural.imovelDTO.codTipoUsoImovel, Validators.required],
        dataAquisicao: [this.produtorRural.imovelDTO.datAquisicao, Validators.required],
        inscricaoIncra: [this.produtorRural.imovelDTO.dscInscricao, Validators.required],
        nomeImovel: [this.produtorRural.imovelDTO.dscNomeImovel, Validators.required],
        areaImovel: [this.produtorRural.imovelDTO.numAreaTerreno, Validators.required],
        areaPastagemNatural: this.produtorRural.imovelDTO.numAreaPastagemNatural,
        areaPastagemFormada: this.produtorRural.imovelDTO.numAreaPastagemFormada,
        areaInexplorada: this.produtorRural.imovelDTO.numAreaInexplorada,
        areaCultivada: this.produtorRural.imovelDTO.numAreaCultivada,
        valorEstimadoImovel: [this.produtorRural.imovelDTO.vlrImovel, [Validators.required, Validators.min(0.01)]],
        observacao: this.produtorRural.imovelDTO.observacao
      });
      this.arquivos = this.produtorRural.imovelDTO.imoveisGed;
    }
  }

  validarCategoriaOcupacao() {
    if (this.form.controls.categoriaOcupacao.value == 1) {
      this.abrirModal();
    }
  }

  getDataInicio(event) {
    if (this.dataAquisicao && this.dataAquisicao >= this.dataAtual) {
      this.form.controls.dataAquisicao.reset();
      Swal.fire({
        icon: 'warning',
        text: 'Date de início não pode ser maior que a data atual.'
      })
    } else {
      this.dataAquisicao = event;
    }
  }

  anexo(event) {  
    for (let i = 0; i < event.target.files.length; i++) {
      if (event.target.files[i].type == 'image/jpeg' || event.target.files[i].type == 'image/gif' || event.target.files[i].type == 'application/pdf') {
        this.arquivoValido = true;
        this.arquivosNovos.push(event.target.files[i]);
      } else {
        this.arquivoValido = false;
        return
      }
    }
  }

  deletarArquivo(item) {
    this.arquivosNovos.splice(item, 1);
  }

  converterParaBase64(arquivo, index) {
    let base64File: any;
    const file = arquivo;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      base64File = reader.result;
    }
    setTimeout(() => {
      base64File = base64File.split(',')[1];
      let arquivo = {
        ged: {
          nomeArquivo: this.arquivosNovos[index].name,
          arquivo: base64File
        }
      }
      this.dadosEditados.imovelDTO.imoveisGed.push(arquivo);
    }, 500);
  }

  buscarCar() {
    let data;
    this.produtorRuralService.getCar(this.car).subscribe(
      res => {
        Swal.fire({
          icon: 'error',
          text: `Atenção: Não foi possível processar o CAR informado. Os dados abaixo poderão ser 
            informados e serão validados com o CAR pela Auditoria Fiscal.`
        })
      },
      err => {
        Swal.fire({
          icon: 'error',
          text: `Atenção: Não foi possível processar o CAR informado. Os dados abaixo poderão ser 
            informados e serão validados com o CAR pela Auditoria Fiscal.`
        })
      },
      () => {
        // Adicionar dados após validação do CAR

        // this.informacoesExploracaoForm.controls.inscricaoIncra.setValue(data.inscricaoIncra);
        // this.informacoesExploracaoForm.controls.nomeImovel.setValue(data.nomeImovel);
        // this.informacoesExploracaoForm.controls.areaImovel.setValue(data.areaImovel);
        // this.informacoesExploracaoForm.controls.areaPastagemNatural.setValue(data.areaPastagemNatural);
        // this.informacoesExploracaoForm.controls.areaPastagemFormada.setValue(data.areaPastagemFormada);
        // this.informacoesExploracaoForm.controls.areaInexplorada.setValue(data.areaInexplorada);
        // this.informacoesExploracaoForm.controls.areaCultivada.setValue(data.areaCultivada);

        // this.informacoesExploracaoForm.controls.inscricaoIncra.disable();
        // this.informacoesExploracaoForm.controls.nomeImovel.disable();
        // this.informacoesExploracaoForm.controls.areaImovel.disable();
        // this.informacoesExploracaoForm.controls.areaPastagemNatural.disable();
        // this.informacoesExploracaoForm.controls.areaPastagemFormada.disable();
        // this.informacoesExploracaoForm.controls.areaInexplorada.disable();
        // this.informacoesExploracaoForm.controls.areaCultivada.disable();
        this.fecharModal();
      }
    )
  }

  abrirModal() {
    this.carAberto = true;
  }

  fecharModal() {
    this.carAberto = false;
    this.car = '';
  }

  proximoPasso() {
    this.dadosEditados.imovelDTO.observacao = this.form.controls.observacao.value;
    this.dadosEditados.imovelDTO.datAquisicao = this.form.controls.dataAquisicao.value;
    this.dadosEditados.imovelDTO.vlrImovel = this.form.controls.valorEstimadoImovel.value;
    this.dadosEditados.imovelDTO.numAreaTerreno = this.form.controls.areaImovel.value;
    this.dadosEditados.imovelDTO.codTipoUsoImovel = this.form.controls.categoriaOcupacao.value;
    this.dadosEditados.imovelDTO.tipoUsoImovel.codigo = this.form.controls.categoriaOcupacao.value;
    this.dadosEditados.imovelDTO.dscInscricao = this.form.controls.inscricaoIncra.value;
    this.dadosEditados.imovelDTO.dscNomeImovel = this.form.controls.nomeImovel.value;
    this.dadosEditados.imovelDTO.numAreaPastagemNatural = this.form.controls.areaPastagemNatural.value;
    this.dadosEditados.imovelDTO.numAreaPastagemFormada = this.form.controls.areaPastagemFormada.value;
    this.dadosEditados.imovelDTO.numAreaInexplorada = this.form.controls.areaInexplorada.value;
    this.dadosEditados.imovelDTO.numAreaCultivada = this.form.controls.areaCultivada.value;
    this.dadosEditados.imovelDTO.imoveisGed = this.produtorRural.imovelDTO.imoveisGed;
    for (let i = 0; i < this.arquivosNovos.length; i ++) {
      this.converterParaBase64(this.arquivosNovos[i], i);
    }
    this.dadosEditados.passo = 8;
    this.passarEtapa.emit();
  }

  voltarPasso() {
    this.voltarEtapa.emit();
  }

  desistir() {
    this.sair.emit();
  }
}