import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { HomeRoutingModule } from './home.routing.module';
import { HomeComponent } from './home/home.component';
import { faAdjust, faBars, faChevronRight, faHome, faSquare, faUser } from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
    imports: [
        HomeRoutingModule,
        CommonModule,
        NgbModule,
        FontAwesomeModule
    ],
    exports: [
    ],
    declarations:[
        HomeComponent,
    ],
    providers: [],
})

export class HomeModule {
    constructor(library: FaIconLibrary) {
        library.addIcons(
          faAdjust,
          faUser,
          faBars,
          faHome,
          faChevronRight
        )
      }
}
