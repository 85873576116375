  <!--Modal NFA-E -->
  <div class="backdrop" *ngIf="this.abrirModalNfa">
    <div class="div br-modal auto position-modal modalNfa">
      <div class="br-modal-body">
        <div class="d-flex row">
          <div class="servicos-item centralizar" tabindex="0" (click)="abrirSolicitacaoNFA()">
            <div class="row">
              <span class="servicos-item__texto-maior col">Solicitação</span>
            </div>
            <div class="row">
              <span class="servicos-item__texto col">Solicitar autorização para emissão da NFA-e</span>
            </div>
            <div class="row iconeNfe marginIcone">
              <img class="iconeNfe_img"
                [src]="modoEscuro ? '../../../assets/images/icones/white-file-signature-solid.svg' : '../../../assets/images/icones/file-signature-solid.svg'">
            </div>
          </div>

          <div class="servicos-item centralizar" tabindex="0" (click)="abrirEmitir()">
            <div class="row">
              <span class="servicos-item__texto-maior col">Emitir</span>
            </div>
            <div class="row">
              <span class="servicos-item__texto col">Emissão da Nota Fiscal Avulsa Eletrônica</span>
            </div>

            <div class="row iconeNfe marginIcone">
              <img class="iconeNfe_img"
                [src]="modoEscuro ? '../../../assets/images/icones/white-emitir-nf.svg' : '../../../assets/images/icones/emitir-nf.svg'">
            </div>
          </div>

          <div class="servicos-item centralizar" tabindex="0">
            <div class="row">
              <span class="servicos-item__texto-maior col">Consultar</span>
            </div>
            <div class="row">
              <span class="servicos-item__texto col">Consultas de Notas Fiscais Avulsas Eletrônicas</span>
            </div>

            <div class="row iconeNfe">
              <img class="iconeNfe_img"
                [src]="modoEscuro ? '../../../assets/images/icones/white-consultar-nf.svg' : '../../../assets/images/icones/consultar-nf.svg'">
            </div>
          </div>

          <div class="servicos-item centralizar" tabindex="0">
            <div class="row">
              <span class="servicos-item__texto-maior col">Cancelamento</span>
            </div>
            <div class="row">
              <span class="servicos-item__texto col">Solicitação de cancelamento da Nota Fiscal Avulsa Eletrônica</span>
            </div>
            <div class="row iconeNfe">
              <img class="iconeNfe_img"
                [src]="modoEscuro ? '../../../assets/images/icones/white-cancelar-nf.svg' : '../../../assets/images/icones/cancelar-nf.svg'">
            </div>
          </div>

          <!-- <div class="servicos-item centralizar" tabindex="0">
            <div class="row">
              <span class="servicos-item__texto-maior col">Emitir Parecer</span>
            </div>
            <div class="row">
              <span class="servicos-item__texto col">Emissão do Parecer da Nota Fiscal Avulsa Eletrônica</span>
            </div>
            <div class="row iconeNfe">
              <img 
                class="iconeNfe_img" 
                src="../../../assets/images/icones/emitir-parecer-nf.svg"  
              >
            </div>
          </div> -->
        </div>
      </div>
      <div class="br-modal-footer justify-content-end">
        <div>
          <button class="br-button secondary" type="button" (click)="abrirFecharModalNfae()">Retornar</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Emitir NFA-E -->
  <div class="backdrop" *ngIf="this.abrirModalEmitirNfa">
    <div class="div br-modal auto position-modal modalNfa">
      <div class="br-modal-body">
        <div class="d-flex row">
      
          <div class="servicos-item centralizar" tabindex="0" (click)="redirecionarNfe()">
            <div class="row">
              <span class="servicos-item__texto-maior col">Produtor Pecuarista</span>
            </div>
            <div class="row">
              <span class="servicos-item__texto col">Emitir NFA-e como Produtor Pecuarista</span>
            </div>

            <div class="row iconeNfe">
              <img class="iconeNfe_img"
                [src]="modoEscuro ? '../../../assets/images/icones/white-user-solid.svg' : '../../../assets/images/icones/user-solid.svg'">
            </div>
          </div>

          <div class="servicos-item centralizar cardProgramaGov" tabindex="0">
            <div class="row">
              <span class="servicos-item__texto-maior col cardProgramaGov_titulo">Produtor Rural - Programas do
                Governo</span>
            </div>
            <div class="row">
              <span class="servicos-item__texto col cardProgramaGov_subtitulo">Emitir NFA-e como Produtor Rural -
                Programas do Governo</span>
            </div>

            <div class="row iconeNfe">
              <img class="iconeNfe_img"
                [src]="modoEscuro ? '../../../assets/images/icones/white-user-solid.svg' : '../../../assets/images/icones/user-solid.svg'">
            </div>
          </div>
        </div>
      </div>
      <div class="br-modal-footer justify-content-end">
        <div class="div-botao mt-4">
          <button class="br-button secondary" type="button" (click)="abrirFecharModalNfae()">Retornar</button>
        </div>
      </div>
    </div>
  </div>