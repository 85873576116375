<div class="ml-5">
  <h3>Identificação do Produtor Rural</h3>
  <h5>Selecione o contribuinte:</h5>
  <div>
    <div class="row mt-3">
      <div class="br-table col-12">
        <table>
          <thead>
            <tr>
              <th scope="col">Nome/Razão Social</th>
              <th scope="col">Nome Fantasia</th>
              <th scope="col">CPF</th>
              <th scope="col">Inscrição Estadual</th>
              <th scope="col">Relação</th>
              <th scope="col">Início da Procuração</th>
              <th scope="col">Fim da Procuração</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="listaContribuinte.length; else semDados">
            <tr class="cursor" *ngFor="let item of listaContribuinte" (click)="pegarCodigoContribuinte(item)">
              <td>{{ item.dscRazaoSocial }}</td>
              <td>{{ item.dscNomeFantasia }}</td>
              <td>{{ item.cadastro.cpfCnpj | mask: 'CPF_CNPJ' }}</td>
              <td>{{ item.dscIe ? item.dscIe : 'Não Informado' }}</td>
              <td>{{ item.tipoProcuracao?.dscTipoProcuracao ? item.tipoProcuracao.dscTipoProcuracao : item.relacao }}</td>
              <td>{{ item.dataInicio ? (item.dataInicio | date: 'dd/MM/yyyy') : 'N/A' }}</td>
              <td>{{ item.dataFim ? (item.dataFim  | date: 'dd/MM/yyyy') : 'N/A' }}</td>
            </tr>
            </ng-container>
            <ng-template #semDados>
              <tr>
                <td colspan="12" align="center">Sem dados</td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="div-botao mt-4">
    <button class="br-button primary posicao-botao" type="button" disabled>Próxima Etapa</button>
    <button class="br-button secondary posicao-botao mr-4" type="button" (click)="retornarEtapa()">Voltar Etapa</button>
    <button class="br-button primary posicao-botao mr-4 desistir" type="button" (click)="desistir()">Desistir</button>
  </div>
</div>