import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { ProcuracaoService } from "src/app/services/procuracao.service";
import { ProdutorRuralService } from "src/app/services/produtor-rural.service";
import Swal from "sweetalert2";
import { cpf } from "cpf-cnpj-validator";
import { catchError, timeout } from "rxjs/operators";
import { ProdutorRuralAlterar } from "src/app/features/common/interface/produtorRuralAlterar";

@Component({
  selector: 'app-alteracao-produtor-etapa-8',
  templateUrl: './etapa-8.component.html',
  styleUrls: ['./etapa-8.component.scss']
})
export class Etapa8Component implements OnInit {
  @Output() passarEtapa = new EventEmitter();

  @Output() voltarEtapa = new EventEmitter();
  
  @Output() sair = new EventEmitter();

  @Input() produtorRural: any;

  @Input() dadosEditados: ProdutorRuralAlterar;

  form: FormGroup;
  loading: boolean = false;
  loadingReceita: boolean = false;
  loadingCrc: boolean = false;

  constructor(
    private produtorRuralService: ProdutorRuralService,
    private procuracaoService: ProcuracaoService,
    private formBuilder: FormBuilder
  ) {

  }

  ngOnInit(): void {
    this.criarFormulario();
    this.verificarValidacoes();
  }

  verificarValidacoes() {
    if (this.form.controls.cpf.value) {
      this.form.controls.cpf.addValidators(Validators.required);
      this.form.controls.nome.addValidators(Validators.required);
      this.form.controls.crc.addValidators(Validators.required);
      this.form.controls.email.addValidators(Validators.required);
    }
  }

  criarFormulario() {
    if (this.dadosEditados.passo > 8) {
      this.form = this.formBuilder.group({
        cpf: this.dadosEditados.contabilistaResponsavel.cadastro.cpfCnpj,
        nome: this.dadosEditados.contabilistaResponsavel.cadastro.dscNomeCadastro,
        crc: this.dadosEditados.contabilistaResponsavel.dscDocumento,
        email: this.dadosEditados.contabilistaResponsavel.cadastro.emails[0].descricao
      })
      if (this.dadosEditados.contabilistaResponsavel.cadastro.cpfCnpj) {
        this.form.controls.nome.disable();
      }
    } else {
      this.form = this.formBuilder.group({
        cpf: this.produtorRural.contabilistaResponsavel.cadastro.cpfCnpj,
        nome: this.produtorRural.contabilistaResponsavel.cadastro.dscNomeCadastro,
        crc: this.produtorRural.contabilistaResponsavel.dscDocumento,
        email: this.produtorRural.contabilistaResponsavel.cadastro.emails[this.produtorRural.contabilistaResponsavel.cadastro.emails.length - 1].descricao
      })
      if (this.produtorRural.contabilistaResponsavel.cadastro.cpfCnpj) {
        this.form.controls.nome.disable();
      }
    }
  }

  cpfMask(cpf) {
    cpf = cpf.replace(/\D/g,"")
    cpf = cpf.replace(/(\d{3})(\d)/,"$1.$2")
    cpf = cpf.replace(/(\d{3})(\d)/,"$1.$2")
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/,"$1-$2")
    return cpf
  }

  verificarExistenciaCpf(cpf) {
    if (cpf.length == 11) {
      this.loadingReceita = true;
      this.produtorRuralService.consultarCpfReceita(cpf).subscribe(
        res => {
          this.loadingReceita = false;
        },
        err => {
          this.loadingReceita = false;
          Swal.fire({
            icon: "warning",
            text: 'Análise junto a Receita Federal não foi possível. Processando junto a base SEFAZ TO aguarde...',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.procuracaoService.getCpf(cpf).subscribe(
                res => {
                  let data: any = res;
                  let cpf = this.cpfMask(data[0].cpfCnpj);
                  Swal.fire({
                    icon: 'info',
                    html: `
                      <p>Atenção: Foi encontrado o C.P.F. com os dados informados na SEFAZ TO.</p>
                      <p>C.P.F: ${cpf}</p>
                      <p>Nome: ${data[0].dscNomeCadastro}</p>
                    `,
                    confirmButtonText: 'Utilizar os dados encontrados',
                    showCancelButton: true,
                    cancelButtonText: 'Estes dados estão inconsistentes, não utilizar',
                    allowOutsideClick: false
                  }).then((result) => {
                    if (result.isConfirmed) {
                      this.loading = false;
                      this.form.controls.nome.setValue(data[0].dscNomeCadastro);
                      this.form.controls.nome.disable();
                      this.form.controls.nome.addValidators(Validators.required);
                      this.form.controls.crc.addValidators(Validators.required);
                      this.form.controls.email.addValidators(Validators.required);

                      this.form.controls.nome.updateValueAndValidity();
                      this.form.controls.crc.updateValueAndValidity();
                      this.form.controls.email.updateValueAndValidity();
                    } else if (result.isDismissed) {
                      this.form.controls.nome.setValue('');
                      this.form.controls.nome.enable();
                      this.form.controls.nome.addValidators(Validators.required);
                      this.form.controls.crc.addValidators(Validators.required);
                      this.form.controls.email.addValidators(Validators.required);

                      this.form.controls.nome.updateValueAndValidity();
                      this.form.controls.crc.updateValueAndValidity();
                      this.form.controls.email.updateValueAndValidity();
                      Swal.fire({
                        icon: 'info',
                        text: `Atenção: Será permitida a digitação dos dados que
                        serão validados posteriormente pela Auditoria Fiscal.`
                      })
                    }
                  })
                },
                err => {
                  Swal.fire({
                    icon: 'error',
                    text: 'Ocorreu um erro ao validar os dados, tente novamente.'
                  })
                }
              )
            }
          })
        }
      )
    }
  }

  validarDigitoCpf() {
    if (cpf.isValid(this.form.controls.cpf.value) == false && this.form.controls.cpf.value.length == 11) {
      this.form.controls.cpf.setErrors({'invalid': true});
      this.form.controls.nome.reset();
      this.form.controls.nome.enable();
    } else if (this.form.controls.cpf.value.length > 0 && this.form.controls.cpf.value.length < 11) {
      this.form.controls.cpf.setErrors({'invalid': true});
      this.form.controls.nome.reset();
      this.form.controls.nome.enable();
    } else {
      this.form.controls.cpf.setErrors(null);
      this.form.controls.nome.setValue('');
      this.form.controls.nome.enable();
      this.form.controls.nome.clearValidators();
      this.form.controls.crc.clearValidators();
      this.form.controls.email.clearValidators();
      this.form.controls.email.setValidators(Validators.email);

      this.form.controls.nome.updateValueAndValidity();
      this.form.controls.crc.updateValueAndValidity();
      this.form.controls.email.updateValueAndValidity();
    }
  }

  consultarCrc(crc) {
    this.loadingCrc = true;
    this.produtorRuralService.consultarCrc(crc)
      .pipe(
        timeout(5000),
        catchError(err => {
          this.loadingCrc = false;
          Swal.fire({
            icon: 'warning',
            text: `Atenção: Não foi possível validar os dados junto ao C.R.C. 
            Será Permitida a digitação dos dados que serão validados posteriormente pela Auditoria Fiscal.`
          })
          return err;
        })
      )
      .subscribe(
        res => {
          this.loadingCrc = false;
          Swal.fire({
            icon: 'warning',
            text: `Atenção: Não foi possível validar os dados junto ao C.R.C. 
              Será Permitida a digitação dos dados que serão validados posteriormente pela Auditoria Fiscal.`
          })
        }
      )
  }

  proximoPasso() {
    this.dadosEditados.contabilistaResponsavel.dscDocumento = this.form.controls.crc.value;
    this.dadosEditados.contabilistaResponsavel.cadastro.cpfCnpj = this.form.controls.cpf.value;
    this.dadosEditados.contabilistaResponsavel.cadastro.dscNomeCadastro = this.form.controls.nome.value;
    this.dadosEditados.contabilistaResponsavel.cadastro.emails[0].descricao = this.form.controls.email.value;
    this.dadosEditados.contabilistaResponsavel.codCadastro = this.produtorRural.contabilistaResponsavel.codCadastro;
    this.dadosEditados.contabilistaResponsavel.codContribuinte = this.produtorRural.contabilistaResponsavel.codContribuinte;
    this.dadosEditados.contabilistaResponsavel.dataInicio = this.produtorRural.contabilistaResponsavel.dataInicio;
    this.dadosEditados.contabilistaResponsavel.id = this.produtorRural.contabilistaResponsavel.id;
    this.dadosEditados.contabilistaResponsavel.cadastro.codigo = this.produtorRural.contabilistaResponsavel.cadastro.codigo;
    this.dadosEditados.contabilistaResponsavel.cadastro.tipoPessoa = this.produtorRural.contabilistaResponsavel.cadastro.tipoPessoa;
    this.dadosEditados.contabilistaResponsavel.contribuinte.codClassificacao = this.produtorRural.contabilistaResponsavel.contribuinte.codClassificacao;
    this.dadosEditados.contabilistaResponsavel.contribuinte.codOrigemCadastro = this.produtorRural.contabilistaResponsavel.contribuinte.codOrigemCadastro;
    this.dadosEditados.contabilistaResponsavel.contribuinte.codigo = this.produtorRural.contabilistaResponsavel.contribuinte.codigo;
    this.dadosEditados.contabilistaResponsavel.contribuinte.dscRazaoSocial = this.produtorRural.contabilistaResponsavel.contribuinte.dscRazaoSocial;
    this.dadosEditados.passo = 9;
    this.passarEtapa.emit();
  }

  voltarPasso() {
    this.voltarEtapa.emit();
  }

  desistir() {
    this.sair.emit();
  }
}