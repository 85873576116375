
<form [formGroup]="userForm">
  <section class="margin-top container">
    <div>
      <h3>Dados do Usuário</h3>
    </div>
    <div class="container-flex">
      <div class="row">
        <div class="col-4">
          <label class="mb-2">Tipo de Pessoa</label>
          <div class="br-radio">
            <input 
              class="input-disable"
              id="1" 
              type="radio"
              formControlName="tipoPessoa" 
              name="tipoPessoa" 
              [value]="1" 
              [disabled]="true" />
            <label class="mr-4 input-disable" for="1">Pessoa Física</label>
            <input 
              class="input-disable"
              id="2" 
              type="radio" 
              formControlName="tipoPessoa"
              name="tipoPessoa" 
              [value]="2" 
              [disabled]="true" />
            <label class="mr-4 input-disable" for="2">Pessoa Jurídica</label>
          </div>
        </div>
        <div class="col-4">
          <div class="br-input">
            <label>CPF/CNPJ</label>
            <input
              id="cpfCnpj"
              formControlName="cpfCnpj"
              mask="CPF_CNPJ"
              name="cpfCnpj"
              placeholder="CPF/CNPJ"
              type="text"
              [disabled]="true"
            />
          </div>
        </div>
        <div class="col-4">
          <div class="br-input">
            <label for="dscNomeCadastro">Nome</label>
            <input
              formControlName="dscNomeCadastro"
              id="dscNomeCadastro"
              name="dscNomeCadastro"
              placeholder="dscNomeCadastro"
              type="text"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <div class="br-input">
            <label>Data do registro</label>
            <input
              formControlName="dataRegistro"
              id="dataRegistro"
              name="dataRegistro"
              placeholder="Data do registro"
              type="text"
            />
          </div>
        </div>
        <div class="col-4">
          <div class="br-input">
            <label>Origem do registro</label>
            <input
              formControlName="origemCadastro"
              id="origemCadastro"
              name="origemCadastro"
              placeholder="Origem do registro"
              type="text"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <section class="margin-top container">
    <div>
      <h3>E-mail</h3>
    </div>
    <div class="container-flex">
      <div class="row mb-5">
        <div class="col-4">
          <div class="br-input">
            <label>E-mail</label>
            <input
            formControlName="email"
            type="email"
            id="email"
            class="form-control form-control-sm"
            ngModel
            email="true"
            #email
          >
          <div
            *ngIf="
              userForm.controls.email.invalid &&
              (userForm.controls.email.dirty ||
              userForm.controls.email.touched)
              "
            class="invalid-feedback d-block"
          >
            O campo deve ser preenchido com um email
          </div>
          </div>
        </div>
        <div class="col-8">
          <label class="mb-4"></label>
          <div class="br-radio">
            <input 
              id="primario" 
              name="prioridadeEmail"
              formControlName="prioridadeEmail"
              type="radio" 
              [value]="1" 
              [attr.disabled]="primarioBloqueado ? 'disabled' : null"
            />
            <label class="mr-4" for="primario">Primário</label>
            <input 
              id="secundario" 
              name="prioridadeEmail"
              formControlName="prioridadeEmail"
              type="radio" 
              [value]="2" 
            />
            <label class="mr-4" for="secundario">Secundário</label>

            <button
              type="button"
              class="btn btn-light"
              title="Adicionar E-mail"
              (click)="adicionarEmail()"
            >

            <img class="mb-1" src="../../../assets/images/icones/plus.svg" alt="Adicionar E-mail">
            </button>
          </div>
        </div>
      </div>
      <div class="top-bar">
        <table>
          <thead>
            <tr>
              <th scope="col">E-mail</th>
              <th scope="col">Prioridade do e-mail</th>
              <th scope="col">Ação</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="emails.length > 0; else semDados"></ng-container>
            <tr *ngFor="let itemEmail of emails; let i = index">
              <td>
                {{ itemEmail.descricao }}
              </td>
              <td>
                {{ (itemEmail.tipoEmail == '1') ? 'Primário' : 'Secundário' }}
              </td>
              <td >
                <img
                  src="/assets/images/icones/trash-solid.svg"
                  class="icone-colorido"
                  alt="Apagar Email"
                  width="20"
                  height="20"
                  title="Apagar Email"
                  (click)="apagarEmail(i, itemEmail)"
                />
              </td>
            </tr>
            <ng-template #semDados>
              <tr>
                <td colspan="3" align="center">Sem dados para serem exibidos</td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
    </div>
  </section>
  
  <section class="margin-top container">
    <div>
      <h3>Perfil de Acesso</h3>
    </div>
  
    <div class="top-bar">
      <table>
        <thead>
          <tr>
            <th scope="col" class="col-6"></th>
            <th scope="col">Perfil</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let perfil of this.perfisAcesso; let i = index">
            <td>
              <div class="br-checkbox mt-3">
                <input 
                  id="perfil" 
                  [checked]="perfil.checked" 
                  type="checkbox" 
                  class="input-disable"
                  />
                <label class="input-disable"></label>
              </div>
            </td>
            <td>{{ perfil.descricao }}</td>
          </tr>
        </tbody>
      </table> 
    </div>
  </section>
  
  <section class="margin-top container">
    <div>
      <h3>Administrativo</h3>
    </div>
  
    <div class="col-4 mb-2">
      <label class="mb-2">Acesso</label>
      <div class="row align-items-center ml-1">
        <div class="br-radio">
          <input
            id="bloqueado"
            class="input-disable"
            type="radio"
            formControlName="bloqueiosAcesso"
            name="bloqueiosAcesso"
            [value]="1"
          />
          <label class="mr-4 input-disable" for="bloqueado">Está bloqueado</label>
          <input
            id="naoBloqueado"
            class="input-disable"
            type="radio"
            formControlName="bloqueiosAcesso"
            name="bloqueiosAcesso"
            [value]="0"
          />
          <label class="mr-4 input-disable" for="naoBloqueado">Não está bloqueado</label>
        
        </div>
        <button class=" justify-content-center containerIcone" (click)="this.modalAtivo = true">
          <img class="icone-question" src="../../../../assets/images/icones/question-solid.svg" alt="Ajuda">
        </button>
      </div>
    </div>
  
    <div class="br-table margin-table">
      <div class="table-header">
        <div class="top-bar">
          <div class="table-title">Histórico de bloqueios</div>
        </div>
      </div>
    </div>
    <table>
      <thead>
        <tr>
          <th scope="col">Data do bloqueio</th>
          <th scope="col">Data fim do bloqueio</th>
          <th scope="col">Motivo</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="bloqueiosAcesso.length == 0; else preencherBloqueios">
          <td></td>
          <td>Nenhum item encontrado</td>
          <td></td>
        </tr>
        <ng-template #preencherBloqueios>
          <tr *ngFor="let item of bloqueiosAcesso">
            <td>{{ item.dataRegistro | date: 'dd/MM/yyyy' }}</td>
            <td>{{ item.dataFim ? (item.dataFim | date: 'dd/MM/yyyy') : '-' }}</td>
            <td>{{ item.dscMotivo ? item.dscMotivo : '-' }}</td>
          </tr>
        </ng-template>

      </tbody>
    </table>
    <div class="col-12 containerBotoes">
      <button 
        type="button" 
        class="btn btn-secondary btn-select mr-4 botoes" 
        title="Desistir" 
        (click)="desistir()"
      >
        Desistir
      </button>
      <button  
        type="button" 
        class="btn btn-primary btn-select botoes" 
        title="Salvar"
        (click)="salvarDados()" 
      >
        Salvar
      </button>          
    </div>
  </section>
</form>

<!-- Modal -->
<div class="backdrop" *ngIf="modalAtivo">
  <div class="div br-modal medium position-modal">
    <div class="br-modal-header">Acesso</div>
      <div class="br-modal-body">
        <p>Usuário com acesso bloqueado significa não permitir conectar qualquer serviço/portal do SFERA.</p>
      </div>
      <div class="br-modal-footer justify-content-center">
        <button class="br-button secondary mr-4" type="button" (click)="this.modalAtivo = false">Fechar</button>
      </div>
  </div>
</div>

<div class="carregamento" *ngIf="loading">
  <img src="../../../assets/images/icones/logo-animado.svg">
</div>

