import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { DesempenhoService } from 'src/app/services/desempenho.service';
import { IntegracaoService } from 'src/app/services/integracao.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  dadosDesempenho: any;
  urlData: any;
  code: any;
  state: any;
  dadosIntegracao: any;

  constructor(
    private desempenhoService: DesempenhoService,
    private router: Router
  ) {

  }

  ngOnInit() {
    let urlParametros = window.location.href.toString().split('/?code=')[1];
    if(urlParametros){
      this.router.navigate(["/integracao"]);
    }
    this.desempenhoService.getDados().subscribe(
      res => {
        this.dadosDesempenho = res;
      }
    )
  }

  abrirLink() {
    window.open('https://falabr.cgu.gov.br/publico/TO/Manifestacao/RegistrarManifestacao', '_blank')
  }

}
