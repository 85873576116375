<section class="margem-top servicos">
  <div class="servicos-titulo">Produtor Rural</div>
  <div class="servicos-texto">Selecione um dos serviços abaixo clicando sobre eles:</div>
  <div class="servicos-container">
    <div class="servicos-item centralizar" [routerLink]="['../../../cadastro/produtor-rural/inscricao']">
      <div class="row">
        <span class="servicos-item__texto-maior col">INSCRIÇÃO</span>
      </div>
      <div class="row">
        <span class="servicos-item__texto col">Solicitação de inscrição e alteração da situação cadastral da I.E.</span>
      </div>
    </div>
    <div class="servicos-item centralizar" [routerLink]="['../../../cadastro/produtor-rural/acompanhamento']">
      <div class="row">
        <span class="servicos-item__texto-maior servicos-item__texto-grande col">ACOMPANHAMENTO</span>
      </div>
      <div class="row">
        <span class="servicos-item__texto col"></span>
      </div>
    </div>
    <div class="servicos-item centralizar" *ngIf="!cadastroEmUso">
      <div class="row">
        <span class="servicos-item__texto-maior servicos-item__texto-grande col">REGULARIZAÇÃO</span>
      </div>
      <div class="row">
        <span class="servicos-item__texto col"></span>
      </div>
    </div>
  </div>
</section>