import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CadastroComponent } from './cadastro/cadastro.component';
import { CadastroMenuComponent } from './cadastro-menu/cadastro-menu.component';
import { CanteiroObrasComponent } from './canteiro-obras/canteiro-obras.component';
import { CanteiroObrasMenuComponent } from './canteiro-obras/canteiro-obras-menu/canteiro-obras-menu.component';
import { SubstitutoTributarioComponent } from './substituto-tributario/substituto-tributario.component';
import { SubstitutoTributarioMenuComponent } from './substituto-tributario/substituto-tributario-menu/substituto-tributario-menu.component';
import { InformacoesComplementaresComponent } from './informacoes-complementares/informacoes-complementares.component';
import { InformacoesComplementaresMenuComponent } from './informacoes-complementares/informacoes-complementares-menu/informacoes-complementares-menu.component';
import { AuthGuard } from '../core/auth.guard';
import { AcompanhamentoProdutorRuralComponent } from './produtor-rural/acompanhamento/acompanhamento.component';
import { ProdutorRuralMenuComponent } from './produtor-rural/produtor-rural-menu/produtor-rural-menu.component';
import { ProdutorRuralComponent } from './produtor-rural/produtor-rural.component';
import { CadastramentoProdutorRuralComponent } from './produtor-rural/inscricao/cadastramento/cadastramento.component';
import { InscricaoMenuComponent } from './produtor-rural/inscricao/inscricao-menu/inscricao-menu.component';
import { SuspensaoProdutorRuralComponent } from './produtor-rural/inscricao/suspensao/suspensao.component';
import { AlteracaoProdutorRuralComponent } from './produtor-rural/inscricao/alteracao/alteracao.component';

const routes: Routes = [
  {
    path: 'cadastro',
    canActivate: [AuthGuard],
    component: CadastroComponent,
    data: { breadcrumb: 'Cadastro' },
    children: [
      { 
        path: '', 
        redirectTo: 'menu', 
        pathMatch: 'full' 
      },
      {
        path: 'menu',
        component: CadastroMenuComponent,
      },
      {
        path: 'produtor-rural',
        component: ProdutorRuralComponent,
        data: { breadcrumb: 'Menu Produtor Rural'},
        children: [
          {
            path: '',
            redirectTo: 'menu',
            pathMatch: 'full'
          },
          {
            path: 'menu',
            component: ProdutorRuralMenuComponent,
          },
          {
            path: 'inscricao',
            data: { breadcrumb: 'Inscrição Produtor Rural' },
            children: [
              {
                path: '',
                component: InscricaoMenuComponent,
                data: { breadcrumb: null },
                pathMatch: 'full'
              },
              {
                path: 'cadastramento',
                component: CadastramentoProdutorRuralComponent,
                data: { breadcrumb: 'Cadastramento Produtor Rural' }
              },
              {
                path: 'suspensao',
                component: SuspensaoProdutorRuralComponent,
                data: { breadcrumb: 'Suspensão Produtor Rural' }
              },
              {
                path: 'reativacao',
                component: SuspensaoProdutorRuralComponent,
                data: { breadcrumb: 'Reativação Produtor Rural' }
              },
              {
                path: 'baixa',
                component: SuspensaoProdutorRuralComponent,
                data: { breadcrumb: 'Baixa da I.E. Produtor Rural'}
              },
              {
                path: 'alteracao',
                component: AlteracaoProdutorRuralComponent,
                data: { breadcrumb: 'Alteração Produtor Rural' }
              }
            ]
          },
          {
            path: 'acompanhamento',
            component: AcompanhamentoProdutorRuralComponent,
            data: { breadcrumb: 'Acompanhamento Produtor Rural' }
          }
        ]
      },
      {
        path: 'canteiro-obras',
        component: CanteiroObrasComponent,
        data: { breadcrumb: 'Menu Canteiro de Obras'},
        children: [
          {
            path: '',
            redirectTo: 'menu',
            pathMatch: 'full'
          },
          {
            path: 'menu',
            component: CanteiroObrasMenuComponent
          }
        ]
      },
      {
        path: 'substituto-tributario',
        component: SubstitutoTributarioComponent,
        data: { breadcrumb: 'Menu Substituto Tributário'},
        children: [
          {
            path: '',
            redirectTo: 'menu',
            pathMatch: 'full'
          },
          {
            path: 'menu',
            component: SubstitutoTributarioMenuComponent
          }
        ]
      },
      {
        path: 'informacoes-complementares',
        component: InformacoesComplementaresComponent,
        data: { breadcrumb: 'Informações Complementares Menu' },
        children: [
          {
            path: '',
            redirectTo: 'menu',
            pathMatch: 'full'
          },
          {
            path: 'menu',
            component: InformacoesComplementaresMenuComponent
          }
        ]
      }
    ]
  }
]

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})

export class CadastroRoutingModule {

}