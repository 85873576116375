<section class="margem-top servicos">
  <div class="servicos-titulo">Produtor Rural - Inscrição</div>
  <div class="servicos-texto">Selecione um dos serviços abaixo clicando sobre eles:</div>
  <div class="servicos-container">
    <div class="servicos-item centralizar" [routerLink]="['../../../cadastro/produtor-rural/inscricao/cadastramento']">
      <div class="row">
        <span class="servicos-item__texto-grande col">CADASTRAMENTO</span>
      </div>
    </div>
    <div class="servicos-item centralizar" [routerLink]="['../../../cadastro/produtor-rural/inscricao/alteracao']" *ngIf="!cadastroEmUso">
      <div class="row">
        <span class="servicos-item__texto-maior servicos-item__texto-grande col">ALTERAÇÃO</span>
      </div>
      <div class="row">
        <span class="servicos-item__texto col"></span>
      </div>
    </div>
    <div class="servicos-item centralizar" [routerLink]="['../../../cadastro/produtor-rural/inscricao/reativacao']" *ngIf="!cadastroEmUso">
      <div class="row">
        <span class="servicos-item__texto-maior servicos-item__texto-grande col">REATIVAÇÃO</span>
      </div>
      <div class="row">
        <span class="servicos-item__texto col"></span>
      </div>
    </div>
    <div class="servicos-item centralizar" [routerLink]="['../../../cadastro/produtor-rural/inscricao/suspensao']" *ngIf="!cadastroEmUso">
      <div class="row">
        <span class="servicos-item__texto-maior servicos-item__texto-grande col">SUSPENSÃO</span>
      </div>
      <div class="row">
        <span class="servicos-item__texto col"></span>
      </div>
    </div>
    <div class="servicos-item centralizar" [routerLink]="['../../../cadastro/produtor-rural/inscricao/baixa']" *ngIf="!cadastroEmUso">
      <div class="row">
        <span class="servicos-item__texto-maior servicos-item__texto-grande col">BAIXA DA I.E.</span>
      </div>
      <div class="row">
        <span class="servicos-item__texto col"></span>
      </div>
    </div>
  </div>
</section>