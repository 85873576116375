import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "src/environments/environment";
import { ResponseApi } from "../features/common/interface/responseApi";

@Injectable()
export class DesempenhoService {

  private apiUrl = environment.API_URL
  
  constructor(private http: HttpClient) { }

  getDados(): Observable<ResponseApi> {
    return this.http.get<ResponseApi>(`${this.apiUrl}/dados-desempenho/dadosPortal`);   
  }
}