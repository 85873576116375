import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable()
export class CadastroService {

  private apiUrl = environment.API_URL

  constructor(private http: HttpClient) { }

  getUsuario(codigo) {
    return this.http.get(
      `${this.apiUrl}/cadastros/cadastro/id/${codigo}`
    );
  }

  getPerfis() {
    return this.http.get(
      `${this.apiUrl}/cadastros/cadastro/gridPerfisAcesso`
    );
  }

  editarUsuario(obj) {
    return this.http.put(
      `${this.apiUrl}/cadastros/cadastro`, obj
    )
  }

  verificarAtualizacaoContato(codigo): Observable<any> {
    return this.http.get(`${this.apiUrl}/cadastros/dados-cadastro?cadastro=${codigo}`);
  }

  salvarContatos(obj): Observable<any> {
    return this.http.post(`${this.apiUrl}/cadastros/dados-cadastro`, obj);
  } 
}
