import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Observable } from "rxjs";
import { TokenService } from "src/app/services/token.service";


@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  
  token: string;

  constructor(
    private router: Router,
    private tokenService: TokenService
  ) { }

  canActivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.tokenService.hasToken()) {
      this.router.navigate(['inicio']);

      return false;
    }
    return true;
  }
}