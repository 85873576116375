import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { IntegracaoService } from 'src/app/services/integracao.service';
@Component({
  selector: 'my-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private titleService: Title, private router: Router) {
    try{
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          if((<any>window).ga !== undefined){
            (<any>window).ga('set', 'page', event.urlAfterRedirects);
            (<any>window).ga('send', 'pageview');
          }
        }
      });
    }catch(e){
      console.log('erro ao enviar analytics', e);
    }
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle('front-dte-v2')
  }
}
