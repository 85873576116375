import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ProdutorRuralAlterar } from "src/app/features/common/interface/produtorRuralAlterar";
import { ProdutorRuralService } from "src/app/services/produtor-rural.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-alteracao-produtor-etapa-10',
  templateUrl: './etapa-10.component.html',
  styleUrls: ['./etapa-10.component.scss']
})
export class Etapa10Component implements OnInit {
  @Output() voltarEtapa = new EventEmitter();

  @Output() sair = new EventEmitter();

  @Input() produtorRural: any;

  @Input() dadosSolicitante: any;

  @Input() listaComplemento: any;

  @Input() listaOcupacao: any;

  @Input() listaPaises: any;

  @Input() listaGed: any;

  @Input() dadosEditados: ProdutorRuralAlterar;

  socios: any = [{}];
  cnae: any = [{}];
  arquivos: any = [{}];

  constructor(
    private produtorRuralService: ProdutorRuralService
  ) {

  }

  ngOnInit(): void {
    this.cnae = this.dadosEditados.informacoesFiscaisContribuinte.cnaeContribuinteDTO;
    this.arquivos = this.dadosEditados.imovelDTO.imoveisGed;
    this.socios = this.dadosEditados.socioDTO;
  }

  cadastrarProdutorRural() {
    this.produtorRuralService.alterarProdutor(this.produtorRural).subscribe(
      res => {
        Swal.fire({
          icon: 'success',
          text: 'Alteração feita com sucesso!'
        })
      },
      err => {
        Swal.fire({
          icon: 'error',
          text: 'Ops! Ocorreu uma falha de comunicação. Tente novamente mais tarde.'
        })
      }
    )
  }

  voltarPasso() {
    this.voltarEtapa.emit();
  }

  desistir() {
    this.sair.emit();
  }

}