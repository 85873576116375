import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { Instanciar, ProdutorRuralAlterar } from "src/app/features/common/interface/produtorRuralAlterar";
import { ContribuinteService } from "src/app/services/contribuinte.service";
import { ProdutorRuralService } from "src/app/services/produtor-rural.service";

import Swal from "sweetalert2";

@Component({
  selector: 'app-alteracao-produtor-rural',
  templateUrl: './alteracao.component.html',
  styleUrls: ['./alteracao.component.scss']
})
export class AlteracaoProdutorRuralComponent implements OnInit {

  active1: boolean = true;
  active2: boolean = false;
  active3: boolean = false;
  active4: boolean = false;
  active5: boolean = false;
  active6: boolean = false;
  active7: boolean = false;
  active8: boolean = false;
  active9: boolean = false;
  active10: boolean = false;
  dadosIntegracao: any;
  dadosFormatados: any;
  dadosSolicitante: any;
  contribuinteEmUso: any;
  contribuinteFormatado: any;
  dadosProdutorRural: any;
  dadosEditados: ProdutorRuralAlterar;
  bufferObj: any = {};
  loading: boolean = false;
  listaBic: any;
  listaComplemento: any;
  listaClassificacao: any;
  listaRegimeRecolhimento: any;
  listaNaturezaJuridica: any;
  listaOcupacao: any;
  listaPaises: any;
  listaGed: any;
  listaCnae: any;

  constructor(
    private router: Router,
    private produtorRuralService: ProdutorRuralService,
    private contribuinteService: ContribuinteService
  ) {

  }

  ngOnInit(): void {
    let obj = new Instanciar();
    this.dadosEditados = obj.instanciar(this.dadosEditados);
    this.getListaBic();
    this.getListaComplementos();
    this.getListaClassificacaoContribuinte();
    this.getListaRegimeRecolhimento();
    this.getListaNaturezaJuridica();
    this.getListaOcupacao();
    this.getListaPais();
    this.getListaGed();
    this.getListaCnae();
    setTimeout(() => {
      this.dadosIntegracao = localStorage.getItem('dadosIntegracao');
      this.dadosFormatados = JSON.parse(this.dadosIntegracao);
      this.contribuinteEmUso = localStorage.getItem('contribuinteEmUso');
      this.contribuinteFormatado = JSON.parse(this.contribuinteEmUso);
      this.dadosEditados.bic.cadastro.codigo = this.contribuinteFormatado.cadastro.codigo;
      this.dadosEditados.bic.listaBic.id = 2;
      
    }, 500);
  }

  getListaBic() {
    this.contribuinteService.getDadosListaBic().subscribe(
      res => {
        this.listaBic = res;
      }
    )
  }

  getListaComplementos() {
    this.produtorRuralService.getListaComplementos().subscribe(
      res => {
        this.listaComplemento = res;
      }
    )
  }

  getListaClassificacaoContribuinte() {
    this.produtorRuralService.getListaClassificacao().subscribe(
      res => {
        this.listaClassificacao = res;
      }
    )
  }

  getListaRegimeRecolhimento() {
    this.produtorRuralService.getListaRegimeRecolhimento().subscribe(
      res => {
        this.listaRegimeRecolhimento = res;
      }
    )
  }

  getListaNaturezaJuridica() {
    this.produtorRuralService.getListaNaturezaJuridica().subscribe(
      res => {
        this.listaNaturezaJuridica = res;
      }
    )
  }

  getListaOcupacao() {
    this.produtorRuralService.getListaOcupacao().subscribe(
      res => {
        this.listaOcupacao = res;
      }
    )
  }

  getListaPais() {
    this.produtorRuralService.getListaPaises().subscribe(
      res => {
        this.listaPaises = res;
      }
    )
  }

  getListaGed() {
    this.produtorRuralService.getListaGedIdentificador().subscribe(
      res => {
        this.listaGed = res;
      }
    )
  }

  getListaCnae() {
    let params = {
      filtrar: 'true'
    }
    this.produtorRuralService.getListaCnae(params).subscribe(
      res => {
        this.listaCnae = res;
      }
    )
  }

  pegarCodigoContribuinte(event) {
    this.loading = true;
    this.bufferObj.codigoContribuinte = event;
    this.produtorRuralService.getProdutorRural(event).subscribe(
      res => {
        this.dadosProdutorRural = res;
      },
      err => {
        Swal.fire({
          icon: 'error',
          text: 'Ops! Ocorreu um erro ao pegar os dados do Produtor Rural.'
        });
        this.loading = false;
      },
      () => {
        this.loading = false;
        this.active2 = false;
        this.active3 = true;
      }
    )
  }

  passarEtapa(etapa, solicitante?) {
    console.log(this.dadosEditados);
    switch(etapa) {
      case 1:
        this.dadosSolicitante = solicitante;
        this.active1 = false;
        this.active2 = true;
        break;
      case 3:
        this.active3 = false;
        this.active4 = true;
        break;
      case 4:
        this.active4 = false;
        this.active5 = true;
        break;
      case 5:
        this.active5 = false;
        this.active6 = true;
        break;
      case 6:
        this.active6 = false;
        this.active7 = true;
        break;
      case 7:
        this.active7 = false;
        this.active8 = true;
        break;
      case 8:
        this.active8 = false;
        this.active9 = true;
        break;
      case 9:
        this.active9 = false;
        this.active10 = true;
        break; 
      default: 
    }
  }

  voltarEtapa(etapa) {
    switch(etapa) {
      case 2:
        this.active1 = true;
        this.active2 = false;
        break;
      case 3:
        this.active2 = true;
        this.active3 = false;
        break;
      case 4:
        this.active3 = true;
        this.active4 = false;
        break;
      case 5:
      this.active4 = true;
      this.active5 = false;
      break;
      case 6:
        this.active5 = true;
        this.active6 = false;
        break;
      case 7:
        this.active6 = true;
        this.active7 = false;
        break;
      case 8:
      this.active7 = true;
      this.active8 = false;
      break;
      case 9:
        this.active8 = true;
        this.active9 = false;
        break;
      case 10:
        this.active9 = true;
        this.active10 = false;
        break;    
      default:    
    }
  }

  desistir() {
    Swal.fire({
      icon: 'question',
      text: 'Atenção! Desistir neste momento implica em descartar todos os dados digitados. Confirma?',
      confirmButtonText: 'Sim',
      showCancelButton: true,
      cancelButtonText: 'Não'
    }).then(result => {
      if (result.isConfirmed) {
        this.router.navigate(['cadastro/produtor-rural/inscricao']);
      } else if (result.isDenied) {
        Swal.close();
      }
    })
  }
}