import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { CaixaPostalService } from "src/app/services/caixa-postal.service";

@Component({
  selector: 'app-definir-tipo-comunicado',
  templateUrl: './definir-tipo-comunicado.component.html',
  styleUrls: ['./definir-tipo-comunicado.component.scss']
})

export class DefinirTipoComunicadoComponent implements OnInit {
  @Output() fecharModal = new EventEmitter();
  @Output() proximo = new EventEmitter();

  listaTipoComunicados: Array<any> = [];
  tipoComunicadoSelecionado: any = null;
  classCheck: string = 'unchecked';
  loading: boolean = false;
  tipoComunicadosOriginal: Array<any>;
  filtro: string = '';

  constructor(
    private caixaPostalService: CaixaPostalService
  ) {
    
  }

  ngOnInit(): void {
    this.loading = true;
    this.getTiposComunicados();
  }

  getTiposComunicados() {
    this.caixaPostalService.getTiposComunicados().subscribe((res: Array<any>) => {
      const comunicadosPermitidos = res.filter(tipoComunicado => tipoComunicado.numContIniciar == true);
      this.listaTipoComunicados = comunicadosPermitidos;
      this.tipoComunicadosOriginal = comunicadosPermitidos;
    }, error => {

    }, () => {
      this.loading = false;
    });
  }

  filtrar() {
    if(this.filtro == '') {
      this.listaTipoComunicados = this.tipoComunicadosOriginal;
      return
    }

    this.listaTipoComunicados = this.listaTipoComunicados.filter(tipoComunicado => {
      const dscTipoComunicado = tipoComunicado.dscTipoComunicado.toLowerCase();
      const filtro = this.filtro.toLowerCase();
      return dscTipoComunicado.includes(filtro);
    });
  }

  selecionar(tipoComunicado) {
    this.tipoComunicadoSelecionado = tipoComunicado;
    this.classCheck = 'checked'
  }

  fecharTipoComunicado() {
    this.fecharModal.emit();
  }

  proximoPasso() {
    this.proximo.emit(this.tipoComunicadoSelecionado);
  }
}