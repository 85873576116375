<section class="margin-top container">
  <div class="backdrop">
    <div class="div br-modal xx-large position-modal scroll-modal">
      <div class="br-modal-header">Enviar Comunicado</div>
      <div class="br-modal-body overflow">
        <div class="d-flex justify-content-between">
          <p class="subtitulo">
            {{ tipoComunicado.dscTipoComunicado }}
          </p>
          <p>{{ dataHora }}</p>
        </div>

        <div class="row">
          <div class="col-4 mb-4">
            <div class="br-input">
              <label>Assunto<span class="obrigatorio label">*</span></label>
              <input type="text" placeholder="Assunto" [(ngModel)]="assunto"/>
            </div>
          </div>
        </div>
        <p-editor
          [(ngModel)]="mensagem"
          [style]="{ height: '220px' }"
          placeholder="Escreva Aqui..."
          (onTextChange)="contarCaracteres($event)"
        >
          <ng-template pTemplate="header">
            <span class="ql-formats">
              <button
                type="button"
                class="ql-bold btnEditor"
                aria-label="Bold"
                title="Negrito"
              ></button>
              <button
                type="button"
                class="ql-italic btnEditor"
                aria-label="Italic"
                title="Itálico"

              ></button>
              <button
                type="button"
                class="ql-underline btnEditor"
                aria-label="Underline"
                title="Sublinhado"
              ></button>
              <button
                type="button"
                class="ql-clean btnEditor"
                aria-label="Clean"
                title="Remover estilo da fonte"
              ></button>
            </span>
          </ng-template>
        </p-editor> 

        <div class="caracteres">
          <fa-icon
            [icon]="['fas', quantCaracteres >= 20 ? 'check' : 'xmark']"
            [ngStyle]="{ color: quantCaracteres >= 20 ? '#168821' : '#E52207' }"
            aria-hidden="true"
          >
          </fa-icon>
          <span>No mínimo 20 caracteres</span>
        </div>
      </div>
      <div class="br-modal-footer justify-content-end">
        <button
          class="br-button primary desistir mr-4"
          type="button"
          (click)="desistir()"
        >
          Desistir
        </button>
        <button
          class="br-button primary"
          type="button"
          (click)="enviarComunicado()"
          [disabled]="this.assunto.length == 0 || quantCaracteres < 20"
        >
          Enviar Comunicado
        </button>
      </div>
    </div>
  </div>
</section>

<div class="carregamento" *ngIf="loading">
  <img src="../../../assets/images/icones/logo-animado.svg" />
</div>
