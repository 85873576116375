import { Component, OnInit } from "@angular/core";

import { ProdutorRuralService } from "src/app/services/produtor-rural.service";
import * as dayjs from "dayjs";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { UsuarioLogadoService } from "src/app/services/usuarioLogado.service";

@Component({
  selector: 'app-acompanhamento',
  templateUrl: './acompanhamento.component.html',
  styleUrls: ['./acompanhamento.component.scss']
})
export class AcompanhamentoProdutorRuralComponent {

  loading: boolean = false;
  protocoloFiltro: string = '';
  dadosUsuario: any;
  dadosContribuinte: any;
  contribuinteFormatado: any;
  contribuinte: number;
  paginaSolicitacao: number = 0;
  quantidadeDePaginasSolicitacao: number;
  tamanhoPaginaSolicitacao: number = 10;
  solicitacoes: any;
  paginaMovimentacao: number = 0;
  quantidadeDePaginasMovimentacao: number;
  tamanhoPaginaMovimentacao: number = 5;
  movimentacoes: any;
  modalAtivo: boolean = false;
  codigoSolicitacao: number;
  filtroAtivo: boolean = false;

  constructor(
    private produtorRuralService: ProdutorRuralService,
    private router: Router,
    private usuarioLogadoService: UsuarioLogadoService
  ) {

  }

  ngOnInit(): void {
    setTimeout(() => {
      this.dadosUsuario = this.usuarioLogadoService.getUsuarioLogado();
      this.contribuinte = this.dadosUsuario.codigoCadastro;
      this.getSolicitacoes(this.contribuinte, this.paginaSolicitacao, this.tamanhoPaginaSolicitacao);
    }, 200);
  }

  getSolicitacoes(contribuinte, pagina, tamanhoPagina) {
    this.loading = true;
    this.produtorRuralService.getSolicitacoes(contribuinte, pagina, tamanhoPagina).subscribe(
      res => {
        this.solicitacoes = res['bics'];
        this.quantidadeDePaginasSolicitacao = res['totalElementos'] / this.tamanhoPaginaSolicitacao;
        this.quantidadeDePaginasSolicitacao = Math.trunc(this.quantidadeDePaginasSolicitacao);
        let modulo = res['totalElementos'] % this.tamanhoPaginaSolicitacao; 

        if (modulo != 0 && this.quantidadeDePaginasSolicitacao != 0) {
          this.quantidadeDePaginasSolicitacao = this.quantidadeDePaginasSolicitacao + 1;
        }

        if (this.quantidadeDePaginasSolicitacao == 0) {
          this.quantidadeDePaginasSolicitacao = 1;
        }
      },
      err => {
        Swal.fire({
          icon: 'error',
          text: 'Ops! Ocorreu um erro ao receber as Solicitações'
        });
        this.router.navigate(['/produtor-rural']);
      },
      () => {
        this.loading = false;
      }
    )
  }

  getMovimentacoes(codigo, pagina, tamanhoPagina) {
    this.produtorRuralService.getMovimentacoes(codigo, pagina, tamanhoPagina).subscribe(
      res => {
        this.movimentacoes = res['bicSituacoes'];
        this.quantidadeDePaginasMovimentacao = res['totalElementos'] / this.tamanhoPaginaMovimentacao;
        this.quantidadeDePaginasMovimentacao = Math.trunc(this.quantidadeDePaginasMovimentacao);
        let modulo = res['totalElementos'] % this.tamanhoPaginaMovimentacao; 

        if (modulo != 0 && this.quantidadeDePaginasMovimentacao != 0) {
          this.quantidadeDePaginasMovimentacao = this.quantidadeDePaginasMovimentacao + 1;
        }

        if (this.quantidadeDePaginasMovimentacao == 0) {
          this.quantidadeDePaginasMovimentacao = 1;
        }
        for (let i = 0; i < this.movimentacoes.length; i++) {
          if (this.movimentacoes[i].datFim) {
            const datFimFormatada = dayjs(this.movimentacoes[i].datFim).format('MM/DD/YYYY');
            const datRegistroFormatada = dayjs(this.movimentacoes[i].datRegistro).format('MM/DD/YYYY');
            const datFim = new Date(datFimFormatada);
            const datRegistro = new Date(datRegistroFormatada);
            
            const time = datFim.getTime() - datRegistro.getTime();
            this.movimentacoes[i].dias = time / (1000 * 3600 * 24);
          } else {
            this.movimentacoes[i].dias = dayjs(this.movimentacoes[i].datRegistro).format('DD/MM/YYYY');
          }
        }
      },
      err => {
        Swal.fire({
          icon: 'error',
          text: 'Ops! Ocorreu um erro ao receber as Movimentações'
        });
        this.router.navigate(['/produtor-rural']);
      },
      () => {
        this.loading = false;
      }
    )
  }

  buscarProtocolo() {
    this.produtorRuralService.getSolicitacaoPorProtocolo(this.protocoloFiltro).subscribe(
      res => {
        this.solicitacoes = res;
      },
      err => {
        Swal.fire({
          'icon': 'error',
          'text': 'Não foi encontrada nenhuma solicitação com esse número de protocolo.'
        })
      },
      () => {
        this.filtroAtivo = true;
      }
    )
  }

  limparFiltro() {
    this.getSolicitacoes(this.contribuinte, this.paginaSolicitacao, this.tamanhoPaginaSolicitacao);
    this.filtroAtivo = false;
    this.protocoloFiltro = '';
  }

  abrirModal(codigo) {
    this.loading = true;
    this.codigoSolicitacao = codigo;
    this.getMovimentacoes(codigo, this.paginaMovimentacao, this.tamanhoPaginaMovimentacao);
    this.modalAtivo = true;
  }

  fecharModal() {
    this.modalAtivo = false;
    this.paginaMovimentacao = 0;
  }

  primeiraPagina(tabela: string) {
    if (tabela == 'solicitacao') {
      this.paginaSolicitacao = 0;
      this.getSolicitacoes(this.contribuinte, this.paginaSolicitacao, this.tamanhoPaginaSolicitacao);
    } else {
      this.paginaMovimentacao = 0;
      this.getMovimentacoes(this.codigoSolicitacao, this.paginaMovimentacao, this.tamanhoPaginaMovimentacao);
    }
  }

  paginaAnterior(tabela: string) {
    if (tabela == 'solicitacao') {
      this.paginaSolicitacao -= 1;
      this.getSolicitacoes(this.contribuinte, this.paginaSolicitacao, this.tamanhoPaginaSolicitacao);
    } else {
      this.paginaMovimentacao -= 1;
      this.getMovimentacoes(this.codigoSolicitacao, this.paginaMovimentacao, this.tamanhoPaginaMovimentacao);
    }
  }

  proximaPagina(tabela: string) {
    if (tabela == 'solicitacao') {
      this.paginaSolicitacao += 1;
      this.getSolicitacoes(this.contribuinte, this.paginaSolicitacao, this.tamanhoPaginaSolicitacao);
    } else {
      this.paginaMovimentacao += 1;
      this.getMovimentacoes(this.codigoSolicitacao, this.paginaMovimentacao, this.tamanhoPaginaMovimentacao);
    }
  }

  ultimaPagina(tabela: string) {
    if (tabela == 'solicitacao') {
      this.paginaSolicitacao = this.quantidadeDePaginasSolicitacao - 1;
      this.getSolicitacoes(this.contribuinte, this.paginaSolicitacao, this.tamanhoPaginaSolicitacao);
    } else {
      this.paginaMovimentacao = this.quantidadeDePaginasMovimentacao - 1;
      this.getMovimentacoes(this.codigoSolicitacao, this.paginaMovimentacao, this.tamanhoPaginaMovimentacao);
    }
  }
}