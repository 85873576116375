<div class="ml-5">
  <h3>Sócios</h3>
  <div class="d-flex align-items-end">
    <button class="br-button primary mb-2" type="button" (click)="abrirModal()" [disabled]="!liberarAdicao">Adicionar</button>
    <p 
      class="mensagem-erro-negrito ml-3" 
      *ngIf="!liberarAdicao && dadosEditados.imovelDTO.tipoUsoImovel.codigo == 1"
      >Somente um sócio é permitido quando a Categoria de Ocupação selecionada for "Próprio"
    </p>
    <p
      class="mensagem-erro-negrito ml-3"
      *ngIf="!liberarAdicao && participacaoCounter == 100 && dadosEditados.imovelDTO.tipoUsoImovel.codigo != 1"
      >Participação máxima atingida (100%)
    </p>
  </div>
  <div class="br-table">
    <table>
      <thead>
        <tr>
          <th scope="col">País</th>
          <th scope="col">Tipo Documento</th>
          <th scope="col">Documento</th>
          <th scope="col">Nome</th>
          <th scope="col">Participação({{ participacaoCounter }}%)</th>
          <th scope="col">Data entrada do sócio</th>
          <th scope="col">Data saída do sócio</th>
          <th scope="col">Deletar</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="socios.length > 0; else semSocios"></ng-container>
        <tr *ngFor="let item of socios; index as i">
          <td>
            {{ 
              item.socioEndereco.endereco.pais.dscPais
              ? item.socioEndereco.endereco.pais.dscPais
              : listaPaises[item.socioEndereco.endereco.pais.codigo - 1].dscPais
            }}
          </td>
          <td>
            {{ 
              item.tipoGed.descricaoGed
              ? item.tipoGed.descricaoGed
              : listaGed[item.tipoGed.codigo - 7].descricaoGed
            }}
          </td>
          <td>{{ item.dscDocumento }}</td>
          <td>{{ item.dscNomeSocio }}</td>
          <td>{{ item.participacao }}%</td>
          <td>{{ item.datInicio | date: 'dd/MM/yyyy' }}</td>
          <td>{{ item.datFim ? (item.datFim | date: 'dd/MM/yyyy') : '-' }}</td>
          <td><img class="deletar" src="../../../../assets/images/icones/trash-solid.svg" alt="Lixeira" (click)="deletarSocio(i)"></td>
        </tr>
        <ng-template #semSocios>
          <tr>
            <td colspan="8" align="center">Sem dados para serem exibidos.</td>
          </tr>
        </ng-template>
      </tbody>
    </table>
  </div>
  <div class="div-botao mt-4">
    <button class="br-button primary posicao-botao" type="button" (click)="proximoPasso()" [disabled]="socios.length == 0">Próxima Etapa</button>
    <button class="br-button secondary posicao-botao mr-4" type="button" (click)="voltarPasso()">Voltar Etapa</button>
    <button class="br-button primary posicao-botao mr-4 desistir" type="button" (click)="desistir()">Desistir</button>
  </div>
</div>

<div class="backdrop" *ngIf="sociosAberto">
  <div class="div br-modal xx-large position-modal">
    <div class="br-modal-header">Adicionar Sócio</div>
    <div class="br-modal-body overflow">
      <form [formGroup]="form">
        <div class="row">
          <div class="col-4 filter-dropdown">
            <label class="label">País<span class="obrigatorio label">*</span></label>
            <p-dropdown 
              [options]="listaPaises"
              optionLabel="dscPais"
              optionValue="codigo"
              [filter]="true"
              filterBy="dscPais"
              placeholder="Selecione o País..."
              formControlName="pais"
              emptyFilterMessage="Sem resultados"
              [(ngModel)]="pais"
              (onChange)="verificarPais()"
            >
            </p-dropdown>
            <p 
              class="mensagem-erro" 
              *ngIf="form.controls.pais.invalid && form.controls.pais.touched"
            >
              Campo obrigatório
            </p>
          </div>
          <div class="col-4">
            <div class="br-input">
              <label>Tipo de Documento<span class="obrigatorio label">*</span></label>
              <input type="text" class="input" disabled>
              <select class="select" formControlName="tipoDocumento">
                <option value="" selected disabled>Selecione o Tipo de Documento...</option>
                <option *ngFor="let item of listaGed" [ngValue]="item.codigo">{{ item.descricaoGed }}</option>
              </select>
            </div>
            <p 
              class="mensagem-erro" 
              *ngIf="form.controls.tipoDocumento.invalid && form.controls.tipoDocumento.touched"
            >
              Campo obrigatório
            </p>
          </div>
          <div class="col-4">
            <div class="br-input">
              <label>Documento<span class="obrigatorio label">*</span></label>
              <input type="text" formControlName="documento" maxlength="60">
            </div>
            <p 
              class="mensagem-erro" 
              *ngIf="form.controls.documento.invalid && form.controls.documento.touched"
            >
              Campo obrigatório
            </p>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-4">
            <div class="br-input">
              <label>C.P.F / C.N.P.J<span class="obrigatorio label">*</span></label>
              <input 
                type="text" formControlName="cpfCnpj" mask="CPF_CNPJ" 
                (blur)="validarDigitoCpfCnpj()"
                >
            </div>
            <p 
              class="mensagem-erro" 
              *ngIf="form.controls.cpfCnpj.invalid && form.controls.cpfCnpj.touched"
            >
            Campo obrigatório / C.P.F ou C.N.P.J Inválido
            </p>
          </div>
          <div class="col-8">
            <div class="br-input">
              <label>Nome<span class="obrigatorio label">*</span></label>
              <input type="text" formControlName="nome" maxlength="160">
            </div>
            <p 
              class="mensagem-erro" 
              *ngIf="form.controls.nome.invalid && form.controls.nome.touched"
            >
              Campo obrigatório / Mínimo 2 Dígitos
            </p>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-4">
            <div class="br-input">
              <label>% Participação<span class="obrigatorio label">*</span></label>
              <input type="number" formControlName="participacao" maxlength="3" max="100">
            </div>
            <p 
              class="mensagem-erro" 
              *ngIf="form.controls.participacao.invalid && form.controls.participacao.touched"
            >
              Campo obrigatório / Excedeu o número máximo(100)
            </p>
          </div>
          <div class="col-4">
            <label class="mb-3">Tipo de Pessoa:<span class="obrigatorio label">*</span></label>
            <div class="br-radio">
              <input id="socioJuridica" type="radio" value="2" formControlName="tipoPessoa">
              <label class="mr-5" for="socioJuridica">Pessoa Jurídica</label>
              <input id="socioFisica" type="radio" value="1" formControlName="tipoPessoa" #tipoPessoaSocio>
              <label for="socioFisica">Pessoa Física</label>
            </div>
          </div>
          <div class="col-4">
            <label class="mb-3">É o Administrador?<span class="obrigatorio label">*</span></label>
            <div class="br-radio">
              <input id="administradorSim" type="radio" name="administrador" value="1" formControlName="administrador">
              <label class="mr-5" for="administradorSim">Sim</label>
              <input id="administradorNao" type="radio" name="administrador" value="0" formControlName="administrador">
              <label for="administradorNao">Não</label>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-4">
            <div class="br-input">
              <label>Data da Entrada na sociedade<span class="obrigatorio label">*</span></label>
            </div>
            <dp-date-picker 
                theme="dp-material"
                [config]="datePickerConfig"
                formControlName="dataEntrada"
                [(ngModel)]="dataInicio"
                (onChange)="getDataInicio($event)"
              >
            </dp-date-picker>
            <p 
              class="mensagem-erro" 
              *ngIf="form.controls.dataEntrada.invalid && form.controls.dataEntrada.touched"
            >
              Campo obrigatório
            </p>
          </div>
          <div class="col-4">
            <div class="br-input">
              <label>Data de Saída da sociedade<span class="igualar-altura label">*</span></label>
            </div>
            <dp-date-picker 
                theme="dp-material"
                [config]="datePickerConfig"
                formControlName="dataSaida"
                [(ngModel)]="dataFim"
                (onChange)="getDataFim($event)"
              >
            </dp-date-picker>
          </div>
        </div>
        <div *ngIf="form.controls.pais.value != 30 && form.controls.pais.value > 0">
        <div class="row mt-4 mb-2">
            <div class="col">
              <div class="br-input">
                <label>Endereço completo no País de origem<span class="obrigatorio label">*</span></label>
                <input type="text" formControlName="enderecoCompleto" maxlength="200">
              </div>
              <p 
              class="mensagem-erro" 
              *ngIf="form.controls.dataEntrada.invalid && form.controls.dataEntrada.touched"
            >
              Campo obrigatório
            </p>
            </div>
          </div>
        </div>
        <div *ngIf="form.controls.pais.value == 30 && form.controls.pais.value > 0">
          <div class="row mt-4">
            <div class="col-4">
              <div class="br-input">
                <label>C.E.P<span class="obrigatorio label">*</span></label>
                <input type="text" class="input" formControlName="cep" mask="00.000-000" (blur)="validarCep()" #cepSocios>
              </div>
              <p 
                class="mensagem-erro" 
                *ngIf="form.controls.cep.invalid && form.controls.cep.touched"
              >
                Campo obrigatório / Mínimo 8 dígitos
              </p>
            </div>
            <div class="col-4">
              <div class="br-input">
                <label>Logradouro<span class="igualar-altura label">*</span></label>
                <input type="text" formControlName="logradouro">
              </div>
            </div>
            <div class="col-4">
              <div class="br-input">
                <label>Número<span class="obrigatorio label">*</span></label>
                <input type="text" formControlName="numero" maxlength="10">
              </div>
              <p 
                class="mensagem-erro" 
                *ngIf="form.controls.numero.invalid && form.controls.numero.touched"
              >
                Campo obrigatório
              </p>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-4">
              <div class="br-input">
                <label>Tipo Complemento</label>
                <input type="text" class="input" disabled>
                <select class="select" formControlName="tipoComplemento" (change)="validarTipoComplemento()">
                  <option value="" selected disabled>Selecione o Tipo de Complemento...</option>
                  <option *ngFor="let item of listaComplemento; index as i" [ngValue]="i">{{ item.dscTipoComplemento }}</option>
                </select>
              </div>
            </div>
            <div class="col-4">
              <div class="br-input">
                <label>Complemento</label>
                <input type="text" formControlName="complemento" maxlength="20">
              </div>
              <p 
                class="mensagem-erro" 
                *ngIf="form.controls.complemento.invalid"
              >
                Campo obrigatório
              </p>
            </div>
            <div class="col-2">
              <div class="br-input">
                <label>Latitude</label>
                <input type="text" formControlName="latitude" maxlength="20">
              </div>
              <p 
                class="mensagem-erro" 
                *ngIf="form.controls.latitude.invalid"
              >
                Mínimo -90 e máximo 90
              </p>
            </div>
            <div class="col-2">
              <div class="br-input">
                <label>Longitude</label>
                <input type="text" formControlName="longitude" maxlength="20">
              </div>
              <p 
                class="mensagem-erro" 
                *ngIf="form.controls.longitude.invalid"
              >
                Mínimo -180 e máximo 180
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="br-modal-footer justify-content-center">
      <button class="br-button primary desistir mr-4" type="button" (click)="fecharModal()">Desistir</button>
      <button class="br-button primary" type="button" (click)="adicionarSocio()" [disabled]="!form.valid">Adicionar</button>
    </div>
  </div>
</div>

<div class="carregamento" *ngIf="loading">
  <img src="../../../assets/images/icones/logo-animado.svg">
</div>
<app-loading
  *ngIf="loadingReceita"
  [mensagem]="'Processando análise junto a Receita Federal, aguarde...'"
>
</app-loading>