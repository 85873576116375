import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable()
export class ContribuinteService {

  private apiUrl = environment.API_URL

  constructor(private http: HttpClient) { }

  getDadosListaBic() {
    return this.http.get(`${this.apiUrl}/contribuinte/solicitacao/tiposSolicitacao`);
  }

  criarSolicitacao(obj) {
    return this.http.post(`${this.apiUrl}/contribuinte/solicitacao`, obj);
  }

  criarCadastroContribuinte(codigoCadastro) {
    return this.http.get(`${this.apiUrl}/contribuinte/autoCadastro/contribuintePF/${codigoCadastro}`);
  }
}
