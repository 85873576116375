import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ProdutorRuralAlterar } from "src/app/features/common/interface/produtorRuralAlterar";

import { ContribuinteService } from "src/app/services/contribuinte.service";

@Component({
  selector: 'app-alteracao-produtor-etapa-1',
  templateUrl: './etapa-1.component.html',
  styleUrls: ['./etapa-1.component.scss']
})
export class Etapa1Component implements OnInit {
  @Output() passarEtapa = new EventEmitter();

  @Output() sair = new EventEmitter();

  @Input() contribuinte: any;

  @Input() buffer: any;

  @Input() listaBic: any;

  cpfSolicitante: string;
  solicitante: string;

  constructor() {

  }

  ngOnInit(): void {    
    setTimeout(() => {
      this.solicitante = this.contribuinte.dscRazaoSocial;
      this.cpfSolicitante = this.contribuinte.cadastro.cpfCnpj;
    }, 500);
  }

  proximaEtapa() {
    let obj = {
      solicitante: this.solicitante,
      cpfSolicitante: this.cpfSolicitante
    }
    this.passarEtapa.emit(obj);
  }

  desistir() {
    this.sair.emit();
  }

}