import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as dayjs from "dayjs";
import { CaixaPostalService } from "src/app/services/caixa-postal.service";
import { CriaComunicado } from "../../common/interface/criaComunicado";
import Swal from "sweetalert2";

@Component({
  selector: 'app-enviar-comunicado',
  templateUrl: './enviar-comunicado.component.html',
  styleUrls: ['./enviar-comunicado.component.scss']
})

export class EnviarComunicadoComponent implements OnInit {
  @Output() fecharModal = new EventEmitter();
  @Input() tipoComunicado = null;

  loading: boolean = false;
  dataHora: string = dayjs().format('DD/MM/YYYY HH:mm');
  mensagem: string = '';
  quantCaracteres: number = 0;
  assunto: string = '';
  dadosUsuario: any;
  objUsuario: any;
  config;

  constructor(
    private caixaPostalService: CaixaPostalService
  ) {
    dayjs.locale('pt-br');
  }

  ngOnInit(): void {
    this.dadosUsuario = localStorage.getItem('contribuinteEmUso');
    this.objUsuario = JSON.parse(this.dadosUsuario);
  }

  enviarComunicado() {
    this.loading = true;

    const dataAtual = dayjs().format("YYYY-MM-DDTHH:mm:ss");
    const seqCadastro = this.objUsuario.cadastro.codigo;
    const seqContribuinte = this.objUsuario.codigo;
    const seqTipoComunicado = this.tipoComunicado.seqTipoComunicado;
    
    const obj: CriaComunicado = {
      dscAssunto: this.assunto,
      seqCadastro: seqCadastro, 
      seqContribuinte: seqContribuinte,
      seqTipoComunicado: seqTipoComunicado, 
      txtMensagem: this.mensagem,
      datCaixaPostal: dataAtual
    }

    this.caixaPostalService.enviarComunicado(obj).subscribe(res => {
      this.loading = false;
      Swal.fire({
        icon: 'success',
        text: 'Comunicado enviado com sucesso'
      }).then(() =>{
        this.fecharModal.emit();
      });
    }, (error) => {
      this.loading = false;
      Swal.fire({
        icon: 'error',
        text: 'Ops! Ocorreu um erro ao enviar o comunicado'
      });
    });
  }

  desistir() {
    this.fecharModal.emit();
  }

  contarCaracteres(event) {
    this.quantCaracteres = event.textValue.length;
  }

}