<div class="ml-5">
  <h3>Resumo</h3>
  <div>
    <h4>Solicitação Cadastral</h4>
    <div class="container">
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">Motivo:</label>
          <p class="pt-1 ml-2">{{ buffer.motivo }}</p>
        </div>
        <div class="col-6 d-flex">
          <label class="titulo">C.P.F:</label>
          <p class="pt-1 ml-2">{{ buffer.cpf | mask: 'CPF_CNPJ' }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">Nome:</label>
          <p class="pt-1 ml-2">{{ buffer.solicitante }}</p>
        </div>
      </div>
    </div>
  </div>
  <div>
    <h4>Identificador do Produtor Rural</h4>
    <div class="container">
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">Tipo de Contribuinte:</label>
          <p class="pt-1 ml-2">Pessoa Física</p>
        </div>
        <div class="col-6 d-flex">
          <label class="titulo">Inscrição no C.P.F:</label>
          <p class="pt-1 ml-2">{{ dadosProdutorRural.cadastro.cpfCnpj | mask: 'CPF_CNPJ' }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">Nome ou Razão Social:</label>
          <p class="pt-1 ml-2">{{ dadosProdutorRural.cadastro.dscNomeCadastro }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6 d-flex">
          <label class="titulo">Data de Início:</label>
          <p class="pt-1 ml-2">{{ dadosProdutorRural.contribuinte.datInicioAtiv | date:'dd/MM/yyyy' }}</p>
        </div>
        <div class="col-6 d-flex">
          <label class="titulo">Término da Atividade:</label>
          <p class="pt-1 ml-2">
            {{ 
              dadosProdutorRural.contribuinte.datFimAtiv != 'null'
              ? (dadosProdutorRural.contribuinte.datFimAtiv | date: 'dd/MM/yyyy')
              : 'Não Informado'
            }}
          </p>
        </div> 
      </div>
    </div>
    <div>
      <h4>Endereço da Propriedade Rural</h4>
      <div class="container">
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">C.E.P:</label>
            <p class="pt-1 ml-2">{{ dadosProdutorRural.imovelDTO.endereco.cep.codCep | mask: '00000-000' }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Logradouro:</label>
            <p class="pt-1 ml-2">{{ dadosProdutorRural.imovelDTO.endereco.cep.dscLogradouro }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Município:</label>
            <p class="pt-1 ml-2">{{ dadosProdutorRural.imovelDTO.endereco.cep.cidade.dscCidade }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Estado:</label>
            <p class="pt-1 ml-2">{{ dadosProdutorRural.imovelDTO.endereco.cep.cidade.estado.dscUf }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Número:</label>
            <p class="pt-1 ml-2">{{ dadosProdutorRural.imovelDTO.endereco.dscNumero }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Tipo Complemento:</label>
            <p class="pt-1 ml-2">
              {{ dadosProdutorRural.imovelDTO.endereco.tipoComplemento.dscTipoComplemento }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Complemento:</label>
            <p class="pt-1 ml-2">
              {{ 
                dadosProdutorRural.imovelDTO.endereco.dscComplemento 
                ? dadosProdutorRural.imovelDTO.endereco.dscComplemento
                : 'Não Informado'
              }}
            </p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Localização:</label>
            <p class="pt-1 ml-2">Zona Rural</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Latitude:</label>
            <p class="pt-1 ml-2">
              {{ 
                dadosProdutorRural.imovelDTO.endereco.latitude 
                ? dadosProdutorRural.imovelDTO.endereco.latitude
                : 'Não Informado'
              }}
            </p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Longitude:</label>
            <p class="pt-1 ml-2">
              {{ 
                dadosProdutorRural.imovelDTO.endereco.longitute 
                ? dadosProdutorRural.imovelDTO.endereco.longitute
                : 'Não Informado'
              }}</p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h4>Endereço da Correspondência Fiscal</h4>
      <div class="container">
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">C.E.P:</label>
            <p class="pt-1 ml-2">{{ dadosProdutorRural.contribuinteEndereco.endereco.cep.codCep | mask:'00000-000' }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Logradouro:</label>
            <p class="pt-1 ml-2">{{ dadosProdutorRural.contribuinteEndereco.endereco.cep.dscLogradouro }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Município:</label>
            <p class="pt-1 ml-2">{{ dadosProdutorRural.contribuinteEndereco.endereco.cep.cidade.dscCidade }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Estado:</label>
            <p class="pt-1 ml-2">{{ dadosProdutorRural.contribuinteEndereco.endereco.cep.cidade.estado.dscUf }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Número:</label>
            <p class="pt-1 ml-2">{{ dadosProdutorRural.contribuinteEndereco.endereco.dscNumero }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Tipo Complemento:</label>
            <p class="pt-1 ml-2">{{ dadosProdutorRural.contribuinteEndereco.endereco.tipoComplemento.dscTipoComplemento }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Complemento:</label>
            <p class="pt-1 ml-2">
              {{
                dadosProdutorRural.contribuinteEndereco.endereco.dscComplemento 
                ? dadosProdutorRural.contribuinteEndereco.endereco.dscComplemento
                : 'Não Informado'
              }}
            </p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Localização:</label>
            <p class="pt-1 ml-2">{{ dadosProdutorRural.contribuinteEndereco.endereco.naturezaImovel.dscNaturezaImovel }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Latitude:</label>
            <p class="pt-1 ml-2">
              {{ 
                dadosProdutorRural.contribuinteEndereco.endereco.latitude 
                ? dadosProdutorRural.contribuinteEndereco.endereco.latitude
                : 'Não Informado'
              }}
            </p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Longitude:</label>
            <p class="pt-1 ml-2">
              {{ 
                dadosProdutorRural.contribuinteEndereco.endereco.longitute 
                ? dadosProdutorRural.contribuinteEndereco.endereco.longitute
                : 'Não Informado'
              }}</p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h4>Informações Econômicas e Fiscais</h4>
      <div class="container">
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Classificação do Contribuinte:</label>
            <p class="pt-1 ml-2">Produtor Rural</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Protocolo Registro na Junta Comercial:</label>
            <p class="pt-1 ml-2">Não Informado</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Data do Registro na Junta Comercial:</label>
            <p class="pt-1 ml-2">Não Informado</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Capital Social:</label>
            <p class="pt-1 ml-2">R$ {{ dadosProdutorRural.contribuinte.vrlCapitalSocial | mask: 'separator': {thousandSeparator: '.'} }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Regime de Recolhimento:</label>
            <p class="pt-1 ml-2">N/A</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Natureza Jurídica:</label>
            <p class="pt-1 ml-2">Produtor Rural (Pessoa Física)</p>
          </div>
        </div>
        <div class="row">
            <label class="titulo">CNAEs({{ listaCnae.length }}):</label>
        </div>
        <div *ngFor="let item of listaCnae">
          <div class="row">
            <div class="col d-flex">
              <label class="titulo">CNAE - Primária - Exercido no endereço do estabelecimento:</label>
            </div>
          </div>
          <div class="row">
            <div class="col d-flex">
            <p class="pt-1 ml-2">{{ item.cnae.dscCnae }} - {{ item.numPrincipal == 1 ? 'Sim' : 'Não' }} - {{ item.numExerceNoEnd == 1 ? 'Sim' :  'Não' }}</p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h4>Informações sobre a exploração rural</h4>
      <div class="container">
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Categoria de Ocupação:</label>
            <p class="pt-1 ml-2">{{ dadosProdutorRural.imovelDTO.tipoUsoImovel.descricao }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Data de aquisição:</label>
            <p class="pt-1 ml-2">{{ dadosProdutorRural.imovelDTO.datAquisicao | date: 'dd/MM/yyyy' }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Inscrição no INCRA:</label>
            <p class="pt-1 ml-2">{{ dadosProdutorRural.imovelDTO.dscInscricao }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Nome do Imóvel:</label>
            <p class="pt-1 ml-2">{{ dadosProdutorRural.imovelDTO.dscNomeImovel }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Área do Imóvel:</label>
            <p class="pt-1 ml-2">{{ dadosProdutorRural.imovelDTO.numAreaTerreno }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Área de pastagem natural:</label>
            <p class="pt-1 ml-2">
              {{ 
                dadosProdutorRural.imovelDTO.numAreaPastagemNatural 
                ? dadosProdutorRural.imovelDTO.numAreaPastagemNatural
                : 'Não Informado'
              }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Área de pastagem formada:</label>
            <p class="pt-1 ml-2">
              {{ 
                dadosProdutorRural.imovelDTO.numAreaPastagemFormada 
                ? dadosProdutorRural.imovelDTO.numAreaPastagemFormada
                : 'Não Informado'
              }}
            </p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Área inexplorada:</label>
            <p class="pt-1 ml-2">
              {{ 
                dadosProdutorRural.imovelDTO.numAreaInexplorada 
                ? dadosProdutorRural.imovelDTO.numAreaInexplorada
                : 'Não Informado'
              }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Área cultivada:</label>
            <p class="pt-1 ml-2">
              {{ 
                dadosProdutorRural.imovelDTO.numAreaCultivada 
                ? dadosProdutorRural.imovelDTO.numAreaCultivada
                : 'Não Informado'
              }}  
            </p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Valor estimado do imóvel:</label>
            <p class="pt-1 ml-2">R$ {{ dadosProdutorRural.imovelDTO.vlrImovel | mask: 'separator': {thousandSeparator: '.'} }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col d-flex">
            <label class="titulo">Observação:</label>
            <p class="pt-1 ml-2">
              {{ 
                dadosProdutorRural.imovelDTO.observacao 
                ? dadosProdutorRural.imovelDTO.observacao
                : 'Não Informado'
              }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col d-flex">
            <label class="titulo">Arquivos({{ listaArquivos.length}}):</label>
            <div *ngFor="let item of listaArquivos">
              <p class="pt-1 ml-2">{{ item.ged.nomeArquivo }} | </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h4>Contabilista Responsável</h4>
      <div class="container">
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">C.P.F:</label>
            <p class="pt-1 ml-2">
              {{ 
                dadosProdutorRural.contabilistaResponsavel.cadastro.cpfCnpj
                ? (dadosProdutorRural.contabilistaResponsavel.cadastro.cpfCnpj | mask:'CPF_CNPJ')
                : 'Não Informado' 
              }}
            </p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Nome:</label>
            <p class="pt-1 ml-2">
              {{ 
                dadosProdutorRural.contabilistaResponsavel.cadastro.dscNomeCadastro 
                ? dadosProdutorRural.contabilistaResponsavel.cadastro.dscNomeCadastro
                : 'Não Informado'
              }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">C.R.C:</label>
            <p class="pt-1 ml-2">123</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">E-mail:</label>
            <p class="pt-1 ml-2">
              {{ 
                dadosProdutorRural.contabilistaResponsavel.cadastro.emails 
                ? dadosProdutorRural.contabilistaResponsavel.cadastro.emails[indexEmail - 1].descricao
                : 'Não Informado'
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h4>Sócios({{ listaSocios.length }})</h4>
      <div class="container" *ngFor="let item of listaSocios">
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">País:</label>
            <p class="pt-1 ml-2">{{ item.socioEndereco.endereco.pais.dscPais }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Tipo de Documento:</label>
            <p class="pt-1 ml-2">{{ item.tipoGed.descricaoGed }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Documento:</label>
            <p class="pt-1 ml-2">{{ item.dscDocumento }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Nome:</label>
            <p class="pt-1 ml-2">{{ item.dscNomeSocio }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Participação:</label>
            <p class="pt-1 ml-2">{{ item.participacao }}%</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Tipo de Pessoa:</label>
            <p class="pt-1 ml-2">{{ item.numTipoPessoa == 1 ? 'Pessoa Física' : 'Pessoa Jurídica' }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">É o Administrador?:</label>
            <p class="pt-1 ml-2">{{ item.administrador == 0 ? 'Não' : 'Sim' }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Data da Entrada na sociedade:</label>
            <p class="pt-1 ml-2">{{ item.datInicio | date: 'dd/MM/yyyy' }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Date de Saída da sociedade:</label>
            <p class="pt-1 ml-2">
              {{ 
                item.datFim
                ? (item.datFim | date: 'dd/MM/yyyy') 
                : 'Não Informado'
              }}
            </p>
          </div>
        </div>
        <div class="row mt-2" *ngIf="item.socioEndereco.endereco.pais.codigo != 30">
          <div class="col-6 d-flex">
            <label class="titulo">Endereço completo no país de origem:</label>
            <p class="pt-1 ml-2">{{ item.socioEndereco.endereco.dscEnderecoExterior }}</p>
          </div>
        </div>
        <div class="divisoria" *ngIf="item.socioEndereco.endereco.pais.codigo != 30"></div>
        <div *ngIf="item.socioEndereco.endereco.pais.codigo == 30">
          <div class="row mt-2">
            <div class="col-6 d-flex">
              <label class="titulo">C.E.P:</label>
              <p class="pt-1 ml-2">{{ item.socioEndereco.endereco.cep.codCep | mask: '00000-000' }}</p>
            </div>
            <div class="col-6 d-flex">
              <label class="titulo">Logradouro:</label>
              <p class="pt-1 ml-2">{{ item.socioEndereco.endereco.cep.dscLogradouro }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-6 d-flex">
              <label class="titulo">Município:</label>
              <p class="pt-1 ml-2">{{ item.socioEndereco.endereco.cep.cidade.dscCidade }}</p>
            </div>
            <div class="col-6 d-flex">
              <label class="titulo">Estado:</label>
              <p class="pt-1 ml-2">{{ item.socioEndereco.endereco.cep.cidade.estado.dscUf }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-6 d-flex">
              <label class="titulo">Número:</label>
              <p class="pt-1 ml-2">{{ item.socioEndereco.endereco.dscNumero }}</p>
            </div>
            <div class="col-6 d-flex">
              <label class="titulo">Tipo Complemento:</label>
              <p class="pt-1 ml-2">
                {{ 
                  item.socioEndereco.endereco.tipoComplemento
                  ? item.socioEndereco.endereco.tipoComplemento.dscTipoComplemento
                  : 'Não Informado'
                }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-6 d-flex">
              <label class="titulo">Complemento:</label>
              <p class="pt-1 ml-2">
                {{ 
                  item.socioEndereco.endereco.dscComplemento
                  ? item.socioEndereco.endereco.dscComplemento
                  : 'Não Informado'
                 }}
                </p>
            </div>
            <div class="col-6 d-flex">
              <label class="titulo">Latitude:</label>
              <p class="pt-1 ml-2">
                {{ 
                  item.socioEndereco.endereco.latitude 
                  ? item.socioEndereco.endereco.latitude
                  : 'Não Informado'
                }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-6 d-flex">
              <label class="titulo">Longitude:</label>
              <p class="pt-1 ml-2">
                {{ 
                  item.socioEndereco.endereco.longitute 
                  ? item.socioEndereco.endereco.longitute
                  : 'Não Informado'
                }}
              </p>
            </div>
          </div>
          <div class="divisoria"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="div-botao mt-4">
    <button class="br-button primary posicao-botao" type="button" (click)="proximaEtapa()">Próxima Etapa</button>
    <button class="br-button secondary posicao-botao mr-4" type="button" (click)="retornarEtapa()">Voltar Etapa</button>
    <button class="br-button primary posicao-botao mr-4 desistir" type="button" (click)="desistir()">Desistir</button>
  </div>
</div>