<div class="footer-area margem-footer">
    <div id="rodape">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
            <span class="fonte-rodape">2020 | &reg; Eicon - Todos os direitos reservados</span>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 text-center">
            <span class="fonte-rodape">{{ version }}</span>
          </div>
          <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <a href="https://transparencyreport.google.com/safe-browsing/search?url={{ dominio }}">
              <img src="assets/images/icones/icone-google-safe-browser.svg" alt="Google Safe Browser">
            </a>
          </div>
        </div>  
      </div>
    </div>
  </div>