import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { DpDatePickerModule } from "ng2-date-picker";
import { NgxMaskModule } from "ngx-mask";
import { CadastroRoutingModule } from "../../../cadastro.routing.module";
import { AlteracaoProdutorRuralComponent } from "./alteracao.component";
import { Etapa1Component } from "./etapa-1/etapa-1.component";
import { Etapa2Component } from "./etapa-2/etapa-2.component";
import { Etapa3Component } from "./etapa-3/etapa-3.component";
import { Etapa4Component } from "./etapa-4/etapa-4.component";
import { Etapa5Component } from "./etapa-5/etapa-5.component";
import { Etapa6Component } from "./etapa-6/etapa-6.component";
import { Etapa7Component } from "./etapa-7/etapa-7.component";
import { Etapa8Component } from "./etapa-8/etapa-8.component";
import { Etapa9Component } from "./etapa-9/etapa-9.component";
import { Etapa10Component } from "./etapa-10/etapa-10.component";
import { CommonAppModule } from "src/app/features/common/common.module";
import { DropdownModule } from "primeng/dropdown";

@NgModule({
  imports: [
    CommonModule,
    CommonAppModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DpDatePickerModule,
    DropdownModule,
    NgxMaskModule,
    CurrencyMaskModule,
    FontAwesomeModule,
    CadastroRoutingModule
  ],
  declarations: [
    AlteracaoProdutorRuralComponent,
    Etapa1Component,
    Etapa2Component,
    Etapa3Component,
    Etapa4Component,
    Etapa5Component,
    Etapa6Component,
    Etapa7Component,
    Etapa8Component,
    Etapa9Component,
    Etapa10Component
  ]
})
export class AlteracaoProdutorRuralModule {

}