<section class="margem-top servicos">
  <div class="servicos-titulo">Canteiro de Obras</div>
  <div class="servicos-texto">Selecione um dos serviços abaixo clicando sobre eles:</div>
  <div class="servicos-container">
    <div class="servicos-item centralizar">
      <div class="row">
        <span class="servicos-item__texto-maior col">INSCRIÇÃO</span>
      </div>
      <div class="row">
        <span class="servicos-item__texto col">Solicitação alteração de situação cadastral</span>
      </div>
    </div>
    <div class="servicos-item centralizar">
      <div class="row">
        <span class="servicos-item__texto-maior servicos-item__texto-grande col">ACOMPANHAMENTO</span>
      </div>
      <div class="row">
        <span class="servicos-item__texto col"></span>
      </div>
    </div>
    <div class="servicos-item centralizar">
      <div class="row">
        <span class="servicos-item__texto-maior servicos-item__texto-grande col">REGULARIZAÇÃO</span>
      </div>
      <div class="row">
        <span class="servicos-item__texto col"></span>
      </div>
    </div>
  </div>
</section>