import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CadastroComponent } from './cadastro/cadastro.component';
import { CadastroRoutingModule } from './cadastro.routing.module';
import { CadastroMenuComponent } from './cadastro-menu/cadastro-menu.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { DropdownModule } from 'primeng/dropdown';
import { NgxMaskModule } from 'ngx-mask';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CanteiroObrasComponent } from './canteiro-obras/canteiro-obras.component';
import { CanteiroObrasMenuComponent } from './canteiro-obras/canteiro-obras-menu/canteiro-obras-menu.component';
import { SubstitutoTributarioComponent } from './substituto-tributario/substituto-tributario.component';
import { SubstitutoTributarioMenuComponent } from './substituto-tributario/substituto-tributario-menu/substituto-tributario-menu.component';
import { InformacoesComplementaresComponent } from './informacoes-complementares/informacoes-complementares.component';
import { InformacoesComplementaresMenuComponent } from './informacoes-complementares/informacoes-complementares-menu/informacoes-complementares-menu.component';
import { CommonAppModule } from '../common/common.module';
import { CadastramentoProdutorRuralComponent } from './produtor-rural/inscricao/cadastramento/cadastramento.component';
import { InscricaoMenuComponent } from './produtor-rural/inscricao/inscricao-menu/inscricao-menu.component';
import { AcompanhamentoProdutorRuralComponent } from './produtor-rural/acompanhamento/acompanhamento.component';
import { ProdutorRuralMenuComponent } from './produtor-rural/produtor-rural-menu/produtor-rural-menu.component';
import { ProdutorRuralComponent } from './produtor-rural/produtor-rural.component';
import { SuspensaoProdutorRuralModule } from './produtor-rural/inscricao/suspensao/suspensao.module';
import { AlteracaoProdutorRuralModule } from './produtor-rural/inscricao/alteracao/alteracao.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DpDatePickerModule,
    DropdownModule,
    SuspensaoProdutorRuralModule,
    AlteracaoProdutorRuralModule,
    NgxMaskModule,
    CurrencyMaskModule,
    FontAwesomeModule,
    CommonAppModule,
    CadastroRoutingModule
  ],
  declarations: [
    CadastroComponent,
    CadastroMenuComponent,
    CadastramentoProdutorRuralComponent,
    CanteiroObrasComponent,
    CanteiroObrasMenuComponent,
    SubstitutoTributarioComponent,
    SubstitutoTributarioMenuComponent,
    InformacoesComplementaresComponent,
    InformacoesComplementaresMenuComponent,
    InscricaoMenuComponent,
    ProdutorRuralMenuComponent,
    ProdutorRuralComponent,
    AcompanhamentoProdutorRuralComponent
  ]
})
export class CadastroModule {

}