<div class="ml-5">
  <h3>Justificativa da Solicitação</h3>
  <div class="container">
    <div class="row">
      <div class="col-6">
        <div class="br-input">
          <label>Solicitação</label>
          <input type="text" disabled [(ngModel)]="solicitacao">
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col">
        <div class="br-textarea">
          <label>Motivo</label>
          <textarea class="textarea" rows="5" cols="88" placeholder="Digite aqui o motivo..." [(ngModel)]="motivo"></textarea>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col">
        <div class="br-upload">
          <label class="upload-label">
            Documentos
          </label>
          <input class="upload-input" type="file" multiple="multiple" #file_input (change)="getAnexo($event)" />
          <button class="upload-button" type="button" (click)="file_input.click()">
            <fa-icon [icon]="['fas', 'upload']">
            </fa-icon>
            <span>Selecione o arquivo</span>
          </button>
          <div class="ml-2 mt-2" *ngIf="arquivos.length == 0">
            Ainda não há arquivos.
          </div>
          <div class="upload-list mt-2" *ngFor="let item of arquivos; let i = index">{{ item.name }} 
            <fa-icon class="lixeira" [icon]="['fas', 'trash']" (click)="deletarArquivo(i)"></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="div-botao mt-4">
    <button class="br-button primary posicao-botao" type="button" [disabled]="motivo.length == 0" (click)="confirmarSolicitacao()">Confirmar Solicitação</button>
    <button class="br-button primary posicao-botao mr-4 desistir" type="button" (click)="desistir()">Desistir</button>
  </div>
</div>
<div class="carregamento" *ngIf="loading">
  <img src="../../../assets/images/icones/logo-animado.svg">
</div>
