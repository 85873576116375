import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ProdutorRuralAlterar } from "src/app/features/common/interface/produtorRuralAlterar";

import { ProdutorRuralService } from "src/app/services/produtor-rural.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-alteracao-produtor-etapa-5',
  templateUrl: './etapa-5.component.html',
  styleUrls: ['./etapa-5.component.scss']
})
export class Etapa5Component implements OnInit{
  @ViewChild('cep') cep: ElementRef;

  @Output() passarEtapa = new EventEmitter();

  @Output() sair = new EventEmitter();
  
  @Output() voltarEtapa = new EventEmitter();

  @Input() produtorRural: any;

  @Input() listaComplemento: any;

  @Input() dadosEditados: ProdutorRuralAlterar;

  form: FormGroup;
  loading: boolean = false;
  codigoTipoComplemento: number;

  constructor(
    private formBuilder: FormBuilder,
    private produtorRuralService: ProdutorRuralService
  ) {

  }

  ngOnInit(): void {
    this.criarFormulario();
  }

  criarFormulario() {
    for (let i = 0; i < this.listaComplemento.length; i++) {
      if (
        this.produtorRural.contribuinteEndereco.endereco.tipoComplemento.dscTipoComplemento
        == this.listaComplemento[i].dscTipoComplemento
      ) {
        this.codigoTipoComplemento = i;
        }
    }
    if (this.dadosEditados.passo > 5) {
      this.form = this.formBuilder.group({
        mesmoEndereco: this.dadosEditados.contribuinteEndereco.mesmoEndereco,
        cep: [this.dadosEditados.contribuinteEndereco.endereco.cep.codCep, Validators.required],
        logradouro: [{value: this.dadosEditados.contribuinteEndereco.endereco.cep.logradouro, disabled: true}, Validators.required],
        municipio: [{value: this.dadosEditados.contribuinteEndereco.endereco.cep.municipio, disabled: true}, Validators.required],
        estado: [{value: this.dadosEditados.contribuinteEndereco.endereco.cep.estado, disabled: true},Validators.required],
        numero: [this.dadosEditados.contribuinteEndereco.endereco.dscNumero, Validators.required],
        tipoComplemento: this.codigoTipoComplemento,
        complemento: this.dadosEditados.contribuinteEndereco.endereco.dscComplemento,
        localizacao: [{value: 2, disabled: true}, Validators.required],
        latitude: [this.dadosEditados.contribuinteEndereco.endereco.latitude, [Validators.min(-90), Validators.max(90)]],
        longitude: [this.dadosEditados.contribuinteEndereco.endereco.longitute, [Validators.min(-180), Validators.max(180)]]
      })
    } else {
      this.form = this.formBuilder.group({
        mesmoEndereco: false,
        cep: [this.produtorRural.contribuinteEndereco.endereco.cep.codCep, Validators.required],
        logradouro: [{value: this.produtorRural.contribuinteEndereco.endereco.cep.dscLogradouro, disabled: true}, Validators.required],
        municipio: [{value: this.produtorRural.contribuinteEndereco.endereco.cep.cidade.dscCidade, disabled: true}, Validators.required],
        estado: [{value: this.produtorRural.contribuinteEndereco.endereco.cep.cidade.estado.dscUf, disabled: true},Validators.required],
        numero: [this.produtorRural.contribuinteEndereco.endereco.dscNumero, Validators.required],
        tipoComplemento: this.codigoTipoComplemento,
        complemento: this.produtorRural.contribuinteEndereco.endereco.dscComplemento,
        localizacao: [{value: 2, disabled: true}, Validators.required],
        latitude: [this.produtorRural.contribuinteEndereco.endereco.latitude, [Validators.min(-90), Validators.max(90)]],
        longitude: [this.produtorRural.contribuinteEndereco.endereco.longitute, [Validators.min(-180), Validators.max(180)]]
      })
    }
    if (this.dadosEditados.contribuinteEndereco.mesmoEndereco) {
      this.form.controls.cep.disable();
      this.form.controls.logradouro.disable();
      this.form.controls.municipio.disable();
      this.form.controls.estado.disable();
      this.form.controls.numero.disable();
      this.form.controls.tipoComplemento.disable();
      this.form.controls.complemento.disable();
      this.form.controls.localizacao.disable();
      this.form.controls.latitude.disable();
      this.form.controls.longitude.disable();
    }
  }

  copiarFormularios() {
    if (this.form.controls.mesmoEndereco.value == true) {
      let tipoComplemento;
      for (let i = 0; i < this.listaComplemento.length; i++) {
        if (this.listaComplemento[i].codigo == this.dadosEditados.imovelDTO.endereco.tipoComplemento.codigo) {
          tipoComplemento = i;
        }
      }
      this.form.controls.cep.setValue(this.dadosEditados.imovelDTO.endereco.cep.codCep);
      this.form.controls.logradouro.setValue(this.dadosEditados.imovelDTO.endereco.cep.logradouro);
      this.form.controls.municipio.setValue(this.dadosEditados.imovelDTO.endereco.cep.municipio);
      this.form.controls.estado.setValue(this.dadosEditados.imovelDTO.endereco.cep.estado);
      this.form.controls.numero.setValue(this.dadosEditados.imovelDTO.endereco.dscNumero);
      this.form.controls.tipoComplemento.setValue(tipoComplemento);
      this.form.controls.complemento.setValue(this.dadosEditados.imovelDTO.endereco.dscComplemento);
      this.form.controls.localizacao.setValue(this.dadosEditados.imovelDTO.codNaturezaImovel);
      this.form.controls.latitude.setValue(this.dadosEditados.imovelDTO.endereco.latitude);
      this.form.controls.longitude.setValue(this.dadosEditados.imovelDTO.endereco.longitute);

      this.form.controls.cep.disable();
      this.form.controls.logradouro.disable();
      this.form.controls.municipio.disable();
      this.form.controls.estado.disable();
      this.form.controls.numero.disable();
      this.form.controls.tipoComplemento.disable();
      this.form.controls.complemento.disable();
      this.form.controls.localizacao.disable();
      this.form.controls.latitude.disable();
      this.form.controls.longitude.disable();
    } else {
      this.form.reset();
      this.form.controls.localizacao.setValue('2');
      this.form.controls.cep.enable();
      this.form.controls.logradouro.enable();
      this.form.controls.municipio.enable();
      this.form.controls.estado.enable();
      this.form.controls.numero.enable();
      this.form.controls.tipoComplemento.enable();
      this.form.controls.complemento.enable();
      this.form.controls.localizacao.enable();
      this.form.controls.latitude.enable();
      this.form.controls.longitude.enable();
    }
  }

  validarCep(event) {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    if (/^\d{2}[.-]?\d{3}[.-]?\d{3}$/.test(inputValue)) {
      this.verificarCep()
    } else {
      this.apagarEndereco();
    }
  }

  verificarCep() {
    this.loading = true;
    this.produtorRuralService.consultarCep(this.form.controls.cep.value).subscribe(
      res => {
        this.form.controls.logradouro.setValue(res['dscLogradouro']);
        this.form.controls.municipio.setValue(res['cidade']['dscCidade']);
        this.form.controls.estado.setValue(res['cidade']['estado']['codigo']);
        this.form.controls.logradouro.disable();
        this.form.controls.municipio.disable();
        this.form.controls.estado.disable();
      },
      err => {
        Swal.fire({
          icon: 'error',
          text: 'Atenção: C.E.P não encontrado/válido. Informar um próximo ou genérico da cidade.'
        }).then((result) => {
          if (result.isConfirmed) {
            this.form.controls.cep.reset();
            this.cep.nativeElement.focus();
            this.apagarEndereco();
          }
        })
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    )
  }

  apagarEndereco() {
    this.form.controls.logradouro.reset();
    this.form.controls.municipio.reset();
    this.form.controls.estado.reset();
    this.form.controls.logradouro.enable();
    this.form.controls.municipio.enable();
    this.form.controls.estado.enable();
  }

  validarTipoComplemento() {
    if (this.form.controls.tipoComplemento.value) {
      this.form.controls.complemento.setValidators(Validators.required);
      this.form.controls.complemento.updateValueAndValidity();
    }
  }

  proximoPasso() {
    this.dadosEditados.contribuinteEndereco.endereco.cep.codCep = this.form.controls.cep.value;
    this.dadosEditados.contribuinteEndereco.endereco.cep.logradouro = this.form.controls.logradouro.value;
    this.dadosEditados.contribuinteEndereco.endereco.cep.municipio = this.form.controls.municipio.value;
    this.dadosEditados.contribuinteEndereco.endereco.cep.estado = this.form.controls.estado.value;
    this.dadosEditados.contribuinteEndereco.endereco.dscNumero = this.form.controls.numero.value;
    this.dadosEditados.contribuinteEndereco.endereco.dscComplemento = this.form.controls.complemento.value;
    this.form.controls.tipoComplemento.value
      ? this.dadosEditados.contribuinteEndereco.endereco.tipoComplemento.codigo = this.listaComplemento[this.form.controls.tipoComplemento.value].codigo
      : '';
    this.dadosEditados.contribuinteEndereco.endereco.latitude = this.form.controls.latitude.value;
    this.dadosEditados.contribuinteEndereco.endereco.longitute = this.form.controls.longitude.value;
    this.dadosEditados.contribuinteEndereco.endereco.naturezaImovel.codigo = this.form.controls.localizacao.value;
    this.dadosEditados.contribuinteEndereco.codigo = this.produtorRural.contribuinteEndereco.codigo;
    this.dadosEditados.contribuinteEndereco.dtInicio = this.produtorRural.contribuinteEndereco.dtInicio;
    this.dadosEditados.contribuinteEndereco.endereco.pais.codigo = this.produtorRural.contribuinteEndereco.endereco.pais.codigo;
    this.dadosEditados.contribuinteEndereco.seqContribuinte = this.produtorRural.contribuinteEndereco.seqContribuinte;
    this.dadosEditados.contribuinteEndereco.mesmoEndereco = this.form.controls.mesmoEndereco.value;
    this.dadosEditados.passo = 6;
    this.passarEtapa.emit();
  }

  voltarPasso() {
    this.voltarEtapa.emit();
  }

  desistir() {
    this.sair.emit();
  }

}