import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CadastroProdutorRural } from "src/app/features/common/interface/cadastroProdutorRural";
import { ProdutorRuralService } from "src/app/services/produtor-rural.service";
import { UsuarioLogadoService } from "src/app/services/usuarioLogado.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-suspensao-produtor-rural',
  templateUrl: './suspensao.component.html',
  styleUrls: ['./suspensao.component.scss']
})
export class SuspensaoProdutorRuralComponent implements OnInit {

  active1: boolean = true;
  active2: boolean = false;
  active3: boolean = false;
  active4: boolean = false;
  dadosIntegracao: any;
  usuarioLogado: any;
  dadosProdutorRural: any;
  objetoProdutor: CadastroProdutorRural;
  bufferObj: any = {};
  titulo: string;
  loading: boolean = false;

  constructor(
    private router: Router,
    private produtorRuralService: ProdutorRuralService,
    private usuarioLogadoService: UsuarioLogadoService,
    private route: ActivatedRoute
  ) {

  }

  ngOnInit(): void {
    let route = this.route.snapshot.routeConfig.path;
    switch (route) {
      case 'reativacao':
        this.titulo = 'Solicitação de Reativação'
        break;
      case 'suspensao':
        this.titulo = 'Solicitação de Suspensão'
        break;
      case 'baixa':
        this.titulo = 'Solicitação de Baixa da I.E.'
        break;
      default:
        return
    }
    setTimeout(() => {
      this.dadosIntegracao = this.usuarioLogadoService.getDadosIntegracao();
      this.usuarioLogado = this.usuarioLogadoService.getUsuarioLogado();
    }, 500);
  }

  pegarCodigoContribuinte(event) {
    this.loading = true;
    this.bufferObj.codigoContribuinte = event;
    this.produtorRuralService.getProdutorRural(event).subscribe(
      res => {
        this.dadosProdutorRural = res;
      },
      err => {
        this.loading = false;
        Swal.fire({
          icon: 'error',
          text: 'Ops! Ocorreu um erro ao pegar os dados do Produtor Rural.'
        })
      },
      () => {
        this.loading = false;
        this.active2 = false;
        this.active3 = true;
      }
    )
  }

  getDadosEtapa1(event) {
    this.bufferObj.cpf = event.cpfSolicitante;
    this.bufferObj.solicitante = event.solicitante;
    this.bufferObj.motivo = event.dscMotivo;
    this.bufferObj.codMotivo = event.motivo;
    this.bufferObj.indexListaBic = event.indexListaBic;
    this.active1 = false;
    this.active2 = true;
  }

  passarEtapa(etapa) {
    if (etapa == 3) {
      this.active3 = false;
      this.active4 = true;
    }
  }

  voltarEtapa(etapa) {
    if (etapa == 2) {
      this.active1 = true;
      this.active2 = false;
    } else if (etapa == 3) {
      this.active2 = true;
      this.active3 = false;
    }
  }

  desistir() {
    Swal.fire({
      icon: 'question',
      text: 'Atenção! Desistir neste momento implica em descartar todos os dados digitados. Confirma?',
      confirmButtonText: 'Sim',
      showCancelButton: true,
      cancelButtonText: 'Não'
    }).then(result => {
      if (result.isConfirmed) {
        this.router.navigate(['cadastro/produtor-rural/inscricao']);
      } else if (result.isDenied) {
        Swal.close();
      }
    })
  }

  
}