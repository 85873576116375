<div class="container">
  <h1>Dúvidas Frequentes</h1>
</div>
<div class="modoEscuro-duvidas br-accordion tamanho" single="single">
  <div>
    <button class="header" type="button"><span class="icon"><fa-icon [icon]="['fas', 'angle-down']"></fa-icon></span><span class="title">
      O que é portal Domicílio Eletrônico do Contribuinte?
    </span></button>
    <div>É um portal que reúne, em um só lugar, serviços para o cidadão ofertados pela Secretaria de Fazenda do Estado de Tocantins.</div>
  </div>
</div>
<div class="modoEscuro-duvidas br-accordion tamanho" single="single">
  <div>
    <button class="header" type="button"><span class="icon"><fa-icon [icon]="['fas', 'angle-down']"></fa-icon></span><span class="title">
      Qual o benefício para o cidadão?
    </span></button>
    <div>Com o portal, o cidadão vai economizar tempo e evitar deslocamentos desnecessários.</div>
  </div>
</div>
<div class="modoEscuro-duvidas br-accordion tamanho" single="single">
  <div>
    <button class="header" type="button"><span class="icon"><fa-icon [icon]="['fas', 'angle-down']"></fa-icon></span><span class="title">
      Como acesso o Domicílio Eletrônico do Contribuinte?
    </span></button>
    <div>O acesso é utilizando sua conta de acesso Gov.br.
      <a href="https://sfera-ged.sefaz.to.gov.br/share/s/4bgfmoCPSzSjIy0ohClK4g" target="_blank">Assista ao vídeo explicativo. </a>
    </div>
  </div>
</div>
<div class="modoEscuro-duvidas br-accordion tamanho" single="single">
  <div>
    <button class="header" type="button"><span class="icon"><fa-icon [icon]="['fas', 'angle-down']"></fa-icon></span><span class="title">
      Sócio pode acessar com seu próprio acesso do Gov.Br?
    </span></button>
    <div>Sim. Acesse ao video explicativo.
      <a href="https://sfera-ged.sefaz.to.gov.br/share/s/xKwnA4qoSzqXE88jE6D_vw" target="_blank">Assista ao vídeo explicativo. </a>
    </div>
  </div>
</div>
<div class="modoEscuro-duvidas br-accordion tamanho ultimo-texto" single="single">
  <div>
    <button class="header" type="button"><span class="icon"><fa-icon [icon]="['fas', 'angle-down']"></fa-icon></span><span class="title">
      O que é o REFIS?
    </span></button>
    <div>É um programa de Recuperação Fiscal que tem o objetivo de facilitar a regularização e renegociaçào de dívidas tributárias ou não tributárias de pessoas jurídicas
      ou físicas com o Estado. A negociação, em suas diversas modalidades, oferece descontos para pagamento à vista, além da possibilidade de parcelamento.<br>Assista
      ao vídeo explicativo de <a href="https://sfera-ged.sefaz.to.gov.br/share/s/ecNuIl7tQz6I4TDb7p-Gaw" target="_blank">como requerer participação ao REFIS.</a>
    </div>
  </div>
</div>
