import { nfaService } from '../../services/nfa.service';
import { Component, OnInit } from "@angular/core";
import { ModoEscuroService } from 'src/app/services/modoEscuro.service';

@Component({
  selector: 'app-modal-nfa',
  templateUrl: './modal-nfa.component.html',
  styleUrls: ['./modal-nfa.component.scss']
})

export class ModalNfaComponent implements OnInit{

  atBottom: boolean = false;
  modalAtivo: boolean = false;
  abrirModalNfa: boolean = false;
  abrirModalEmitirNfa: boolean = false;
  modoEscuro: boolean = false;

  constructor(
    private nfaService: nfaService, private modoEscuroService: ModoEscuroService) {
    this.nfaService.modalNfa.subscribe(res => {
      if(res) {
        this.abrirFecharModalNfae();
      }
    });
  }

  ngOnInit(): void {
    this.modoEscuroService.modoEscuro$.subscribe(modo => {
      this.modoEscuro = modo;
    });
  }

  abrirModal(){
    if (this.modalAtivo == true) {
      this.modalAtivo = false;
    } else {
      this.modalAtivo = true;
    }
  }


  redirecionarNfe() {
    window.open('https://blue-glacier-052eaad10.4.azurestaticapps.net/#/', '_blank');
  }

  abrirFecharModalNfae() {
    this.abrirModalEmitirNfa = false;
    this.abrirModalNfa = !this.abrirModalNfa;
  }

  abrirEmitir() {
    this.abrirModalNfa = false;
    this.abrirModalEmitirNfa = !this.abrirModalEmitirNfa;
  }

  abrirSolicitacaoNFA() {
    const contribuinteEmUso = localStorage.getItem('contribuinteEmUso');
    const contribuinteEmUsoFormatado = JSON.parse(contribuinteEmUso);
    const obj = {
      url: 'solicitar-autorizacao',
      contribuinte: {
        cpfCnpj: contribuinteEmUsoFormatado.cadastro.cpfCnpj,
        dscIe: contribuinteEmUsoFormatado.dscIe,
        situacaoIe: contribuinteEmUsoFormatado.contribuinteSituacao?.tipoSituacaoIe?.dscSituacaoIE,
        dscRazaoSocial: contribuinteEmUsoFormatado.dscRazaoSocial
      }
    }

    const objString = btoa(JSON.stringify(obj));
    window.open(`https://blue-glacier-052eaad10.4.azurestaticapps.net/#/nfa/${objString}`, '_blank');
   
  }

  scrollEnd(evt: any) {
    if(evt.target.offsetHeight + evt.target.scrollTop >= evt.target.scrollHeight - 50) {
      this.atBottom = true;
    } else {
      this.atBottom = false;
    }
  }
}
