import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "src/environments/environment";
import { ResponseApi } from "../features/common/interface/responseApi";
import { UsuarioLogadoService } from "./usuarioLogado.service";

@Injectable({
  providedIn:  'root'
})
export class TermoAdesaoService {

  apiUrl = environment.API_URL;
  dadosUsuario: any;
  nomeUsuario: any;
  cpfCnpjUsuario : any;
  codigoCadastro : any;


  constructor(
    private http: HttpClient,
    private usuarioLogadoService: UsuarioLogadoService
  ) {

  }

  necessidadeAdesao(): Observable<ResponseApi> {
    return this.http.get<ResponseApi>(`${this.apiUrl}/dfe/verificarNecessidadeAdesao`);
  }

  verificarCadastro(codigoCadastro : number): Observable<ResponseApi> {
    return this.http.get<ResponseApi>(`${this.apiUrl}/dfe/verificarCadastro/${codigoCadastro}`);
  }

  getTermoAdesao(): Observable<ResponseApi> {
    return this.http.get<ResponseApi>(`${this.apiUrl}/dfe/termoAdesao/1`)
  }

  adesaoTermo(aceitacao : boolean) {
    this.dadosUsuario = this.usuarioLogadoService.getUsuarioLogado();
    this.nomeUsuario = this.dadosUsuario.dscRazaoSocial;
    this.cpfCnpjUsuario = this.dadosUsuario.cpfCnpj;
    this.codigoCadastro = this.dadosUsuario.codigoCadastro;

    const domicilioAdesao = {
      aceiteTermoAdesao: aceitacao,
      nome:  this.nomeUsuario,
      cpfCnpj: this.cpfCnpjUsuario,
      codigoCadastro: this.codigoCadastro
    }

    return this.http.post<any>(`${this.apiUrl}/dfe/`, domicilioAdesao);
  }
}
