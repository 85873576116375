<div class="ml-5">
  <h3>Informações Econômicas e Fiscais</h3>
  <form [formGroup]="form">
    <div class="row">
      <div class="col-4">
        <div class="br-input">
          <label>Classificação do Contribuinte<span class="obrigatorio label">*</span></label>
          <input type="text" class="input" disabled />
          <select class="select" formControlName="classificacaoContribuinte">
            <option value="" selected disabled>Selecione a Classificação do Contribuinte...</option>
            <option *ngFor="let item of listaClassificacao" [ngValue]="item.codigo">{{ item.dscClassificacao }}</option>
          </select>
        </div>
        <p 
          class="mensagem-erro" 
          *ngIf="form.controls.classificacaoContribuinte.invalid && form.controls.classificacaoContribuinte.touched"
        >
          Campo obrigatório
        </p>
      </div>
      <div class="col-4">
        <div class="br-input">
          <label>Protocolo Registro na Junta Comercial<span class="igualar-altura label">*</span></label>
          <input type="text" formControlName="protocoloRegistro" maxlength="20">
        </div>
      </div>
      <div class="col-4">
        <div class="br-input">
          <label>Data do Registro na Junta Comercial<span class="igualar-altura label">*</span></label>
        </div>
        <dp-date-picker 
            theme="dp-material"
            [config]="datePickerConfig"
            formControlName="dataRegistro"
            [(ngModel)]="dataRegistro"
          >
        </dp-date-picker>
        <p 
          class="mensagem-erro" 
          *ngIf="form.controls.dataRegistro.invalid"
        >
          Campo obrigatório
        </p>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-4">
        <div class="br-input">
          <label>Capital Social<span class="obrigatorio label">*</span></label>
          <input type="text" formControlName="capitalSocial" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }">
        </div>
        <p 
          class="mensagem-erro" 
          *ngIf="form.controls.capitalSocial.invalid && form.controls.capitalSocial.touched"
        >
          Campo obrigatório
        </p>
      </div>
      <div class="col-4">
        <div class="br-input">
          <label>Regime de Recolhimento<span class="igualar-altura label">*</span></label>
          <input type="text" class="input" disabled />
          <select class="select" formControlName="regimeRecolhimento">
            <option value="" selected disabled>Selecione o Regime de Recolhimento...</option>
            <option *ngFor="let item of listaRegimeRecolhimento" [ngValue]="item.id">{{ item.descricaoRegimeRecolhimento }}</option>
          </select>
        </div>
        <p 
          class="mensagem-erro" 
          *ngIf="form.controls.regimeRecolhimento.invalid && form.controls.regimeRecolhimento.touched"
        >
          Campo obrigatório
        </p>
      </div>
      <div class="col-4">
        <div class="br-input">
          <label>Natureza Jurídica<span class="obrigatorio label">*</span></label>
          <input type="text" class="input" disabled />
          <select class="select" formControlName="naturezaJuridica">
            <option value="" selected disabled>Selecione a Natureza Jurídica...</option>
            <option *ngFor="let item of listaNaturezaJuridica" [ngValue]="item.id">{{ item.dscNaturezaJuridica }}</option>
          </select>
        </div>
        <p 
          class="mensagem-erro" 
          *ngIf="form.controls.naturezaJuridica.invalid && form.controls.naturezaJuridica.touched"
        >
          Campo obrigatório
        </p>
      </div>
    </div>
    <h5>Cnae<span class="obrigatorio label">*</span></h5>
    <button class="br-button primary mb-2" type="button" (click)="abrirModal()">Adicionar</button>
    <div class="br-table">
      <table>
        <thead>
          <tr>
            <th scope="col">CNAE</th>
            <th scope="col">Primária</th>
            <th scope="col">Exercido no endereço do estabelecimento</th>
            <th scope="col">Deletar</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="cnae.length > 0; else semDados"></ng-container>
          <tr *ngFor="let item of cnae, let i = index">
            <td>{{ item.cnae.dscCnae }}</td>
            <td>{{ item.numPrincipal == 0 ? 'Não' : 'Sim' }}</td>
            <td>{{ item.numExerceNoEnd == 0 ? 'Não' : 'Sim' }}</td>
            <td><img class="deletar" src="../../../../assets/images/icones/trash-solid.svg" alt="Lixeira" (click)="deletarCnae(i)"></td>
          </tr>
          <ng-template #semDados>
            <tr>
              <td colspan="7" align="center">Sem dados para serem exibidos.</td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>
    <div class="div-botao mt-4">
      <button class="br-button primary posicao-botao" type="button" (click)="proximoPasso()" [disabled]="!form.valid || cnae.length == 0">Próxima Etapa</button>
      <button class="br-button secondary posicao-botao mr-4" type="button" (click)="voltarPasso()">Voltar Etapa</button>
      <button class="br-button primary posicao-botao mr-4 desistir" type="button" (click)="desistir()">Desistir</button>
    </div>
  </form>
</div>

<div class="backdrop" *ngIf="cnaeAberto">
  <div class="div br-modal x-large position-modal">
    <div class="br-modal-header">CNAE - {{ this.nomeCnae }}</div>
    <div class="br-modal-body overflow">
      <form [formGroup]="cnaeForm">
        <div class="row">
          <div class="col-10">
            <div class="br-input">
              <label>CNAE<span class="obrigatorio label">*</span></label>
              <input type="text" class="input" disabled />
              <select class="select" formControlName="cnae" (change)="getNomeCnae()">
                <option value="" selected disabled>Selecione o CNAE...</option>
                <option *ngFor="let item of listaCnae; index as i" [ngValue]="i">{{ item.dscCnae }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="mt-3 ml-4">
          <label class="mb-3">Essa é a atividade primária?<span class="obrigatorio label">*</span></label>
          <div class="br-radio">
            <input id="sim" type="radio" value="1" formControlName="atividadePrincipal">
            <label class="mr-5" for="sim">Sim</label>
            <input id="nao" type="radio" value="0" formControlName="atividadePrincipal">
            <label for="nao">Não</label>
          </div>
        </div>
        <div class="mt-3 ml-4">
          <label class="mb-3">Essa atividade é exercida no endereço do estabelecimento?<span class="obrigatorio label">*</span></label>
          <div class="br-radio">
            <input id="atividadeSim" type="radio" value="1" formControlName="exercidoEndereco">
            <label class="mr-5" for="atividadeSim">Sim</label>
            <input id="atividadeNao" type="radio" value="0" formControlName="exercidoEndereco">
            <label for="atividadeNao">Não</label>
          </div>
        </div>
      </form>
    </div>
    <div class="br-modal-footer justify-content-center">
      <button class="br-button primary desistir mr-4" type="button" (click)="fecharModal()">Desistir</button>
      <button class="br-button primary" type="button" (click)="adicionarCnae()" [disabled]="!cnaeForm.valid">Adicionar</button>
    </div>
  </div>
</div>