<div class="ml-5">
  <h3>Contabilista Responsável</h3>
  <form [formGroup]="form">
    <div class="row">
      <div class="col-4">
        <div class="br-input">
          <label>C.P.F.</label>
          <input 
            type="text"
            formControlName="cpf"
            mask="CPF_CNPJ" maxlength="14"
            (blur)="verificarExistenciaCpf(form.controls.cpf.value); validarDigitoCpf()">
        </div>
        <p 
          class="mensagem-erro" 
          *ngIf="form.controls.cpf.invalid && form.controls.cpf.touched"
        >
          Campo obrigatório / C.P.F Inválido
        </p>
      </div>
      <div class="col-8">
        <div class="br-input">
          <label>Nome</label>
          <input type="text" formControlName="nome" maxlength="200">
        </div>
        <p 
          class="mensagem-erro" 
          *ngIf="form.controls.nome.invalid && form.controls.nome.touched"
        >
          Campo obrigatório / Mínimo 10 dígitos
        </p>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-4">
        <div class="br-input">
          <label>C.R.C</label>
          <input type="text" formControlName="crc" maxlength="20" (blur)="consultarCrc(form.controls.crc.value)">
        </div>
        <p 
          class="mensagem-erro" 
          *ngIf="form.controls.crc.invalid && form.controls.crc.touched"
        >
          Campo obrigatório
        </p>
      </div>
      <div class="col-8">
        <div class="br-input">
          <label>E-mail</label>
          <input type="text" formControlName="email" maxlength="100" email>
        </div>
        <p 
          class="mensagem-erro" 
          *ngIf="form.controls.email.invalid && form.controls.email.touched"
        >
          Campo obrigatório / E-mail em formato incorreto
        </p>
      </div>
    </div>
    <div class="div-botao mt-4">
      <button class="br-button primary posicao-botao" type="button" (click)="proximoPasso()" [disabled]="!form.valid">Próxima Etapa</button>
      <button class="br-button secondary posicao-botao mr-4" type="button" (click)="voltarPasso()">Voltar Etapa</button>
      <button class="br-button primary posicao-botao mr-4 desistir" type="button" (click)="desistir()">Desistir</button>
    </div>
  </form>
</div>