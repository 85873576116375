import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ContribuinteService } from "src/app/services/contribuinte.service";

@Component({
  selector: 'app-suspensao-produtor-etapa-1',
  templateUrl: './etapa-1.component.html',
  styleUrls: ['./etapa-1.component.scss']
})
export class Etapa1Component implements OnInit {
  @Output() passarEtapa = new EventEmitter();

  @Output() sair = new EventEmitter();

  @Input() contribuinte: any;

  @Input() buffer: any;

  motivo: number = null;
  listaBic: any;
  cpfSolicitante: string;
  solicitante: string;
  titulo: string;

  constructor(
    private contribuinteService: ContribuinteService,
    private route: ActivatedRoute
  ) {

  }

  ngOnInit(): void {    
    let route = this.route.snapshot.routeConfig.path;
    switch (route) {
      case 'reativacao':
        this.titulo = 'Solicitação de Reativação'
        this.motivo = 0;
        break;
      case 'suspensao':
        this.titulo = 'Solicitação de Suspensão'
        this.motivo = 1;
        break;
      case 'baixa':
        this.titulo = 'Solicitação de Baixa da I.E.'
        this.motivo = 2;
        break;
      default:
        return
    }
    setTimeout(() => {
      this.cpfSolicitante = this.contribuinte.cpfCnpj;
      this.solicitante = this.contribuinte.dscRazaoSocial;
    }, 500);
    this.contribuinteService.getDadosListaBic().subscribe(
      res => {
        this.listaBic = res;
        this.listaBic.splice(0, 2);
      }
    )
  }

  enviarDados() {
    let obj = {
      indexListaBic: this.motivo,
      motivo: this.listaBic[this.motivo].id,
      dscMotivo: this.listaBic[this.motivo].descricao,
      cpfSolicitante: this.cpfSolicitante,
      solicitante: this.solicitante
    }
    this.passarEtapa.emit(obj);
  }

  desistir() {
    this.sair.emit();
  }
}