<div class="header-menu-trigger">
  <button
    class="br-button small circle sidemenu-btn" *ngIf="sidemenu === 'false' && logged"
    type="button"
    aria-label="Menu"
    data-toggle="menu"
    data-target="#main-navigation"
    id="navigation"
    (click)="abrirSidemenu()"
  >
    <fa-icon [icon]="['fas', 'bars']" aria-hidden="true"></fa-icon>
  </button>
</div>
<div class="sidemenu" *ngIf="sidemenu === 'true'">
  <button class="closebtn" (click)="fecharSidemenu()" tabindex = 1>
    <img src="../../../../assets/images/imagens/close_button.svg" alt="" />
  </button>
  <a
    [routerLink]="nivelUsuario !== 'bronze' ? ['/caixa-postal'] : []"
    title="{{ nivelUsuario !== 'bronze' ? 'Caixa Postal' : 'Este recurso está disponível somente para os níveis Prata e Ouro do acesso Gov.br' }}"
    [class.disabled]="nivelUsuario == 'bronze'"
    *ngIf="exibirMenu('CAIXA POSTAL ELETRÔNICA') && !cadastroEmUso" tabindex = 2
  >Caixa Postal Eletrônica</a>
  <a
    [routerLink]="nivelUsuario !== 'bronze' ? ['/procuracao'] : []"
    title="{{ nivelUsuario !== 'bronze' ? 'Procuração Eletrônica' : 'Este recurso está disponível somente para os níveis Prata e Ouro do acesso Gov.br' }}"
    [class.disabled]="nivelUsuario == 'bronze'"
    *ngIf="exibirMenu('Procuração Eletrônica') && !cadastroEmUso" tabindex = 3
  >Procuração Eletrônica</a>
  <a
    [routerLink]="nivelUsuario !== 'bronze' ? ['/refis'] : []"
    title="{{ nivelUsuario !== 'bronze' ? 'REFIS' : 'Este recurso está disponível somente para os níveis Prata e Ouro do acesso Gov.br' }}"
    [class.disabled]="nivelUsuario == 'bronze'"
    *ngIf="exibirMenu('REFIS') && !cadastroEmUso" tabindex = 4
  >REFIS</a>
  <a
    class="linkPortal"
    title="Portal de Negociação de Débitos"
    (click)="abrirModal()"
    *ngIf="exibirMenu('Portal de Negociação de Débitos') && !cadastroEmUso" tabindex = 5
  >Portal de Negociação de Débitos</a>
  <a
    class="linkPortal"
    title="NFA-e"
    (click)="abrirModalNfa()" tabindex = 6
    *ngIf="exibirMenu('NFA-e') && (dadosFormatados.sessao.cadastro.codigo == 68430 || dadosFormatados.sessao.cadastro.codigo == 1835445)"
  >NFA-e</a>
  <a
    class="linkPortal"
    title="Portal de Acompanhamento de Parcelamento / Acordos"
    (click)="abrirPortalAcompanhamento()"
    style="font-size: 16px;"
    *ngIf="exibirMenu('Portal de Acompanhamento de Parcelamento / Acordos') && !cadastroEmUso" tabindex = 7
  >Portal de Acompanhamento de Parcelamento / Acordos</a>
  <a
    [routerLink]="nivelUsuario !== 'bronze' ? ['/cadastro'] : []"
    [class.disabled]="nivelUsuario == 'bronze'"
    title="{{ nivelUsuario !== 'bronze' 
      ? 'Cadastro de Contribuintes do I.C.M.S'
      : 'Este recurso está disponível somente para os níveis Prata e Ouro do acesso Gov.br ' }}"
    *ngIf="exibirMenu('Cadastro de Contribuintes do I.C.M.S') && dadosFormatados.sessao.cadastro.codigo == 68430 || dadosFormatados.sessao.cadastro.codigo == 1835445" tabindex = 8
  >Cadastro de Contribuintes do I.C.M.S</a>
  <a
    class="linkPortal"
    title="IPVA"
    (click)="abrirRefisIpva()" tabindex = 9
    *ngIf="exibirMenu('IPVA')"
  >IPVA</a>
  <a
    [routerLink]="['/perfil-contribuinte']"
    title="Perfil do Contribuinte"
    *ngIf="exibirMenu('Perfil do Contribuinte') && !cadastroEmUso" tabindex = 10
  >Perfil do Contribuinte</a>

  <!-- <a href="#">Produtor Rural</a>
  <a href="#">Construção Civil</a>
  <a href="#">Procuração</a> -->
  <!-- <a href="#">Histórico de Eventos</a> -->
  <a href="#" (click)="logout()" tabindex = 11>Sair</a>
    
</div>

  <!-- Modal Negociação de Débito -->
<div class="backdrop" *ngIf="modalAtivo">
  <div class="div br-modal medium position-modal">
    <div class="br-modal-header">Informativo</div>
    <div class="br-modal-body">
      <p>O Portal de Negociação apresenta dados e dívidas ativas lançadas até a data da pesquisa, não devendo presumir
        que é uma Certidão Negativa de Débito.</p>
    </div>
    <div class="br-modal-footer justify-content-end">
      <button class="br-button primary ml-2" type="button" (click)="abrirNegociacaoDebito()">OK</button>
    </div>
  </div>
</div>
<div class="carregamento" *ngIf="loading">
  <img src="../../../assets/images/icones/logo-animado.svg">
</div>