import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ProdutorRuralAlterar } from "src/app/features/common/interface/produtorRuralAlterar";

import { ProdutorRuralService } from "src/app/services/produtor-rural.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-alteracao-produtor-etapa-4',
  templateUrl: './etapa-4.component.html',
  styleUrls: ['./etapa-4.component.scss']
})
export class Etapa4Component implements OnInit{
  @ViewChild('cep') cep: ElementRef;

  @Output() passarEtapa = new EventEmitter();

  @Output() sair = new EventEmitter();

  @Output() voltarEtapa = new EventEmitter();

  @Input() produtorRural: any;

  @Input() listaComplemento: any;

  @Input() dadosEditados: ProdutorRuralAlterar;

  form: FormGroup;
  loading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private produtorRuralService: ProdutorRuralService
  ) {

  }

  ngOnInit(): void {
    this.criarFormulario(); 
  }

  criarFormulario() {
    if (this.dadosEditados.passo > 4) {
      this.form = this.formBuilder.group({
        cep: [this.dadosEditados.imovelDTO.endereco.cep.codCep, Validators.required],
        logradouro: [{value: this.dadosEditados.imovelDTO.endereco.cep.logradouro, disabled: true}, Validators.required],
        municipio: [{value: this.dadosEditados.imovelDTO.endereco.cep.municipio, disabled: true}, Validators.required],
        estado: [{value: this.dadosEditados.imovelDTO.endereco.cep.estado, disabled: true}, Validators.required],
        numero: [this.dadosEditados.imovelDTO.endereco.dscNumero, Validators.required],
        tipoComplemento: {value: 35, disabled: true},
        complemento: this.dadosEditados.imovelDTO.endereco.dscComplemento,
        localizacao: [{value: 2, disabled: true}, Validators.required],
        latitude: [this.dadosEditados.imovelDTO.endereco.latitude, [Validators.min(-90), Validators.max(90)]],
        longitude: [this.dadosEditados.imovelDTO.endereco.longitute, [Validators.min(-180), Validators.max(180)]] 
      })
    } else {
      this.form = this.formBuilder.group({
        cep: [this.produtorRural.imovelDTO.endereco.cep.codCep, Validators.required],
        logradouro: [{value: this.produtorRural.imovelDTO.endereco.cep.dscLogradouro, disabled: true}, Validators.required],
        municipio: [{value: this.produtorRural.imovelDTO.endereco.cep.cidade.dscCidade, disabled: true}, Validators.required],
        estado: [{value: this.produtorRural.imovelDTO.endereco.cep.cidade.estado.dscUf, disabled: true}, Validators.required],
        numero: [this.produtorRural.imovelDTO.endereco.dscNumero, Validators.required],
        tipoComplemento: {value: 35, disabled: true},
        complemento: this.produtorRural.imovelDTO.endereco.dscComplemento,
        localizacao: [{value: 2, disabled: true}, Validators.required],
        latitude: [this.produtorRural.imovelDTO.endereco.latitude, [Validators.min(-90), Validators.max(90)]],
        longitude: [this.produtorRural.imovelDTO.endereco.longitute, [Validators.min(-180), Validators.max(180)]] 
      })
    }
  }

  validarCep(event) {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    if (/^\d{2}[.-]?\d{3}[.-]?\d{3}$/.test(inputValue)) {
      this.verificarCep()
    } else {
      this.apagarEndereco();
    }
  }

  verificarCep() {
    this.loading = true;
    this.produtorRuralService.consultarCep(this.form.controls.cep.value).subscribe(
      res => {
        this.form.controls.logradouro.setValue(res['dscLogradouro']);
        this.form.controls.municipio.setValue(res['cidade']['dscCidade']);
        this.form.controls.estado.setValue(res['cidade']['estado']['codigo']);
        this.form.controls.logradouro.disable();
        this.form.controls.municipio.disable();
        this.form.controls.estado.disable();
      },
      err => {
        Swal.fire({
          icon: 'error',
          text: 'Atenção: C.E.P não encontrado/válido. Informar um próximo ou genérico da cidade.'
        }).then((result) => {
          if (result.isConfirmed) {
            this.form.controls.cep.reset();
            this.cep.nativeElement.focus();
            this.apagarEndereco();
          }
        })
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    )
  }

  apagarEndereco() {
    this.form.controls.logradouro.reset();
    this.form.controls.municipio.reset();
    this.form.controls.estado.reset();
    this.form.controls.logradouro.enable();
    this.form.controls.municipio.enable();
    this.form.controls.estado.enable();
  }
  
  proximoPasso() {
    this.dadosEditados.imovelDTO.endereco.codigo = this.produtorRural.imovelDTO.endereco.codigo;
    this.dadosEditados.imovelDTO.endereco.dscNumero = this.form.controls.numero.value;
    this.dadosEditados.imovelDTO.endereco.dscComplemento = this.form.controls.complemento.value;
    this.dadosEditados.imovelDTO.endereco.cep.codCep = this.form.controls.cep.value;
    this.dadosEditados.imovelDTO.endereco.cep.logradouro = this.form.controls.logradouro.value;
    this.dadosEditados.imovelDTO.endereco.cep.municipio = this.form.controls.municipio.value;
    this.dadosEditados.imovelDTO.endereco.cep.estado = this.form.controls.estado.value;
    this.dadosEditados.imovelDTO.endereco.tipoComplemento.codigo = this.listaComplemento[this.form.controls.tipoComplemento.value].codigo;
    this.dadosEditados.imovelDTO.endereco.latitude = this.form.controls.latitude.value;
    this.dadosEditados.imovelDTO.endereco.longitute = this.form.controls.longitude.value;
    this.dadosEditados.imovelDTO.codNaturezaImovel = this.produtorRural.imovelDTO.codNaturezaImovel;
    this.dadosEditados.imovelDTO.codigo = this.produtorRural.imovelDTO.codigo;
    this.dadosEditados.imovelDTO.datAquisicao = this.produtorRural.imovelDTO.datAquisicao;
    this.dadosEditados.imovelDTO.datRegistro = this.produtorRural.imovelDTO.datRegistro;
    this.dadosEditados.imovelDTO.dscCartorio = this.produtorRural.imovelDTO.dscCartorio;
    this.dadosEditados.imovelDTO.dscRegistroCartorio = this.produtorRural.imovelDTO.dscRegistroCartorio;
    this.dadosEditados.imovelDTO.tipoUsoImovel.codigo = this.produtorRural.imovelDTO.tipoUsoImovel.codigo;
    this.dadosEditados.passo = 5; 
    this.passarEtapa.emit();
  }

  voltarPasso() {
    this.voltarEtapa.emit();
  }

  desistir() {
    this.sair.emit();
  }
}