import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: 'app-suspensao-produtor-etapa-3',
  templateUrl: './etapa-3.component.html',
  styleUrls: ['./etapa-3.component.scss']
})
export class Etapa3Component implements OnInit {
  @Output() passarEtapa = new EventEmitter();

  @Output() voltarEtapa = new EventEmitter();

  @Output() sair = new EventEmitter();

  @Input() dadosProdutorRural: any;

  @Input() buffer: any;

  listaCnae: any;
  listaSocios: any;
  listaArquivos: any;
  indexEmail: number;

  constructor() {

  }

  ngOnInit(): void {
    this.listaCnae = this.dadosProdutorRural.informacoesFiscaisContribuinte.cnaeContribuinteDTO;
    this.listaSocios = this.dadosProdutorRural.socioDTO;
    this.listaArquivos = this.dadosProdutorRural.imovelDTO.imoveisGed;
    this.indexEmail = this.dadosProdutorRural.contabilistaResponsavel.cadastro.emails.length;
    
  }

  proximaEtapa() {
    this.passarEtapa.emit();
  }

  retornarEtapa() {
    this.voltarEtapa.emit();
  }

  desistir() {
    this.sair.emit();
  }
}