<section class="margin-top container">
  <div class="backdrop">
    <div class="div br-modal xx-large position-modal scroll-modal">
      <div class="br-modal-header">Definir Tipo de Comunicado</div>
      <div class="br-modal-body overflow">
        <div class="br-table">
          <div class="table-header">
            <div class="top-bar padding">
              <div class="d-flex flex-column">
                <div class="table-title">
                  Selecione abaixo um tipo de comunicado para a mensagem a ser enviada
                </div>
                <div class="br-input col-10 mt-3 mb-3">
                  <input
                    id="tipoComunicadoFiltro"
                    type="text"
                    name="tipoComunicado"
                    placeholder="Pesquisar Tipo de Comunicado"
                    [(ngModel)]="filtro"
                    (blur)="filtrar()"
                  />
                    <button class="br-button" type="button" aria-label="Buscar" (click)="filtrar()" [disabled]="this.filtro == ''">
                      <fa-icon [icon]="['fas', 'search']" aria-hidden="true"></fa-icon>
                    </button>
                </div>
              </div>
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <th>Título</th>
                <th>Selecionar</th>
              </tr>
            </thead>
            <tbody>
              <tr 
                *ngFor="let tipoComunicado of listaTipoComunicados" 
                (click)="selecionar(tipoComunicado)"
                class="linha"
              >
                <td>{{ tipoComunicado.dscTipoComunicado }}</td>
                <td>
                  <span 
                    [class]="
                      (tipoComunicado.seqTipoComunicado == tipoComunicadoSelecionado?.seqTipoComunicado)
                        ? 'icon-checked'
                        : 'icon-unchecked'"
                  >
                    <fa-icon [icon]="['fas', 'check']" [classes]="['teste']"></fa-icon>
                  </span>
                </td>
              </tr>
              <tr *ngIf="listaTipoComunicados.length == 0">
                <td>Nenhum resultado encontrado</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="br-modal-footer justify-content-end">
        <button 
          class="br-button primary desistir mr-4" type="button" 
          (click)="fecharTipoComunicado()"
        >Desistir</button>
        <button 
          class="br-button primary" type="button" 
          [disabled]="tipoComunicadoSelecionado == null" 
          (click)="proximoPasso()"
        >Próximo</button>
      </div>
    </div>
  </div>
</section>

<div class="carregamento" *ngIf="loading">
  <img src="../../../assets/images/icones/logo-animado.svg">
</div>