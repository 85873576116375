<section class="servicos">
  <div class="servicos-titulo">Cadastramento</div>
  <div class="servicos-texto">Selecione um dos serviços abaixo clicando sobre eles:</div>
  <div class="margem-top container">
    <div 
      class="servicos-item centralizar" 
      [routerLink]="['../produtor-rural']"
      >
      <div class="row">
        <span class="servicos-item__texto-maior col">PRODUTOR RURAL</span>
      </div>
      <div class="row">
        <span class="servicos-item__texto col">Pessoa Física</span>
      </div>
    </div>
    <div 
      class="servicos-item centralizar"
      [routerLink]="['../canteiro-obras']"
    >
      <div class="row">
        <span class="servicos-item__texto-maior col">CANTEIRO DE OBRAS</span>
      </div>
    </div>
    <div 
      class="servicos-item centralizar"
      [routerLink]="['../substituto-tributario']"
    >
      <div class="row">
        <span class="servicos-item__texto-maior col">SUBSTITUTO TRIBUTÁRIO</span>
      </div>
    </div>
    <div 
      class="servicos-item centralizar"
      [routerLink]="['../informacoes-complementares']"
    >
      <div class="row">
        <span class="servicos-item__texto-pequeno col">INFORMAÇÕES COMPLEMENTARES DE EMPRESAS VINDAS DA REDESIM</span>
      </div>
    </div>
  </div>
</section>