<section class="margem-top container">
  <div class="wizard-sample-1">
    <div class="br-wizard">
      <div class="wizard-progress">
        <button 
          class="wizard-progress-btn" 
          type="button" 
          [title]="titulo"
          [attr.active]="active1 == true ? 'active' : null" 
          [attr.disabled]="active1 == false ? 'disabled' : null" 
          step="1">
          <span class="info">{{ titulo }}</span>
        </button>
        <button 
          class="wizard-progress-btn" 
          type="button" 
          title="Identicação do Produtor Rural"
          [attr.active]="active2 == true ? 'active' : null"
          [attr.disabled]="active2 == false ? 'disabled' : null"
          step='2'>
          <span class="info">Identificação do Produtor Rural</span>
        </button>
        <button 
          class="wizard-progress-btn" 
          type="button" 
          title="Resumo" 
          [attr.active]="active3 == true ? 'active' : null"
          [attr.disabled]="active3 == false ? 'disabled' : null"
          step='3'>
          <span class="info">Resumo</span>
        </button>
        <button 
          class="wizard-progress-btn" 
          type="button" 
          title="Justificativa da Solicitação" 
          [attr.active]="active4 == true ? 'active' : null"
          [attr.disabled]="active4 == false ? 'disabled' : null"
          step='4'>
          <span class="info">Justificativa da Solicitação</span>
        </button>
      </div>
    </div>
  </div>
  <app-suspensao-produtor-etapa-1
    *ngIf="active1 == true"
    [buffer]="bufferObj"
    [contribuinte]="usuarioLogado"
    (passarEtapa)="getDadosEtapa1($event)"
    (sair)="desistir()"
  >
  </app-suspensao-produtor-etapa-1>
  <app-suspensao-produtor-etapa-2
    *ngIf="active2 == true"
    [buffer]="bufferObj"
    [contribuintes]="dadosIntegracao"
    (getCodigoContribuinte)="pegarCodigoContribuinte($event)"
    (voltarEtapa)="voltarEtapa(2)"
    (sair)="desistir()"
  >
  </app-suspensao-produtor-etapa-2>
  <app-suspensao-produtor-etapa-3
    *ngIf="active3 == true"
    [dadosProdutorRural]="dadosProdutorRural"
    (passarEtapa)="passarEtapa(3)"
    (voltarEtapa)="voltarEtapa(3)"
    (sair)="desistir()"
    [buffer]="bufferObj"
  >  
  </app-suspensao-produtor-etapa-3>
  <app-suspensao-produtor-etapa-4
    *ngIf="active4 == true"
    [buffer]="bufferObj"
    [contribuinte]="usuarioLogado"
    (sair)="desistir()"
  >
  </app-suspensao-produtor-etapa-4>
</section>

<div class="carregamento" *ngIf="loading">
  <img src="../../../assets/images/icones/logo-animado.svg">
</div>