import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { Observable } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { TokenService } from "src/app/services/token.service";
import { UsuarioLogadoService } from "src/app/services/usuarioLogado.service";


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  token: string;

  constructor(
    private router: Router,
    private tokenService: TokenService,
    private usuarioLogadoService: UsuarioLogadoService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> | Promise<boolean> {
      this.usuarioLogadoService.getDados();
      if (!this.tokenService.hasToken()) {
        this.router.navigate(['home']);
        return false;
      }
      
      if (
        !this.usuarioLogadoService.validarContribuinteEmUso()
        && route.url[0].path != 'selecao-representante'
        && route.url[0].path != 'integracao'
        && route.url[0].path != 'inicio'
        && route.url[0].path != 'meu-perfil'
      ) {
        localStorage.clear();
        this.router.navigate(['home']);
        return false;
      } 
      return true;
  }
}