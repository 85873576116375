import { Component, OnInit } from "@angular/core";
import { UsuarioLogadoService } from "src/app/services/usuarioLogado.service";

@Component({
  selector: 'app-inscricao-menu',
  templateUrl: './inscricao-menu.component.html',
  styleUrls: ['./inscricao-menu.component.scss']
})
export class InscricaoMenuComponent implements OnInit{

  cadastroEmUso: boolean = false;

  constructor(
    private usuarioLogadoService: UsuarioLogadoService
  ) {

  }

  ngOnInit(): void {
    let obj = this.usuarioLogadoService.getUsuarioLogado();
    this.cadastroEmUso = obj.cadastroEmUso;
  }
}