import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { HeaderComponent } from "./header/header.component";
import { RodapeComponent } from "./rodape/rodape.component";
import { SidemenuComponent } from "./sidemenu/sidemenu.component";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { LoadingComponent } from "./loading/loading.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FontAwesomeModule,
        NgbModule
    ],
    exports: [
        RodapeComponent,
        HeaderComponent,
        SidemenuComponent,
        BreadcrumbComponent,
        LoadingComponent
    ],
    declarations: [
        RodapeComponent,
        HeaderComponent,
        SidemenuComponent,
        BreadcrumbComponent,
        LoadingComponent
    ],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CommonAppModule {

}