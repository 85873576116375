<section class="margin-top container">
  <div class="wizard-sample-1">
    <div class="br-wizard">
      <div class="wizard-progress">
        <button class="wizard-progress-btn" type="button" title="Solicitação Cadastral"
          [attr.active]="active1 == true ? 'active' : null" [attr.disabled]="active1 == false ? 'disabled' : null"
          step="1">
          <span class="info">Solicitação de Cadastramento</span>
        </button>
        <button class="wizard-progress-btn" type="button" title="Categoria de Ocupação e Cadastro Ambiental Rural"
          [attr.active]="active2 == true ? 'active' : null" [attr.disabled]="active2 == false ? 'disabled' : null"
          step='2'>
          <span class="info">Categoria de Ocupação e Cadastro Ambiental Rural</span>
        </button>
        <button class="wizard-progress-btn" type="button" title="Endereço da Propriedade Rural"
          [attr.active]="active3 == true ? 'active' : null" [attr.disabled]="active3 == false ? 'disabled' : null"
          step='3'>
          <span class="info">Endereço da Propriedade Rural</span>
        </button>
        <button class="wizard-progress-btn" type="button" title="Endereço de Correspondência/Fiscal"
          [attr.active]="active4 == true ? 'active' : null" [attr.disabled]="active4 == false ? 'disabled' : null"
          step='4'>
          <span class="info">Endereço de Correspondência/ Fiscal</span>
        </button>
        <button class="wizard-progress-btn" type="button" title="Informações Econômicas e Fiscais"
          [attr.active]="active5 == true ? 'active' : null" [attr.disabled]="active5 == false ? 'disabled' : null"
          step='5'>
          <span class="info">Informações Econômicas e Fiscais</span>
        </button>
        <button class="wizard-progress-btn" type="button" title="Informações sobre a exploração rural"
          [attr.active]="active6 == true ? 'active' : null" [attr.disabled]="active6 == false ? 'disabled' : null"
          step='6'>
          <span class="info">Informações sobre a exploração rural</span>
        </button>
        <button class="wizard-progress-btn" type="button" title="Contabilista Responsável"
          [attr.active]="active7 == true ? 'active' : null" [attr.disabled]="active7 == false ? 'disabled' : null"
          step='7'>
          <span class="info">Contabilista Responsável</span>
        </button>
        <button class="wizard-progress-btn" type="button" title="Sócios"
          [attr.active]="active8 == true ? 'active' : null" [attr.disabled]="active8 == false ? 'disabled' : null"
          step='8'>
          <span class="info">Sócios</span>
        </button>
        <button class="wizard-progress-btn" type="button" title="Confirmação"
          [attr.active]="active9 == true ? 'active' : null" [attr.disabled]="active9 == false ? 'disabled' : null"
          step='9'>
          <span class="info">Confirmação</span>
        </button>
      </div>
    </div>
  </div>
  <div class="ml-5" *ngIf="active1">
    <h3>Solicitação de Cadastramento</h3>
    <div class="row">
      <div class="col-4">
        <h5>Motivo</h5>
        <div class="br-input">
          <label>Motivo<span class="obrigatorio label">*</span></label>
          <input type="text" class="input" disabled />
          <select disabled class="select">
            <option *ngIf="listaBic && listaBic.length > 1" selected>
              {{ campoMotivo.id }} - {{ campoMotivo.descricao }}
            </option>
            <option *ngIf="!listaBic || listaBic.length <= 1" disabled>Sem opções</option>
          </select>          
        </div>
        <h4 class="label"></h4>
      </div>
    </div>
    <h5>Solicitante</h5>
    <div class="row">
      <div class="col-4">
        <div class="br-input">
          <label>C.P.F</label>
          <input type="text" [(ngModel)]="cpfCnpjSolicitante" disabled mask="CPF_CNPJ">
        </div>
      </div>
      <div class="col-4">
        <div class="br-input">
          <label>Nome</label>
          <input type="text" [(ngModel)]="solicitante" disabled>
        </div>
      </div>
      <form class="col-5 mt-4" [formGroup]="produtorRuralForm">
        <div>
          <label class="mb-3">Tipo de Contribuinte</label>
          <div class="br-radio">
            <input class="radio-disabled" id="fisica" type="radio" name="tipoPessoa" [value]="1"
              formControlName="tipoContribuinte" (change)="validarTipoPessoa()">
            <label class="mr-5" for="fisica">Pessoa Física</label>
            <input id="juridica" type="radio" name="tipoPessoa" [value]="2" formControlName="tipoContribuinte"
              (change)="validarTipoPessoa()">
            <label for="juridica">Pessoa Jurídica</label>
          </div>
        </div>
      </form>
    </div>
    <div class="div-botao mt-4">
      <button class="br-button primary posicao-botao" type="button" (click)="proximoPasso()"
        [disabled]="!motivo">Próxima Etapa</button>
      <button class="br-button primary posicao-botao mr-4 desistir" type="button" (click)="desistir()">Desistir</button>
    </div>
  </div>
  <div class="ml-5" *ngIf="active2">
    <h3>Categoria de Ocupação e Cadastro Ambiental Rural</h3>
    <form [formGroup]="produtorRuralForm">
      <div class="row">
        <div class="col-6">
          <div class="br-input">
            <label>Categoria de Ocupação<span class="obrigatorio label">*</span></label>
            <input type="text" class="input" disabled />
            <select class="select" formControlName="categoriaOcupacao" (change)="validarCategoriaOcupacao()">
              <option value="" selected disabled>Selecione a Categoria de Ocupação...</option>
              <option *ngFor="let item of listaOcupacao" [ngValue]="item.codigo">{{ item.descricao }}</option>
            </select>
          </div>
          <p class="mensagem-erro"
            *ngIf="produtorRuralForm.controls.categoriaOcupacao.invalid && produtorRuralForm.controls.categoriaOcupacao.touched">
            Campo obrigatório
          </p>
        </div>
        <div class="col-6">
          <div *ngIf="digitarCar" class="">
            <div class="">
              <div class="br-input">
                <label>Protocolo de cadastro do C.A.R do proprietário ou posseiro<span class="obrigatorio label">*</span></label>
                <input type="text" formControlName="car" maxlength="7" minlength="7" (blur)="consultarCar()"
                  pattern="[0-9]*" (keypress)="onlyNumbers($event)" inputmode="numeric">
              </div>
              <p class="mensagem-erro"
                *ngIf="produtorRuralForm.controls.car.invalid && produtorRuralForm.controls.car.touched && produtorRuralForm.controls.car.value !== ''">
                Campo obrigatório
              </p>
            </div>
          </div>
        </div>
      </div>
      <h5>Atividade</h5>
      <div class="row">
        <div class="col-4">
          <div class="br-input">
            <label>Data de Início da Atividade<span class="obrigatorio label">*</span></label>
          </div>
          <dp-date-picker theme="dp-material" [config]="datePickerConfig" formControlName="dataInicio"
            [(ngModel)]="dataInicioProdutorRural" (onChange)="getDataInicio($event, 'produtorRural')">
          </dp-date-picker>
          <p class="mensagem-erro"
            *ngIf="produtorRuralForm.controls.dataInicio.invalid && produtorRuralForm.controls.dataInicio.touched; else espacoEmBranco">
            Campo obrigatório
          </p>
          <ng-template #espacoEmBranco>
            <p class="mensagem-erro"></p>
          </ng-template>
        </div>
        <div class="col-4">
          <div class="br-input">
            <label>Data Final da Atividade<span class="igualar-altura label"></span></label>
          </div>
          <dp-date-picker theme="dp-material" [config]="datePickerConfig" formControlName="dataTermino"
            [(ngModel)]="dataFimProdutorRural" (onChange)="getDataFim($event, 'produtorRural')">
          </dp-date-picker>
        </div>
      </div>
      <div class="div-botao mt-4">
        <button class="br-button primary posicao-botao" type="button" (click)="proximoPasso()"
          [disabled]="!produtorRuralForm.valid || (digitarCar && !produtorRuralForm.controls.car.value && produtorRuralForm.controls.car.touched)">Próxima
          Etapa</button>
        <button class="br-button secondary posicao-botao mr-4" type="button" (click)="voltarPasso()">Voltar
          Etapa</button>
        <button class="br-button primary posicao-botao mr-4 desistir" type="button"
          (click)="desistir()">Desistir</button>
      </div>
    </form>
  </div>

  <div class="ml-5" *ngIf="active3">
    <h3>Endereço da Propriedade Rural</h3>
    <form [formGroup]="enderecoPropriedadeForm">
      <div class="row">
        <div class="col-4">
          <div class="br-input">
            <label>C.E.P<span class="obrigatorio label">*</span></label>
            <input type="text" formControlName="cep" mask="00.000-000"
              (blur)="validarCep($event, 'enderecoPropriedade')" (change)="enderecoAlterado()">
          </div>
          <p class="mensagem-erro"
            *ngIf="enderecoPropriedadeForm.controls.cep.invalid && enderecoPropriedadeForm.controls.cep.touched">
            Campo obrigatório / Mínimo 8 dígitos
          </p>
        </div>
        <div class="col-4">
          <div class="br-input">
            <label>Logradouro<span class="obrigatorio label">*</span></label>
            <input type="text" formControlName="logradouro">
          </div>
        </div>
        <div class="col-4">
          <div class="br-input">
            <label>Número<span class="obrigatorio label">*</span></label>
            <input type="text" formControlName="numero" maxlength="10">
          </div>
          <p class="mensagem-erro"
            *ngIf="enderecoPropriedadeForm.controls.numero.invalid && enderecoPropriedadeForm.controls.numero.touched">
            Campo obrigatório
          </p>
        </div>
        <div class="col-4">
          <div class="br-input">
            <label>Bairro<span class="obrigatorio label">*</span></label>
            <input type="text" formControlName="bairro">
          </div>
        </div>
        <div class="col-4">
          <div class="br-input">
            <label>Município<span class="obrigatorio label">*</span></label>
            <input type="text" formControlName="municipio">
          </div>
        </div>
        <div class="col-4">
          <div class="br-input">
            <label>Estado<span class="obrigatorio label">*</span></label>
            <input type="text" formControlName="estado">
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-4">
          <div class="br-input">
            <label>Tipo Complemento<span class="igualar-altura label">*</span></label>
            <input type="text" class="input" disabled />
            <select class="select" formControlName="tipoComplemento"
              (change)="validarTipoComplemento('enderecoPropriedade')">
              <option value=null selected disabled>Selecione o Tipo Complemento...</option>
              <option *ngFor="let item of listaComplemento; index as i" [ngValue]="i">{{ item.dscTipoComplemento }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-8">
          <div class="campo-observacao br-input">
            <label>Complemento<span class="igualar-altura label">*</span></label>
            <textarea formControlName="descricaoAcesso" id="descricaoAcesso" class="form-control"
              style="resize: none;"></textarea>
          </div>
        </div>                   
      </div>
      <div class="row mt-4">
        <div class="col-4">
          <label class="mb-3">Localização<span class="obrigatorio label">*</span></label>
          <div class="br-radio">
            <input id="urbana" type="radio" name="localizacao" value="1" formControlName="localizacao">
            <label class="mr-5" for="urbana">Zona Urbana</label>
            <input id="rural" type="radio" name="localizacao" value="2" formControlName="localizacao">
            <label for="rural">Zona Rural</label>
          </div>
        </div>
      </div>
      <h5>Posição Geográfica</h5>
      <div class="row">
        <div class="col-4">
          <div class="br-input">
            <label>Latitude</label>
            <input type="text" formControlName="latitude" (input)="validarLatitude()" />
            <p class="mensagem-erro" *ngIf="enderecoPropriedadeForm.controls.latitude.invalid">
              Mínimo -90 e máximo 90
            </p>
          </div>
        </div>
        <div class="col-4">
          <div class="br-input">
            <label>Longitude</label>
            <input type="text" formControlName="longitude" (input)="validarLongitude()" />
            <p class="mensagem-erro" *ngIf="enderecoPropriedadeForm.controls.longitude.invalid">
              Mínimo -180 e máximo 180
            </p>
          </div>
        </div>
      </div>      
      <div class="div-botao mt-4">
        <button class="br-button primary posicao-botao" type="button" (click)="proximoPasso()"
          [disabled]="!enderecoPropriedadeForm.valid">Próxima Etapa</button>
        <button class="br-button secondary posicao-botao mr-4" type="button" (click)="voltarPasso()">Voltar
          Etapa</button>
        <button class="br-button primary posicao-botao mr-4 desistir" type="button"
          (click)="desistir()">Desistir</button>
      </div>
    </form>
  </div>

  <div class="ml-5" *ngIf="active4">
    <h3>Endereço de Correspondência/Fiscal</h3>
    <form [formGroup]="enderecoCorrespondenciaForm">
      <div class="row">
        <div class="col">
          <div class="br-checkbox mt-3">
            <input id="mesmoEndereco" type="checkbox" formControlName="mesmoEndereco" (change)="copiarFormularios()" />
            <label for="mesmoEndereco">Endereço de Correspondência/Fiscal é o mesmo do endereço da Propriedade
              Rural.</label>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-4">
          <div class="br-input">
            <label>C.E.P<span class="obrigatorio label">*</span></label>
            <input type="text" formControlName="cep" mask="00.000-000"
              (blur)="validarCep($event, 'enderecoCorrespondencia')" #cepEnderecoCorrespondencia>
          </div>
          <p class="mensagem-erro"
            *ngIf="enderecoCorrespondenciaForm.controls.cep.invalid && enderecoCorrespondenciaForm.controls.cep.touched">
            Campo obrigatório / Mínimo 8 dígitos
          </p>
        </div>
        <div class="col-4">
          <div class="br-input">
            <label>Logradouro<span class="igualar-altura label">*</span></label>
            <input type="text" formControlName="logradouro">
          </div>
        </div>
        <div class="col-4">
          <div class="br-input">
            <label>Número<span class="obrigatorio label">*</span></label>
            <input type="text" formControlName="numero" maxlength="10">
          </div>
          <p class="mensagem-erro"
            *ngIf="enderecoCorrespondenciaForm.controls.numero.invalid && enderecoCorrespondenciaForm.controls.numero.touched">
            Campo obrigatório
          </p>
        </div>
        <div class="col-4">
          <div class="br-input">
            <label>Bairro<span class="igualar-altura label">*</span></label>
            <input type="text" formControlName="bairro">
          </div>
        </div>
        <div class="col-4">
          <div class="br-input">
            <label>Município<span class="igualar-altura label">*</span></label>
            <input type="text" formControlName="municipio">
          </div>
        </div>
        <div class="col-4">
          <div class="br-input">
            <label>Estado<span class="igualar-altura label">*</span></label>
            <input type="text" formControlName="estado">
          </div>
        </div>
        <div class="col-4">
          <div class="br-input">
            <label>Tipo Complemento<span class="igualar-altura label">*</span></label>
            <input type="text" class="input" disabled />
            <select class="select" formControlName="tipoComplemento"
              (change)="validarTipoComplemento('enderecoCorrespondencia')">
              <option value="null" disabled>Selecione o Tipo Complemento...</option>
              <option *ngFor="let item of listaComplemento; index as i" [ngValue]="i">{{ item.dscTipoComplemento }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-8">
          <div class="campo-observacao br-input">
            <label>Complemento<span class="igualar-altura label">*</span></label>
            <textarea formControlName="descricaoAcesso" id="descricaoAcesso" class="form-control"
              style="resize: none;"></textarea>
          </div>
        </div>   
      </div>
      <div class="row mt-4">
        <div class="col-4">
          <label class="mb-3">Localização<span class="obrigatorio label">*</span></label>
          <div class="br-radio">
            <input id="urbana" type="radio" name="localizacao" value="1" formControlName="localizacao">
            <label class="mr-5" for="urbana">Zona Urbana</label>
            <input id="rural" type="radio" name="localizacao" value="2" formControlName="localizacao">
            <label for="rural">Zona Rural</label>
          </div>
        </div>
      </div>
      <h5>Posição Geográfica</h5>
      <div class="row">
        <div class="col-4">
          <div class="br-input">
            <label>Latitude</label>
            <input type="text" formControlName="latitude" maxlength="20">
          </div>
          <p class="mensagem-erro" *ngIf="enderecoCorrespondenciaForm.controls.latitude.invalid">
            Mínimo -90 e máximo 90
          </p>
        </div>
        <div class="col-4">
          <div class="br-input">
            <label>Longitude</label>
            <input type="text" formControlName="longitude" maxlength="20">
          </div>
          <p class="mensagem-erro" *ngIf="enderecoCorrespondenciaForm.controls.longitude.invalid">
            Mínimo -180 e máximo 180
          </p>
        </div>
      </div>
      <div class="div-botao mt-4">
        <button class="br-button primary posicao-botao" type="button" (click)="proximoPasso()"
          [disabled]="!enderecoCorrespondenciaForm.valid">Próxima Etapa</button>
        <button class="br-button secondary posicao-botao mr-4" type="button" (click)="voltarPasso()">Voltar
          Etapa</button>
        <button class="br-button primary posicao-botao mr-4 desistir" type="button"
          (click)="desistir()">Desistir</button>
      </div>
    </form>
  </div>
  <div class="ml-5" *ngIf="active5">
    <h3>Informações Econômicas e Fiscais</h3>
    <form [formGroup]="informacoesEconomicasForm">
      <div class="row">
        <div class="col-4">
          <div class="br-input">
            <label>Classificação do Contribuinte<span class="obrigatorio label">*</span></label>
            <input type="text" class="input" disabled />
            <select class="select" formControlName="classificacaoContribuinte">
              <option value="" selected disabled>Selecione a Classificação do Contribuinte...</option>
              <option *ngFor="let item of listaClassificacao" [ngValue]="item.codigo">{{ item.dscClassificacao }}
              </option>
            </select>
          </div>
          <p class="mensagem-erro"
            *ngIf="informacoesEconomicasForm.controls.classificacaoContribuinte.invalid && informacoesEconomicasForm.controls.classificacaoContribuinte.touched">
            Campo obrigatório
          </p>
        </div>
        <div class="col-4">
          <div class="br-input">
            <label>Protocolo Registro na Junta Comercial<span class="igualar-altura label">*</span></label>
            <input type="text" formControlName="protocoloRegistro" maxlength="20" (blur)="validarProtocolo()">
          </div>
        </div>
        <div class="col-4">
          <div class="br-input">
            <label>Data do Registro na Junta Comercial<span class="igualar-altura label">*</span></label>
          </div>
          <dp-date-picker theme="dp-material" [config]="datePickerConfig" formControlName="dataRegistro"
            [(ngModel)]="dataRegistro" (onChange)="getDataInicio($event, 'informacoesEconomicas')">
          </dp-date-picker>
          <p class="mensagem-erro" *ngIf="informacoesEconomicasForm.controls.dataRegistro.invalid">
            Campo obrigatório
          </p>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-4">
          <div class="br-input">
            <label>Capital Social<span class="obrigatorio label">*</span></label>
            <input type="text" formControlName="capitalSocial" currencyMask
              [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }">
          </div>
          <p class="mensagem-erro"
            *ngIf="informacoesEconomicasForm.controls.capitalSocial.invalid && informacoesEconomicasForm.controls.capitalSocial.touched">
            Campo obrigatório
          </p>
        </div>
        <div class="col-4">
          <div class="br-input">
            <label>Regime de Recolhimento<span class="igualar-altura label">*</span></label>
            <input type="text" class="input" disabled />
            <select class="select" formControlName="regimeRecolhimento">
              <option value="" selected disabled>Selecione o Regime de Recolhimento...</option>
              <option *ngFor="let item of listaRegimeRecolhimento" [ngValue]="item.id">{{
                item.descricaoRegimeRecolhimento }}</option>
            </select>
          </div>
          <p class="mensagem-erro"
            *ngIf="informacoesEconomicasForm.controls.regimeRecolhimento.invalid && informacoesEconomicasForm.controls.regimeRecolhimento.touched">
            Campo obrigatório
          </p>
        </div>
        <div class="col-4">
          <div class="br-input">
            <label>Natureza Jurídica<span class="obrigatorio label">*</span></label>
            <input type="text" class="input" disabled />
            <select class="select" formControlName="naturezaJuridica">
              <option value="" selected disabled>Selecione a Natureza Jurídica...</option>
              <option *ngFor="let item of listaNaturezaJuridica" [ngValue]="item.id">{{ item.dscNaturezaJuridica }}
              </option>
            </select>
          </div>
          <p class="mensagem-erro"
            *ngIf="informacoesEconomicasForm.controls.naturezaJuridica.invalid && informacoesEconomicasForm.controls.naturezaJuridica.touched">
            Campo obrigatório
          </p>
        </div>
      </div>
      <h5>Cnae<span class="obrigatorio label">*</span></h5>
      <button class="botao-modoEscuro br-button primary mb-2" type="button"
        (click)="abrirModal('cnae')">Adicionar</button>
      <div class="br-table">
        <table>
          <thead>
            <tr>
              <th scope="col">CNAE</th>
              <th scope="col">Primária</th>
              <th scope="col">Exercido no endereço do estabelecimento</th>
              <th scope="col">Deletar</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="cnae.length > 0; else semDados"></ng-container>
            <tr *ngFor="let item of cnae">
              <td>{{ item.cnaeTexto }}</td>
              <td>{{ item.principalTexto }}</td>
              <td>{{ item.exercidoTexto }}</td>
              <td><img class="deletar" src="../../../../assets/images/icones/trash-solid.svg" alt="Lixeira"
                  (click)="deletarCnae(item)"></td>
            </tr>
            <ng-template #semDados>
              <tr>
                <td colspan="7" align="center">Sem dados para serem exibidos.</td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
      <div class="div-botao mt-4">
        <button class="br-button primary posicao-botao" type="button" (click)="proximoPasso()"
          [disabled]="!informacoesEconomicasForm.valid || cnae.length == 0">Próxima Etapa</button>
        <button class="br-button secondary posicao-botao mr-4" type="button" (click)="voltarPasso()">Voltar
          Etapa</button>
        <button class="br-button primary posicao-botao mr-4 desistir" type="button"
          (click)="desistir()">Desistir</button>
      </div>
    </form>
  </div>
  <div class="ml-5" *ngIf="active6">
    <h3>Informações sobre a exploração rural</h3>
    <form [formGroup]="informacoesExploracaoForm">
      <div class="row">
        <div class="col-4">
          <div class="br-input">
            <label>Nome do Imóvel<span class="obrigatorio label">*</span></label>
            <input type="text" formControlName="nomeImovel" maxlength="150">
          </div>
          <p class="mensagem-erro"
            *ngIf="informacoesExploracaoForm.controls.nomeImovel.invalid && informacoesExploracaoForm.controls.nomeImovel.touched">
            Campo obrigatório / Mínimo 2 dígitos
          </p>
        </div>
        <div class="col-5">
          <div class="br-input">
            <label>Tipo de imóvel<span class="obrigatorio label">*</span></label>
            <input type="text" class="input">
            <select class="select" formControlName="tipoImovel">
              <option value="" selected disabled>Selecione o Tipo de imóvel...</option>
              <option value="1">IRU | Imóvel Rural</option>
              <option value="2"> PCT | Imóvel Rural de Povos e Comunidades Tradicionais</option>
              <option value="3">AST | Imóvel Rural de Assentamentos da Reforma Agrária</option>
            </select>
          </div>
        </div>
        <div class="col-3">
          <div class="br-input">
            <label>Data de aquisição<span class="obrigatorio label">*</span></label>
          </div>
          <dp-date-picker theme="dp-material" [config]="datePickerConfig" formControlName="dataAquisicao"
            [(ngModel)]="dataAquisicao" (onChange)="getDataInicio($event, 'informacoesExploracao')">
          </dp-date-picker>
          <p class="mensagem-erro"
            *ngIf="informacoesExploracaoForm.controls.dataAquisicao.invalid && informacoesExploracaoForm.controls.dataAquisicao.touched">
            Campo obrigatório
          </p>
        </div>
        <div class="col-8">
              <div class="br-input">
                <label>Número do C.A.R<span class="obrigatorio label">*</span></label>
                <input type="text" formControlName="numeroCar" maxlength="45" mask="AA-0000000-AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA">
              </div>
              <p class="mensagem-erro"
                *ngIf="informacoesExploracaoForm.controls.numeroCar.invalid && informacoesExploracaoForm.controls.numeroCar.touched">
                Campo obrigatório / Formato inválido
              </p>
            </div>

            <div class="col-4">
              <div class="br-input">
                <label>Protocolo do C.A.R<span class="obrigatorio label">*</span></label>
                <input type="text" formControlName="protocoloCar" maxlength="7">
              </div>
              <p class="mensagem-erro"
                *ngIf="informacoesExploracaoForm.controls.protocoloCar.invalid && informacoesExploracaoForm.controls.protocoloCar.touched">
                Campo obrigatório / Mínimo 2 dígitos
              </p>
            </div>
            <!-- <div class="col-4">
              <label class="br-input">Possui sede?<span class="obrigatorio label">*</span></label>
              <div class="br-radio">
                <input class="radio-disabled" id="possuiSede" type="radio" name="sede" [value]="1"
                  formControlName="sede" (change)="validarPossuiSede()">
                <label class="mr-5" for="possuiSede">Sim</label>
                <input id="naoPossuiSede" type="radio" name="sede" [value]="2" formControlName="sede"
                  (change)="validarPossuiSede()">
                <label for="naoPossuiSede">Não</label>
              </div>
            </div> -->
        <div class="col-4">
          <div *ngIf="possuiSede" class="">
            <div class="">
              <div class="br-input">
                <label>Protocolo do Cadastro Ambiental Rural (C.A.R)<span class="obrigatorio label">*</span></label>
                <input type="text" formControlName="digitarSede" maxlength="7" minlength="7">
              </div>
              <p class="mensagem-erro"
                *ngIf="produtorRuralForm.controls.car.invalid && produtorRuralForm.controls.car.touched && produtorRuralForm.controls.car.value !== ''">
                Campo obrigatório
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-3">
          <div class="br-input">
            <label>Área Total da Propriedade (ha)<span class="obrigatorio label">*</span></label>
            <input type="text" formControlName="areaImovel" mask="separator.1" thousandSeparator="." decimalMarker=",">
          </div>
          <p class="mensagem-erro" *ngIf="informacoesExploracaoForm.controls.areaImovel.invalid && informacoesExploracaoForm.controls.areaImovel.touched">
            Campo obrigatório
          </p>
        </div>
        <div class="col-3">
          <div class="br-input">
            <label>Área de pastagem natural<span class="igualar-altura label">*</span></label>
            <input type="text" formControlName="areaPastagemNatural" mask="separator.1" thousandSeparator="."
              decimalMarker=",">
          </div>
        </div>
        <div class="col-3">
          <div class="br-input">
            <label>Área de pastagem formada<span class="igualar-altura label">*</span></label>
            <input type="text" formControlName="areaPastagemFormada" mask="separator.1" thousandSeparator="."
              decimalMarker=",">
          </div>
        </div>
        <div class="col-3">
          <div class="br-input">
            <label>Área inexplorada<span class="igualar-altura label">*</span></label>
            <input type="text" formControlName="areaInexplorada" mask="separator.1" thousandSeparator="."
              decimalMarker=",">
          </div>
        </div>
        <div class="col-3">
          <div class="br-input">
            <label>Área cultivada<span class="igualar-altura label">*</span></label>
            <input type="text" formControlName="areaCultivada" mask="separator.1" thousandSeparator="."
              decimalMarker=",">
          </div>
        </div>
        <div class="col-3">
          <div class="br-input">
            <label>Valor estimado do imóvel<span class="obrigatorio label">*</span></label>
            <input type="text" formControlName="valorEstimadoImovel" currencyMask
              [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }">
          </div>
          <p class="mensagem-erro"
            *ngIf="informacoesExploracaoForm.controls.valorEstimadoImovel.invalid && informacoesExploracaoForm.controls.valorEstimadoImovel.touched">
            Campo obrigatório
          </p>
        </div>
        <div class="col-3">
          <div class="br-input">
            <label>Módulos fiscais<span class="obrigatorio label">*</span></label>
            <input type="text" formControlName="modulosFiscais" maxlength="150">
          </div>
          <p class="mensagem-erro"
            *ngIf="informacoesExploracaoForm.controls.modulosFiscais.invalid && informacoesExploracaoForm.controls.modulosFiscais.touched">
            Campo obrigatório / Mínimo 2 dígitos
          </p>
        </div>
      </div>
      <!-- <div class="col-4">
              <div class="br-input">
                <label>Inscrição no INCRA<span class="obrigatorio label">*</span></label>
                <input type="text" formControlName="inscricaoIncra" maxlength="13">
              </div>
              <p class="mensagem-erro"
                *ngIf="informacoesExploracaoForm.controls.inscricaoIncra.invalid && informacoesExploracaoForm.controls.inscricaoIncra.touched">
                Campo obrigatório
              </p>
            </div> -->
      <!-- <h5>Inscrição no INCRA<span class="obrigatorio label">*</span></h5>
            <button class="botao-modoEscuro br-button primary mb-2" type="button" (click)="abrirModal('cnae')">Adicionar</button>
            <div class="br-table">
              <table>
                <thead>
                  <tr>
                    <th scope="col">CNAE</th>
                    <th scope="col">Primária</th>
                    <th scope="col">Deletar</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="cnae.length > 0; else semDados"></ng-container>
                  <tr *ngFor="let item of cnae">
                    <td>{{ item.cnaeTexto }}</td>
                    <td>{{ item.principalTexto }}</td>
                    <td><img class="deletar" src="../../../../assets/images/icones/trash-solid.svg" alt="Lixeira"
                        (click)="deletarCnae(item)"></td>
                  </tr>
                  <ng-template #semDados>
                    <tr>
                      <td colspan="7" align="center">Sem dados para serem exibidos.</td>
                    </tr>
                  </ng-template>
                </tbody>
              </table>
            </div> -->

      <div class="row mt-4">
        <div class="col-8">
          <div class="campo-observacao br-input">
            <label>Observação<span class="igualar-altura label">*</span></label>
            <textarea formControlName="observacao" rows="5" id="objetivoComunicacao" class="form-control"
              id="objetivoComunicacao" #objetivoTextArea
              style="resize: none; height: 100px;"></textarea>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-8">
          <div class="br-upload">
            <label class="upload-label">
              Anexar documento comprovante da propriedade rural (Escritura do imóvel OU contrato de compra e venda registrado em cartório OU 
              Documento emitido pelo Itertins OU Incra OU Comprovante de filiação em Associação local de Produtores Agropecuários)
            </label>
            <input class="upload-input" type="file" accept="image/jpg, image/jpeg, .pdf, .gif"
              #file_input (change)="anexo($event)" [disabled]="arquivos.length >= 1" />
            <button class="upload-button" type="button" (click)="file_input.click()" [disabled]="arquivos.length >= 1">
              <fa-icon [icon]="['fas', 'upload']"></fa-icon>
              <span>Selecione o arquivo</span>
            </button>
            <p class="mensagem-erro mt-2" *ngIf="!arquivoValido">Formato de arquivo inválido(Formatos válidos: PDF,
              JPEG, JPG, GIF)</p>
            <div class="upload-list mt-2" *ngIf="arquivos.length > 0">
              {{ arquivos[0].name }}
              <fa-icon class="lixeira" [icon]="['fas', 'trash']" (click)="deletarArquivo()"></fa-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="div-botao mt-4">
        <button class="br-button primary posicao-botao" type="button" (click)="proximoPasso()"
          [disabled]="!informacoesExploracaoForm.valid">Próxima Etapa</button>
        <button class="br-button secondary posicao-botao mr-4" type="button" (click)="voltarPasso()">Voltar
          Etapa</button>
        <button class="br-button primary posicao-botao mr-4 desistir" type="button"
          (click)="desistir()">Desistir</button>
      </div>
    </form>
  </div>
  <div class="ml-5" *ngIf="active7">
    <h3>Contabilista Responsável</h3>
    <form [formGroup]="contabilistaForm">
      <div class="row">
        <div class="col-4">
          <div class="br-input">
            <label>C.P.F.</label>
            <input type="text" formControlName="cpf" mask="CPF_CNPJ" maxlength="14"
              (blur)="validarDigitoCpfCnpj('contabilista')">
          </div>
          <p class="mensagem-erro"
            *ngIf="contabilistaForm.controls.cpf.invalid && contabilistaForm.controls.cpf.touched">
            Campo obrigatório / C.P.F Inválido
          </p>
        </div>
        <div class="col-8">
          <div class="br-input">
            <label>Nome</label>
            <input type="text" formControlName="nome" maxlength="200">
          </div>
          <p class="mensagem-erro"
            *ngIf="contabilistaForm.controls.nome.invalid && contabilistaForm.controls.nome.touched">
            Campo obrigatório / Mínimo 10 dígitos
          </p>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-4">
          <div class="br-input">
            <label>C.R.C</label>
            <input type="text" formControlName="crc" maxlength="20"
              (blur)="consultarCrc(contabilistaForm.controls.crc.value)">
          </div>
          <p class="mensagem-erro"
            *ngIf="contabilistaForm.controls.crc.invalid && contabilistaForm.controls.crc.touched">
            Campo obrigatório
          </p>
        </div>
        <div class="col-8">
          <div class="br-input">
            <label>E-mail</label>
            <input type="text" formControlName="email" maxlength="100">
          </div>
          <p class="mensagem-erro"
            *ngIf="contabilistaForm.controls.email.invalid && contabilistaForm.controls.email.touched">
            Campo obrigatório / E-mail em formato incorreto
          </p>
        </div>
      </div>
      <div class="div-botao mt-4">
        <button class="br-button primary posicao-botao" type="button" (click)="proximoPasso()"
          [disabled]="!contabilistaForm.valid">Próxima Etapa</button>
        <button class="br-button secondary posicao-botao mr-4" type="button" (click)="voltarPasso()">Voltar
          Etapa</button>
        <button class="br-button primary posicao-botao mr-4 desistir" type="button"
          (click)="desistir()">Desistir</button>
      </div>
    </form>
  </div>
  <div class="ml-5" *ngIf="active8">
    <h3>Sócios</h3>
    <div class="d-flex align-items-end">
      <button class="botao-modoEscuro br-button primary mb-2" type="button" (click)="abrirModal('socios')"
        [disabled]="!liberarAdicao">Adicionar</button>
      <p class="mensagem-erro-negrito ml-3"
        *ngIf="!liberarAdicao && this.produtorRuralForm.controls.categoriaOcupacao.value == 1">Somente um sócio
        é permitido quando a Categoria de Ocupação selecionada for "Próprio"
      </p>
      <p class="mensagem-erro-negrito ml-3"
        *ngIf="!liberarAdicao && participacaoCounter == 100 && this.produtorRuralForm.controls.categoriaOcupacao.value != 1">
        Participação máxima atingida (100%)
      </p>
    </div>
    <div class="br-table">
      <table>
        <thead>
          <tr>
            <th scope="col">País</th>
            <th scope="col">Tipo Documento</th>
            <th scope="col">Documento</th>
            <th scope="col">Nome</th>
            <th scope="col">Participação({{ participacaoCounter }}%)</th>
            <th scope="col">Data entrada do sócio</th>
            <th scope="col">Data saída do sócio</th>
            <th scope="col">Deletar</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="socios.length > 0; else semSocios"></ng-container>
          <tr *ngFor="let item of socios; index as i">
            <td>{{ listaPaises[item.socioEndereco.endereco.pais.codigo - 1].dscPais }}</td>
            <td>{{ listaGed[i].descricaoGed }}</td>
            <td>{{ item.dscDocumento }}</td>
            <td>{{ item.dscNomeSocio }}</td>
            <td>{{ item.participacao }}%</td>
            <td>{{ item.datInicio | date: 'dd/MM/yyyy' }}</td>
            <td>{{ item.datFim ? (item.datFim | date: 'dd/MM/yyyy') : '-' }}</td>
            <td><img class="deletar" src="../../../../assets/images/icones/trash-solid.svg" alt="Lixeira"
                (click)="deletarSocio(item)"></td>
          </tr>
          <ng-template #semSocios>
            <tr>
              <td colspan="8" align="center">Sem dados para serem exibidos.</td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>
    <div class="div-botao mt-4">
      <button class="br-button primary posicao-botao" type="button" (click)="proximoPasso()"
        [disabled]="socios.length == 0">Próxima Etapa</button>
      <button class="br-button secondary posicao-botao mr-4" type="button" (click)="voltarPasso()">Voltar Etapa</button>
      <button class="br-button primary posicao-botao mr-4 desistir" type="button" (click)="desistir()">Desistir</button>
    </div>
  </div>
  <div class="ml-5" *ngIf="active9">
    <h3>Confirmação</h3>
    <div>
      <h4>Solicitação Cadastral</h4>
      <div class="container">
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Motivo:</label>
            <p class="texto pt-1 ml-2">{{ listaBic[motivo - 1].descricao }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">C.P.F/CNPJ:</label>
            <p class="texto pt-1 ml-2">{{ cpfCnpjSolicitante | mask:'CPF_CNPJ'}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Nome:</label>
            <p class="texto pt-1 ml-2">{{ solicitante }}</p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h4>Categoria de Ocupação e Cadastro Ambiental Rural</h4>
      <div class="container">
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Categoria de Ocupação:</label>
            <p class="texto pt-1 ml-2">{{ listaOcupacao[produtorRuralForm.controls.categoriaOcupacao.value -
              1].descricao
              }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Data de Início:</label>
            <p class="texto pt-1 ml-2">{{ produtorRuralForm.controls.dataInicio.value | date: 'dd/MM/yyyy' }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Término da Atividade:</label>
            <p class="texto pt-1 ml-2">
              {{
              produtorRuralForm.controls.dataTermino.value != null
              ? (produtorRuralForm.controls.dataTermino.value | date: 'dd/MM/yyyy')
              : 'Não Informado'
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h4>Endereço da Propriedade Rural</h4>
      <div class="container">
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">C.E.P:</label>
            <p class="texto pt-1 ml-2">{{ enderecoPropriedadeForm.controls.cep.value | mask: '00000-000' }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Bairro:</label>
            <p class="texto pt-1 ml-2">{{ enderecoPropriedadeForm.controls.bairro.value }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Município:</label>
            <p class="texto pt-1 ml-2">{{ enderecoPropriedadeForm.controls.municipio.value }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Número:</label>
            <p class="texto pt-1 ml-2">{{ enderecoPropriedadeForm.controls.numero.value }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Tipo Complemento:</label>
            <p class="texto pt-1 ml-2">
              {{
              enderecoPropriedadeForm.controls.tipoComplemento.value != null
              ? listaComplemento[enderecoPropriedadeForm.controls.tipoComplemento.value]?.dscTipoComplemento
              : 'Não Informado'
              }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Localização:</label>
            <p class="texto pt-1 ml-2">{{ enderecoPropriedadeForm.controls.localizacao.value == '1' ? 'Zona Urbana' :
              'Zona Rural' }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Latitude:</label>
            <p class="texto pt-1 ml-2">
              {{
              enderecoPropriedadeForm.controls.latitude.value != null
              ? enderecoPropriedadeForm.controls.latitude.value
              : 'Não Informado'
              }}
            </p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Longitude:</label>
            <p class="texto pt-1 ml-2">
              {{
              enderecoPropriedadeForm.controls.longitude.value != null
              ? enderecoPropriedadeForm.controls.longitude.value
              : 'Não Informado'
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h4>Endereço da Correspondência Fiscal</h4>
      <div class="container">
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">C.E.P:</label>
            <p class="texto pt-1 ml-2">{{ enderecoCorrespondenciaForm.controls.cep.value | mask: '00000-000' }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Bairro:</label>
            <p class="texto pt-1 ml-2">{{ enderecoCorrespondenciaForm.controls.bairro.value }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Município:</label>
            <p class="texto pt-1 ml-2">{{ enderecoCorrespondenciaForm.controls.municipio.value }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Número:</label>
            <p class="texto pt-1 ml-2">{{ enderecoCorrespondenciaForm.controls.numero.value }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Tipo Complemento:</label>
            <p class="texto pt-1 ml-2">
              {{
              enderecoCorrespondenciaForm.controls.tipoComplemento.value != null
              ? listaComplemento[enderecoCorrespondenciaForm.controls.tipoComplemento.value]?.dscTipoComplemento
              : 'Não Informado'
              }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Localização:</label>
            <p class="texto pt-1 ml-2">{{ enderecoCorrespondenciaForm.controls.localizacao.value == '1' ? 'Zona Urbana'
              : 'Zona Rural' }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Latitude:</label>
            <p class="texto pt-1 ml-2">
              {{
              enderecoCorrespondenciaForm.controls.latitude.value != null
              ? enderecoCorrespondenciaForm.controls.latitude.value
              : 'Não Informado'
              }}
            </p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Longitude:</label>
            <p class="texto pt-1 ml-2">
              {{
              enderecoCorrespondenciaForm.controls.longitude.value != null
              ? enderecoCorrespondenciaForm.controls.longitude.value
              : 'Não Informado'
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h4>Informações Econômicas e Fiscais</h4>
      <div class="container">
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Classificação do Contribuinte:</label>
            <p class="texto pt-1 ml-2">{{
              listaClassificacao[informacoesEconomicasForm.controls.classificacaoContribuinte.value -
              1].dscClassificacao }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Protocolo Registro na Junta Comercial:</label>
            <p class="texto pt-1 ml-2">
              {{
              informacoesEconomicasForm.controls.protocoloRegistro.value != null
              ? informacoesEconomicasForm.controls.protocoloRegistro.value
              : 'Não Informado'
              }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Data do Registro na Junta Comercial:</label>
            <p class="texto pt-1 ml-2">
              {{
              informacoesEconomicasForm.controls.dataRegistro.value != null
              ? (informacoesEconomicasForm.controls.dataRegistro.value | date: 'dd/MM/yyyy')
              : 'Não Informado'
              }}
            </p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Capital Social:</label>
            <p class="texto pt-1 ml-2">R$ {{ informacoesEconomicasForm.controls.capitalSocial.value }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Regime de Recolhimento:</label>
            <p class="texto pt-1 ml-2">{{
              listaRegimeRecolhimento[informacoesEconomicasForm.controls.regimeRecolhimento.value].descricaoRegimeRecolhimento
              }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col d-flex">
            <label class="titulo">Natureza Jurídica:</label>
            <p class="texto pt-1 ml-2">{{
              listaNaturezaJuridica[informacoesEconomicasForm.controls.naturezaJuridica.value - 1].dscNaturezaJuridica
              }}</p>
          </div>
        </div>
        <div class="row">
          <label class="titulo">CNAEs({{ cnae.length }})</label>
        </div>
        <div *ngFor="let item of cnae">
          <div class="row">
            <div class="col-12 d-flex">
              <label class="titulo">CNAE - Primária - Exercido no endereço do estabelecimento:</label>
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-flex">
              <p class="texto pt-1 ml-2">{{ item.cnaeTexto }} - {{ item.numPrincipal == 1 ? 'Sim' : 'Não' }} - {{
                item.numExerceNoEnd == 1 ? 'Sim' : 'Não' }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h4>Informações sobre a exploração rural</h4>
      <div class="container">
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Data de aquisição:</label>
            <p class="texto pt-1 ml-2">{{ informacoesExploracaoForm.controls.dataAquisicao.value | date: 'dd/MM/yyyy' }}
            </p>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Inscrição no INCRA:</label>
            <p class="texto pt-1 ml-2">{{ informacoesExploracaoForm.controls.inscricaoIncra.value }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Nome do Imóvel:</label>
            <p class="texto pt-1 ml-2">{{ informacoesExploracaoForm.controls.nomeImovel.value }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Tipo de imóvel:</label>
            <p class="texto pt-1 ml-2">{{ informacoesExploracaoForm.controls.nomeImovel.value }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Módulos fiscais:</label>
            <p class="texto pt-1 ml-2">{{ informacoesExploracaoForm.controls.modulosFiscais.value }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Número do C.A.R:</label>
            <p class="texto pt-1 ml-2">{{ informacoesExploracaoForm.controls.numeroCar.value }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Protocolo do C.A.R:</label>
            <p class="texto pt-1 ml-2">{{ informacoesExploracaoForm.controls.protocoloCar.value }}</p>
          </div>
        </div> -->
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Área Total da Propriedade (ha):</label>
            <p class="texto pt-1 ml-2">{{ informacoesExploracaoForm.controls.areaImovel.value }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Área de pastagem natural:</label>
            <p class="texto pt-1 ml-2">
              {{
              informacoesExploracaoForm.controls.areaPastagemNatural.value != null
              ? informacoesExploracaoForm.controls.areaPastagemNatural.value
              : 'Não Informado'
              }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Área de pastagem formada:</label>
            <p class="texto pt-1 ml-2">
              {{
              informacoesExploracaoForm.controls.areaPastagemFormada.value != null
              ? informacoesExploracaoForm.controls.areaPastagemFormada.value
              : 'Não Informado'
              }}
            </p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Área inexplorada:</label>
            <p class="texto pt-1 ml-2">
              {{
              informacoesExploracaoForm.controls.areaInexplorada.value != null
              ? informacoesExploracaoForm.controls.areaInexplorada.value
              : 'Não Informado'
              }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Área cultivada:</label>
            <p class="texto pt-1 ml-2">
              {{
              informacoesExploracaoForm.controls.areaCultivada.value != null
              ? informacoesExploracaoForm.controls.areaCultivada.value
              : 'Não Informado'
              }}
            </p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Valor estimado do imóvel:</label>
            <p class="texto pt-1 ml-2">R$ {{ informacoesExploracaoForm.controls.valorEstimadoImovel.value }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col d-flex">
            <label class="titulo">Observação:</label>
            <p class="texto pt-1 ml-2">
              {{
              informacoesExploracaoForm.controls.observacao.value != null
              ? informacoesExploracaoForm.controls.observacao.value
              : 'Não Informado'
              }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col d-flex">
            <label class="titulo">Arquivos({{ arquivos.length }}):</label>
            <div *ngFor="let item of arquivos">
              <p class="pt-1 ml-2">{{ item.name }} | </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h4>Contabilista Responsável</h4>
      <div class="container">
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">C.P.F:</label>
            <p class="texto pt-1 ml-2">
              {{
              contabilistaForm.controls.cpf.value != ''
              ? (contabilistaForm.controls.cpf.value | mask: 'CPF_CNPJ')
              : 'Não Informado'
              }}
            </p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Nome:</label>
            <p class="texto pt-1 ml-2">
              {{
              contabilistaForm.controls.nome.value != ''
              ? contabilistaForm.controls.nome.value
              : 'Não Informado'
              }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">C.R.C:</label>
            <p class="texto pt-1 ml-2">
              {{
              contabilistaForm.controls.crc.value != ''
              ? contabilistaForm.controls.crc.value
              : 'Não Informado'
              }}
            </p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">E-mail:</label>
            <p class="texto pt-1 ml-2">
              {{
              contabilistaForm.controls.email.value != ''
              ? contabilistaForm.controls.email.value
              : 'Não Informado'
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h4>Sócios({{ socios.length }})</h4>
      <div class="container" *ngFor="let item of socios">
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">País:</label>
            <p class="texto pt-1 ml-2">{{ listaPaises[item.socioEndereco.endereco.pais.codigo - 1].dscPais }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Nome:</label>
            <p class="texto pt-1 ml-2">{{ item.dscNomeSocio }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Participação:</label>
            <p class="texto pt-1 ml-2">{{ item.participacao }}%</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Tipo de Pessoa:</label>
            <p class="texto pt-1 ml-2">{{ item.numTipoPessoa == 1 ? 'Pessoa Física' : 'Pessoa Jurídica' }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Função na propriedade rural:</label>
            <p class="texto pt-1 ml-2">{{ item.funcaoPropriedadeRural}}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Data da Entrada na sociedade:</label>
            <p class="texto pt-1 ml-2"> {{ item.datInicio | date: 'dd/MM/yyyy' }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Data de Saída da sociedade:</label>
            <p class="texto pt-1 ml-2">
              {{
              item.datFim != null
              ? (item.datFim | date: 'dd/MM/yyyy')
              : 'Não Informado'
              }}
            </p>
          </div>
        </div>
        <div class="row mt-2" *ngIf="item.socioEndereco.endereco.pais.codigo != 30">
          <div class="col-6 d-flex">
            <label class="titulo">Endereço completo no País de origem:</label>
            <p class="texto pt-1 ml-2">{{ item.socioEndereco.endereco.dscEnderecoExterior }}</p>
          </div>
        </div>
        <div class="divisoria" *ngIf="item.socioEndereco.endereco.pais.codigo != 30"></div>
        <div *ngIf="item.socioEndereco.endereco.pais.codigo == 30">
          <div class="row mt-2">
            <div class="col-6 d-flex">
              <label class="titulo">C.E.P:</label>
              <p class="texto pt-1 ml-2">{{ item.socioEndereco.endereco.cep.codCep | mask: '00000-000' }}</p>
            </div>
            <div class="col-6 d-flex">
              <label class="titulo">Logradouro:</label>
              <p class="texto pt-1 ml-2">{{ item.logradouro }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-6 d-flex">
              <label class="titulo">Número:</label>
              <p class="texto pt-1 ml-2">{{ item.numero }}</p>
            </div>
            <div class="col-6 d-flex">
              <label class="titulo">Tipo Complemento:</label>
              <p class="texto pt-1 ml-2">
                {{
                item.valueComplemento != null
                ? listaComplemento[item.valueComplemento].dscTipoComplemento
                : 'Não Informado'
                }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-6 d-flex">
              <label class="titulo">Complemento:</label>
              <p class="texto pt-1 ml-2">
                {{
                item.socioEndereco.endereco.dscComplemento
                ? item.socioEndereco.endereco.dscComplemento
                : 'Não Informado'
                }}
              </p>
            </div>
            <div class="col-6 d-flex">
              <label class="titulo">Latitude:</label>
              <p class="texto pt-1 ml-2">
                {{
                item.socioEndereco.endereco.latitude
                ? item.socioEndereco.endereco.latitude
                : 'Não Informado'
                }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-6 d-flex">
              <label class="titulo">Longitude:</label>
              <p class="texto pt-1 ml-2">
                {{
                item.socioEndereco.endereco.longitute
                ? item.socioEndereco.endereco.longitute
                : 'Não Informado'
                }}
              </p>
            </div>
          </div>
          <div class="divisoria"></div>
        </div>
      </div>
    </div>
    <div class="div-botao mt-4">
      <button class="br-button primary posicao-botao" type="button" (click)="cadastrarProdutorRural()">Enviar para
        SEFAZ</button>
      <button class="br-button secondary posicao-botao mr-4" type="button" (click)="voltarPasso()">Voltar Etapa</button>
      <button class="br-button primary posicao-botao mr-4 desistir" type="button" (click)="desistir()">Desistir</button>
    </div>
  </div>
  <div class="backdrop" *ngIf="cnaeAberto">
    <div class="div br-modal x-large position-modal">
      <div class="br-modal-header">CNAE - {{ this.nomeCnae }}</div>
      <div class="br-modal-body overflow">
        <form [formGroup]="cnaeForm">
          <div class="row">
            <div class="col-10">
              <div class="br-input">
                <label>CNAE<span class="obrigatorio label">*</span></label>
                <input type="text" class="input" disabled />
                <select class="select" formControlName="cnae" (change)="getNomeCnae()">
                  <option value="" selected disabled>Selecione o CNAE...</option>
                  <option *ngFor="let item of listaCnae; index as i" [ngValue]="i">{{ item.dscCnae }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="mt-3 ml-4">
            <label class="mb-3">Essa é a atividade primária?<span class="obrigatorio label">*</span></label>
            <div class="br-radio">
              <input id="sim" type="radio" name="atividadePrincipal" value="1" formControlName="atividadePrincipal">
              <label class="mr-5" for="sim">Sim</label>
              <input id="nao" type="radio" name="atividadePrincipal" value="0" formControlName="atividadePrincipal">
              <label for="nao">Não</label>
            </div>
            <label class="mb-3">Essa atividade é exercida no endereço do estabelecimento?<span class="obrigatorio label">*</span></label>
            <div class="br-radio">
              <input type="radio" name="atividadePrimaria" value="1" formControlName="atividadePrimaria" selected>
              <label class="mr-5">Sim</label>
              <input type="radio" name="atividadePrimaria" value="0" formControlName="atividadePrimaria">
              <label>Não</label>
            </div>
            
          </div>          
        </form>
      </div>
      <div class="br-modal-footer justify-content-center">
        <button class="br-button primary desistir mr-4" type="button" (click)="fecharModal('cnae')">Desistir</button>
        <button class="botao-modoEscuro br-button primary" type="button" (click)="adicionarCnae()"
          [disabled]="!cnaeForm.valid">Adicionar</button>
      </div>
    </div>
  </div>

  <div class="backdrop" *ngIf="modalCarAtivo">
    <div class="div br-modal x-large position-modal">
      <div class="br-modal-header">Conferência dos dados do C.A.R</div>
      <div class="br-modal-body overflow">
        <div class="row">
          <div class="col-12 modal-car">
            <div class="input-group">
              <div class="br-input">
                <label class="titulo-modal">Nome</label>
                <input type="text" class="input input-modal-longo"
                  [value]="dataCar?.proprietariosPosseirosConcessionarios[0]?.nome" disabled />
              </div>
            </div>

            <div class="input-group">
              <div class="br-input">
                <label class="titulo-modal">CPF</label>
                <input type="text" class="input input-modal"
                  [value]="dataCar?.proprietariosPosseirosConcessionarios[0]?.cpfCnpj" disabled />
              </div>
              <div class="br-input">
                <label class="titulo-modal">Data de nascimento</label>
                <input type="text" class="input input-modal"
                  [value]="dataCar?.proprietariosPosseirosConcessionarios[0]?.dataNascimento" disabled />
              </div>
            </div>

            <div class="input-group">
              <div class="br-input">
                <label class="titulo-modal">Telefone</label>
                <input type="text" class="input input-modal" [value]="dataCar?.imovel?.telefone" disabled />
              </div>
              <div class="br-input">
                <label class="titulo-modal">Tipo de pessoa</label>
                <input type="text" class="input input-modal"
                  [value]="dataCar?.proprietariosPosseirosConcessionarios[0]?.tipo" disabled />
              </div>
            </div>

            <div class="input-group">
              <div class="br-input">
                <label class="titulo-modal">Nome do Imóvel</label>
                <input type="text" class="input input-modal" [value]="dataCar?.imovel?.nome" disabled />
              </div>
              <div class="br-input">
                <label class="titulo-modal">Tipo do Imóvel</label>
                <input type="text" class="input input-modal" [value]="dataCar?.imovel?.tipo" disabled />
              </div>
            </div>

            <div class="input-group">
              <div class="br-input">
                <label class="titulo-modal">CEP</label>
                <input type="text" class="input input-modal" [value]="dataCar?.imovel?.cep" disabled />
              </div>
              <div class="br-input">
                <label class="titulo-modal">Logradouro</label>
                <input type="text" class="input input-modal"
                  [value]="dataCar?.imovel?.enderecoCorrespondencia?.logradouro" disabled />
              </div>
            </div>

            <div class="input-group">
              <div class="br-input">
                <label class="titulo-modal">Número</label>
                <input type="text" class="input input-modal" [value]="dataCar?.imovel?.enderecoCorrespondencia?.numero"
                  disabled />
              </div>
              <div class="br-input">
                <label class="titulo-modal">Bairro</label>
                <input type="text" class="input input-modal" [value]="dataCar?.imovel?.enderecoCorrespondencia?.bairro"
                  disabled />
              </div>
            </div>

            <div class="input-group">
              <div class="br-input" style="width: 100%;">
                <label class="titulo-modal">Complemento</label>
                <textarea formControlName="observacao" rows="auto" id="objetivoComunicacao"
                  class="form-control input-modal-longo" [value]="dataCar?.imovel?.descricaoAcesso" #objetivoTextArea
                  style="resize: none; height: 100px;"></textarea>
              </div>
            </div>

            <div class="input-group">
              <div class="br-input">
                <label class="titulo-modal">Código IBGE do Município</label>
                <input type="text" class="input input-modal" [value]="dataCar?.imovel?.codigoMunicipio" disabled />
              </div>
              <div class="br-input">
                <label class="titulo-modal">Cidade</label>
                <input type="text" class="input input-modal" [value]="dataCar?.imovel?.enderecoCorrespondencia?.cidade"
                  disabled />
              </div>
            </div>

            <div class="input-group">
              <div class="br-input">
                <label class="titulo-modal">Estado</label>
                <input type="text" class="input input-modal"
                  [value]="dataCar?.imovel?.enderecoCorrespondencia?.siglaEstado" disabled />
              </div>
              <div class="br-input">
                <label class="titulo-modal">Zona de Localização</label>
                <input type="text" class="input input-modal" [value]="dataCar?.imovel?.zonaLocalizacao" disabled />
              </div>
            </div>

            <div class="input-group">
              <div class="br-input">
                <label class="titulo-modal">Módulos Fiscais</label>
                <input type="text" class="input input-modal" [value]="dataCar?.imovel?.modulosFiscais" disabled />
              </div>
              <div class="br-input">
                <label class="titulo-modal">Área líquida do Imóvel</label>
                <input type="text" class="input input-modal" [value]="this.areaImovel" disabled />
              </div>
            </div>

            <div class="input-group">
              <div class="br-input" *ngIf="dataCar?.imovel?.email != null || dataCar?.imovel?.email != undefined">
                <label class="titulo-modal">Email</label>
                <input type="text" class="input input-modal" [value]="dataCar?.imovel?.email" disabled />
              </div>
            </div>

            <div class="input-group">
              <div class="br-input" style="width: 100%;">
                <label class="titulo-modal">C.A.R</label>
                <input type="text" class="input input-modal-longo" [value]="dataCar?.imovel?.numeroRecibo" disabled />
              </div>
            </div>

            <div class="input-group">
              <div class="br-input">
                <label class="titulo-modal">Protocolo do C.A.R</label>
                <input type="text" class="input input-modal" [value]="dataCar?.imovel?.numeroReciboEstadual" disabled />
              </div>
              <div class="br-input">
                <label class="titulo-modal">Status</label>
                <input type="text" class="input input-modal" [value]="dataCar?.imovel?.status" disabled />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="br-modal-footer justify-content-center">
        <button class="br-button primary desistir mr-4" type="button" (click)="fecharModalCarAtivo()">Dados
          incorretos</button>
        <button class="botao-modoEscuro br-button primary" type="button" (click)="abrirModalCarAtivo()">Dados
          corretos</button>
      </div>
    </div>
  </div>
  <div class="backdrop" *ngIf="sociosAberto">
    <div class="div br-modal xx-large position-modal">
      <div class="br-modal-header">Adicionar Sócio</div>
      <div class="br-modal-body overflow">
        <form [formGroup]="sociosForm">
          <div class="row">
            <div class="col-4">
              <div class="br-input">
                <label>Nome<span class="obrigatorio label">*</span></label>
                <input type="text" formControlName="nome" maxlength="160">
              </div>
              <p class="mensagem-erro" *ngIf="sociosForm.controls.nome.invalid && sociosForm.controls.nome.touched">
                Campo obrigatório / Mínimo 2 Dígitos
              </p>
            </div>
            <div class="col-4">
              <div class="br-input">
                <label>C.P.F<span class="obrigatorio label">*</span></label>
                <input type="text" formControlName="cpfCnpj" mask="CPF_CNPJ" (blur)="validarDigitoCpfCnpj('socios')">
              </div>
              <p class="mensagem-erro"
                *ngIf="sociosForm.controls.cpfCnpj.invalid && sociosForm.controls.cpfCnpj.touched">
                Campo obrigatório / C.P.F ou C.N.P.J Inválido
              </p>
            </div>
            <div class="col-4">
              <div class="br-input">
                <label>RG<span class="igualar-altura label">*</span></label>
                <input type="text" formControlName="rg" mask="00.000.000-0">
              </div>
            </div>
            <div class="col-4">
              <label class="mb-2 mt-2">Tipo de Pessoa:<span class="obrigatorio label">*</span></label>
              <div class="br-radio">
                <input id="socioJuridica" type="radio" name="socioTipo" [value]="2" formControlName="tipoPessoa">
                <label class="mr-5" for="socioJuridica">Pessoa Jurídica</label>
                <input id="socioFisica" type="radio" name="socioTipo" [value]="1" formControlName="tipoPessoa"
                  #tipoPessoaSocio>
                <label for="socioFisica">Pessoa Física</label>
              </div>
            </div>
            <div class="col-4 filter-dropdown">
              <label class="label">País<span class="obrigatorio label">*</span></label>
              <p-dropdown [options]="listaPaises" optionLabel="dscPais" optionValue="codigo" [filter]="true"
                filterBy="dscPais" placeholder="Selecione o País..." formControlName="pais"
                emptyFilterMessage="Sem resultados" [(ngModel)]="pais" (onChange)="verificarPais()">
              </p-dropdown>
              <p class="mensagem-erro" *ngIf="sociosForm.controls.pais.invalid && sociosForm.controls.pais.touched">
                Campo obrigatório
              </p>
            </div>
            <div class="col-4" *ngIf="sociosForm.controls.pais.value == 30 && sociosForm.controls.pais.value > 0">
              <div class="br-input">
                <label>C.E.P<span class="obrigatorio label">*</span></label>
                <input type="text" class="input" formControlName="cep" mask="00.000-000"
                  (blur)="validarCep($event, 'socios')" #cepSocios>
              </div>
              <p class="mensagem-erro" *ngIf="sociosForm.controls.cep.invalid && sociosForm.controls.cep.touched">
                Campo obrigatório / Mínimo 8 dígitos
              </p>
            </div>

            <div class="col-8" *ngIf="sociosForm.controls.pais.value == 30 && sociosForm.controls.pais.value > 0">
              <div class="br-input">
                <label>Endereço<span class="obrigatorio label">*</span></label>
                <input type="text" formControlName="logradouro">
              </div>
              <p class="mensagem-erro"
                *ngIf="sociosForm.controls.logradouro.invalid && sociosForm.controls.logradouro.touched">
                Campo obrigatório
              </p>
            </div>
            <div class="col-4" *ngIf="sociosForm.controls.pais.value == 30 && sociosForm.controls.pais.value > 0">
              <div class="br-input">
                <label>Número<span class="obrigatorio label">*</span></label>
                <input type="text" formControlName="numero" maxlength="10">
              </div>
              <p class="mensagem-erro" *ngIf="sociosForm.controls.numero.invalid && sociosForm.controls.numero.touched">
                Campo obrigatório
              </p>
            </div>

            <div class="col-4">
              <div class="br-input">
                <label>Tipo Complemento<span class="igualar-altura label">*</span></label>
                <input type="text" class="input" disabled>
                <select class="select" formControlName="tipoComplemento" (change)="validarTipoComplemento('socios')">
                  <option value="" selected disabled>Selecione o Tipo de Complemento...</option>
                  <option *ngFor="let item of listaComplemento; index as i" [ngValue]="i">{{ item.dscTipoComplemento }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-4">
              <div class="br-input">
                <label>Complemento<span class="igualar-altura label">*</span></label>
                <input type="text" formControlName="complemento" maxlength="20">
              </div>
            </div>
            <div class="col-2">
              <div class="br-input">
                <label>Latitude<span class="igualar-altura label">*</span></label>
                <input type="text" formControlName="latitude" maxlength="20">
              </div>
              <p class="mensagem-erro" *ngIf="sociosForm.controls.latitude.invalid">
                Mínimo -90 e máximo 90
              </p>
            </div>
            <div class="col-2">
              <div class="br-input">
                <label>Longitude<span class="igualar-altura label">*</span></label>
                <input type="text" formControlName="longitude" maxlength="20">
              </div>
              <p class="mensagem-erro" *ngIf="sociosForm.controls.longitude.invalid">
                Mínimo -180 e máximo 180
              </p>
            </div>

            <div class="col-4">
              <label class="mb-2">Função na propriedade rural<span class="obrigatorio label">*</span></label>
              <div class="br-radio">
                <input id="titular" type="radio" name="funcaoPropriedadeRural" [value]="1"
                  formControlName="funcaoPropriedadeRural">
                <label class="mr-5" for="titular">Titular</label>
                <input id="administrador" type="radio" name="funcaoPropriedadeRural" [value]="2"
                  formControlName="funcaoPropriedadeRural">
                <label class="mr-5" for="administrador">Administrador</label>
                <input id="socio" type="radio" name="funcaoPropriedadeRural" [value]="3"
                  formControlName="funcaoPropriedadeRural">
                <label class="mt-1" for="socio">Sócio</label>
              </div>
            </div>
            <div class="col-4">
              <div class="br-input">
                <label>% Participação<span class="obrigatorio label">*</span></label>
                <input type="number" formControlName="participacao" maxlength="3" max="100">
              </div>
              <p class="mensagem-erro"
                *ngIf="sociosForm.controls.participacao.invalid && sociosForm.controls.participacao.touched">
                Campo obrigatório / Excedeu o número máximo (100)
              </p>
            </div>
            <div class="col-4">
              <div class="br-input">
                <label>Data da Entrada na sociedade<span class="obrigatorio label">*</span></label>
                <dp-date-picker theme="dp-material" [config]="datePickerConfig" formControlName="dataEntrada"
                  [(ngModel)]="dataInicioSocio" (onChange)="getDataInicio($event, 'socios')">
                </dp-date-picker>
              </div>


              <p class="mensagem-erro"
                *ngIf="sociosForm.controls.dataEntrada.invalid && sociosForm.controls.dataEntrada.touched">
                Campo obrigatório
              </p>
            </div>
            <div class="col-4">
              <div class="br-input">
                <label>Data de Saída da sociedade<span class="igualar-altura label">*</span></label>
                <dp-date-picker theme="dp-material" [config]="datePickerConfig" formControlName="dataSaida"
                  [(ngModel)]="dataFimSocio" (onChange)="getDataFim($event, 'socios')">
                </dp-date-picker>
              </div>
            </div>
            <div *ngIf="sociosForm.controls.pais.value != 30 && sociosForm.controls.pais.value > 0"
              class="row mt-4 mb-2">
              <div class="col">
                <div class="br-input">
                  <label>Endereço completo no País de origem<span class="obrigatorio label">*</span></label>
                  <input type="text" formControlName="enderecoCompleto" maxlength="200">
                </div>
                <p class="mensagem-erro"
                  *ngIf="sociosForm.controls.dataEntrada.invalid && sociosForm.controls.dataEntrada.touched">
                  Campo obrigatório
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="br-modal-footer justify-content-center">
        <button class="br-button primary desistir mr-4" type="button" (click)="fecharModal('socios')">Desistir</button>
        <button class="botao-modoEscuro br-button primary" type="button" (click)="adicionarSocio()"
          [disabled]="!sociosForm.valid">Adicionar</button>
      </div>
    </div>
  </div>
</section>
<app-loading *ngIf="loadingReceita" [mensagem]="'Processando análise junto a Receita Federal, aguarde...'">
</app-loading>

<app-loading *ngIf="loadingCar"
  [mensagem]="'Caro usuário, aguarde, por favor! Estamos checando a informação, isso pode demorar alguns minutos..'">
</app-loading>

<div class="carregamento" *ngIf="loading">
  <img src="../../../assets/images/icones/logo-animado.svg">
</div>