import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable()
export class MenuService {

  private apiUrl = environment.API_URL
  private menuSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public menu$: Observable<any> = this.menuSubject.asObservable();
  
  constructor(private http: HttpClient) { }

  getMenusAutorizados(codigo): Observable<any> {
    return this.http.get(
      `${this.apiUrl}/dominios/perfilAcesso/listaMenus/${codigo}`
    );
  }

  getPerfisAcesso() {
    return this.http.get(
      `${this.apiUrl}/dominios/perfilAcesso/pesfisCadastrados`
    );
  } 

}