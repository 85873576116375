import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: 'app-suspensao-produtor-etapa-2',
  templateUrl: './etapa-2.component.html',
  styleUrls: ['./etapa-2.component.scss']
})
export class Etapa2Component implements OnInit {
  @Output() voltarEtapa = new EventEmitter();

  @Output() sair = new EventEmitter();
  
  @Output() getCodigoContribuinte = new EventEmitter();

  @Input() contribuintes: any;

  @Input() buffer: any;

  listaContribuinte: any = [];

  constructor() {

  }

  ngOnInit(): void {
    let contribuintes: any = [];
    let socios: any = [];
    for (let i = 0; i < this.contribuintes.sessao.contribuinte?.length; i++) {
      if (this.contribuintes.sessao.contribuinte[i].codClassificacao == 3) {
        contribuintes.push(this.contribuintes.sessao.contribuinte[i]);
      }
    }
    contribuintes.forEach(element => {
      element.relacao = 'Contribuinte';
      this.listaContribuinte.push(element);
    });

    for (let i = 0; i < this.contribuintes.sessao.procurador?.length; i++) {
      if (this.contribuintes.sessao.procurador[i].codClassificacao == 3) {
        this.listaContribuinte.push(this.contribuintes.sessao.procurador[i]);
      }
    }

    for (let i = 0; i <this.contribuintes.sessao.socio?.length; i++) {
      if (this.contribuintes.sessao.socio[i].codClassificacao == 3) {
        socios.push(this.contribuintes.sessao.socio[i]);
      }
    }
    socios.forEach(element => {
      element.relacao = 'Sócio';
      this.listaContribuinte.push(element);
    });
  }

  pegarCodigoContribuinte(item) {
    this.getCodigoContribuinte.emit(item.codigo);
  }

  retornarEtapa() {
    this.voltarEtapa.emit();
  }

  desistir() {
    this.sair.emit();
  }

}