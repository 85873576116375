import { Component } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";

@Component({
  selector: 'app-atendimento-dec',
  templateUrl: './atendimento-dec.component.html',
  styleUrls: ['./atendimento-dec.component.scss']
})
export class AtendimentoDecComponent {

  formulario: FormGroup;
  anexos: File[] = [];
  relacaoSolicitanteContribuinte: string = '';
  motivoSolicitacaooAtendimento: string = '';

  constructor(private fb: FormBuilder) {
    this.formulario = this.fb.group({
      dscCpfCnpjSolicitante: [''],
      dscNomeRazaoSocialSolicitante: [''],
      emailSolicitante: [''],
      telefoneSolicitante: [''],
      dscCpfCnpjContribuinte: [''],
      dscNomeRazaoSocialContribuinte: [''],
      relacaoSolicitanteContribuinte: [''],
      motivoSolicitacaooAtendimento: [''],
      txtMensagem: ['']
    });
  }

  onSelectionChange(relacao: string) {
    this.relacaoSolicitanteContribuinte = relacao;
  }

  onMotivoChange(motivo: string) {
    this.motivoSolicitacaooAtendimento = motivo;
  }

  enviarFormulario() {
    // const formData = new FormData();

    // const dadosFormulario = {
    //   solicitante: {
    //     dscCpfCnpj: this.formulario.get('dscCpfCnpjSolicitante')?.value,
    //     dscNomeRazaoSocial: this.formulario.get('dscNomeRazaoSocialSolicitante')?.value,
    //     email: this.formulario.get('emailSolicitante')?.value,
    //     telefone: this.formulario.get('telefoneSolicitante')?.value,
    //   },
    //   contribuinte: {
    //     dscCpfCnpj: this.formulario.get('dscCpfCnpjContribuinte')?.value,
    //     dscNomeRazaoSocial: this.formulario.get('dscNomeRazaoSocialContribuinte')?.value,
    //   },
    //   relacaoSolicitanteContribuinte: this.relacaoSolicitanteContribuinte,
    //   motivoSolicitacaooAtendimento: this.motivoSolicitacaooAtendimento,
    //   txtMensagem: this.formulario.get('txtMensagem')?.value,
    // };

    // formData.append('dados', JSON.stringify(dadosFormulario));

    // this.anexos.forEach((file, index) => {
    //   formData.append(`anexo${index}`, file, file.name);
    // });

    // this.http.post('URL_DA_API', formData).subscribe(response => {
    //   console.log('Formulário enviado com sucesso', response);
    // }, error => {
    //   console.error('Erro ao enviar formulário', error);
    // });
  }
}
