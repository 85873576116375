<div class="ml-5">
  <h3>Endereço de Correspondência/Fiscal</h3>
  <form [formGroup]="form">
    <div class="row">
      <div class="col">
        <div class="br-checkbox mt-3">
          <input id="refis" type="checkbox" formControlName="mesmoEndereco" (change)="copiarFormularios()"/>
          <label for="refis">Endereço de Correspondência/Fiscal é o mesmo do endereço da Propriedade Rural.</label>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-4">
        <div class="br-input">
          <label>C.E.P<span class="obrigatorio label">*</span></label>
          <input type="text" formControlName="cep" mask="00.000-000" (blur)="validarCep($event)" #cepEnderecoCorrespondencia>
        </div>
        <p 
          class="mensagem-erro" 
          *ngIf="form.controls.cep.invalid && form.controls.cep.touched"
        >
          Campo obrigatório / Mínimo 8 dígitos
        </p>
      </div>
      <div class="col-4">
        <div class="br-input">
          <label>Logradouro<span class="igualar-altura label">*</span></label>
          <input type="text" formControlName="logradouro">
        </div>
      </div>
      <div class="col-4">
        <div class="br-input">
          <label>Município<span class="igualar-altura label">*</span></label>
          <input type="text" formControlName="municipio">
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-2">
        <div class="br-input">
          <label>Estado<span class="igualar-altura label">*</span></label>
          <input type="text" formControlName="estado">
        </div>
      </div>
      <div class="col-3">
        <div class="br-input">
          <label>Número<span class="obrigatorio label">*</span></label>
          <input type="text" formControlName="numero" mask="9999999999">
        </div>
        <p 
          class="mensagem-erro" 
          *ngIf="form.controls.numero.invalid && form.controls.numero.touched"
        >
          Campo obrigatório
        </p>
      </div>
      <div class="col-4">
        <div class="br-input">
          <label>Tipo Complemento<span class="igualar-altura label">*</span></label>
          <input type="text" class="input" disabled />
          <select class="select" formControlName="tipoComplemento" (change)="validarTipoComplemento()">
            <option value=null selected disabled>Selecione o Tipo Complemento...</option>
            <option *ngFor="let item of listaComplemento; index as i" [ngValue]="i">{{ item.dscTipoComplemento }}</option>
          </select>
        </div>
      </div>
      <div class="col-3">
        <div class="br-input">
          <label>Complemento<span class="igualar-altura label">*</span></label>
          <input type="text" formControlName="complemento" maxlength="20">
        </div>
        <p 
          class="mensagem-erro" 
          *ngIf="form.controls.complemento.invalid"
        >
          Campo obrigatório
        </p>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-4">
        <label class="mb-3">Localização<span class="obrigatorio label">*</span></label>
        <div class="br-radio">
          <input id="urbana" type="radio" name="localizacao" [value]="1" formControlName="localizacao">
          <label class="mr-5" for="urbana">Zona Urbana</label>
          <input id="rural" type="radio" name="localizacao" [value]="2" formControlName="localizacao">
          <label for="rural">Zona Rural</label>
        </div>
      </div>
    </div>
    <h5>Posição Geográfica</h5>
    <div class="row">
      <div class="col-4">
        <div class="br-input">
          <label>Latitude</label>
          <input type="text" formControlName="latitude" maxlength="20">
        </div>
        <p 
          class="mensagem-erro" 
          *ngIf="form.controls.latitude.invalid"
        >
          Mínimo -90 e máximo 90
        </p>
      </div>
      <div class="col-4">
        <div class="br-input">
          <label>Longitude</label>
          <input type="text" formControlName="longitude" maxlength="20">
        </div>
        <p 
          class="mensagem-erro" 
          *ngIf="form.controls.longitude.invalid"
        >
          Mínimo -180 e máximo 180
        </p>
      </div>
    </div>
    <div class="div-botao mt-4">
      <button class="br-button primary posicao-botao" type="button" (click)="proximoPasso()" [disabled]="!form.valid">Próxima Etapa</button>
      <button class="br-button secondary posicao-botao mr-4" type="button" (click)="voltarPasso()">Voltar Etapa</button>
      <button class="br-button primary posicao-botao mr-4 desistir" type="button" (click)="desistir()">Desistir</button>
    </div>
  </form>
</div>