<section class="margin-top container">
    <div>
        <h3>Perfil do Contribuinte</h3>
    </div>
    <div class="container-flex">
        <div class="row">
            <div class="col-4">
                <div class="br-input">
                    <label>CPF/CNPJ</label>
                        <input
                        class=""
                        disabled
                        id="cpfCnpj"
                        name="cpfCnpj"
                        [(ngModel)]="cpfCnpj"
                        placeholder="CPF/CNPJ"
                        type="text"
                        />
                </div>
            </div>
            <div class="col-4">
                <div class="br-input">
                    <label>Nome</label>
                        <input
                        class=""
                        disabled
                        id="nome"
                        name="nome"
                        [(ngModel)]="nomeCadastro"
                        placeholder="Nome"
                        type="text"
                        />
                </div>
            </div>
            <div class="col-4">
                <div class="br-input">
                    <label>E-mail</label>
                        <input
                        class=""
                        id="email"
                        disabled
                        name="email"
                        [(ngModel)]="email"
                        placeholder="E-mail"
                        type="text"
                        />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <div class="br-input">
                    <label>Nome Fantasia</label>
                        <input
                        class=""
                        disabled
                        id="nomeFantasia"
                        name="nomeFantasia"
                        [(ngModel)]="nomeFantasia"
                        placeholder="Nome Fantasia"
                        type="text"
                        />
                </div>
            </div>
            <div class="col-4">
                <div class="br-input">
                    <label>Razão Social</label>
                        <input
                        class=""
                        disabled
                        id="razaoSocial"
                        name="razaoSocial"
                        [(ngModel)]="razaoSocial"
                        placeholder="Razão Social"
                        type="text"
                        />
                </div>
            </div>
            <div class="col-4">
                <div class="br-input">
                    <label>Inscrição Estadual</label>
                        <input
                        class=""
                        disabled
                        id="inscricaoEstadual"
                        name="inscricaoEstadual"
                        [(ngModel)]="inscricaoEstadual"
                        placeholder="Inscrição Estadual"
                        type="text"
                        />
                </div>
            </div>
        </div>
    </div>
</section>
<section class="margin-top container">
    <div>
        <h3>E-mail</h3>
    </div>
    <div class="container-flex">
        <div class="row">
            <div class="col-4">
                <div class="br-input">
                    <label>E-mail</label>
                        <input
                            type="text"
                            id="email"
                            name="email"
                            placeholder="E-mail"
                            type="text"
                            [(ngModel)]="novoEmail"
                            (input)="validarEmail()"
                        />
                        <div class="mensagem-erro" *ngIf="emailNovoInvalido === false">Email inválido. Por favor, insira um email válido.</div>
                </div>
            </div>
            <div class="col-8">
                <label class="mb-3">Prioridade</label>
                <div class="br-radio">
                    <input
                        id="1"
                        type="radio"
                        name="prioridadeEmail"
                        value=1
                        [(ngModel)]="priorEmail"
                    />
                    <label class="mr-4" for="1">Primário</label>
                    <input
                        id="2"
                        type="radio"
                        value=2
                        [(ngModel)]="priorEmail"
                    />
                    <label class="mr-4" for="2">Secundário</label>
                    <button type="button" class="btn btn-primary" title="Adicionar E-mail" (click)="inserirEmailTemp()" [disabled]="emailNovoInvalido === false || emailNovoInvalido == null || priorEmail == 0">
                        Adicionar/Alterar E-mail
                    </button>
                </div>
            </div>
        </div>
        <div class="top-bar">
            <table>
                <thead>
                    <tr>
                        <th scope="col">E-mail</th>
                        <th scope="col">Prioridade</th>
                        <th scope="col">Ação</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="emailsFiltrados.length > 0; else semDados"></ng-container>
                    <tr *ngFor="let itemEmail of emailsFiltrados let i = index">
                    <ng-container *ngIf="itemEmail.descricao !== undefined && itemEmail.tipoEmail !== undefined">
                    <td>
                    {{ preencherTabela('descricao', itemEmail.descricao, itemEmail.codigo) }}
                    </td>
                    <td>
                    {{ preencherTabela('tipoEmail', itemEmail.tipoEmail) }}
                    </td>
                    <td
                    (click)="apagarEmailTemp(i)"
                    >
                    <img src="/assets/images/icones/trash-solid.svg" class="icone-colorido" alt="Apagar Email" width="20" height="20" title="Apagar Email">
                    </td>
                </ng-container>
                </tr>
                <ng-template #semDados>
                    <tr>
                        <td colspan="3" align="center">Sem dados para serem exibidos</td>
                    </tr>
                </ng-template>
                </tbody>
            </table>
        </div>
    </div>
    <div class="col-12 botoes">
        <button type="button" class="btn btn-secondary btn-select" title="Desistir" (click)="desistir()">
            Desistir
        </button>
        <button type="button" class="btn btn-primary btn-select" title="Salvar Dados" [disabled]="dadoAlterado === false" (click)="salvarDados()" >
            Salvar Dados
          </button>          
    </div>
</section>
<div class="carregamento" *ngIf="loading">
    <img src="../../../assets/images/icones/logo-animado.svg">
</div>