import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PerfilContribuinteService } from './../../services/perfil-contribuinte.service';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-perfil-contribuinte',
  templateUrl: './perfil-contribuinte.component.html',
  styleUrls: ['./perfil-contribuinte.component.scss']
})
export class PerfilContribuinteComponent implements OnInit {

  dadosContribuinte: string;
  cpfCnpj: string;
  contribuinteFormatado: any;
  solicitante: any;
  nomeCadastro: any;
  email: string;
  nomeFantasia: string;
  cadastroContribuinte: any;
  razaoSocial: string;
  cadastroFormatado: any;
  inscricaoEstadual: string;
  codigoContribuinte: any;
  novoEmail: string;
  priorEmail: number = 0;
  contribuinteId: number;
  prioridade: any;
  emailsApi: any[] = [];
  emailNovoInvalido: boolean | null = null;
  emailsFiltrados: any[] = [];
  dadoAlterado: boolean | null = false;
  emailsTemp: {descricao: string, tipoEmail: number, codigo?: number }[] = [];
  emailsApagar: {codigo: number }[] = [];
  emailAdicionado: boolean = false;
  loading: boolean = false;

  constructor(
    private PerfilContribuinteService: PerfilContribuinteService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.cadastroContribuinte = localStorage.getItem('dadosIntegracao');
    this.dadosContribuinte = localStorage.getItem('contribuinteEmUso');
    this.contribuinteFormatado = JSON.parse(this.dadosContribuinte);
    this.cadastroFormatado = JSON.parse(this.cadastroContribuinte);
    this.contribuinteId = this.contribuinteFormatado.codigo;
    this.getEmailsTabela();
    this.preencherCampos();
    }

    getEmailsTabela() {
      this.loading = true;
      this.PerfilContribuinteService.getEmails(this.contribuinteId).subscribe(
        (res) => {
          this.emailsApi = res;
          this.emailsFiltrados = this.emailsApi.filter(email => email.situacao !== 0);
          this.ordenarEmails();
          if (this.emailsFiltrados.length > 0) {
            this.email = this.emailsFiltrados[0].descricao;
            this.emailsTemp = this.emailsFiltrados.slice();
          }
        },
        err => {
          console.log(err);
        },
        () => {
          this.loading = false;
          this.novoEmail = null;
          this.priorEmail = 0;
        }
      );
    }

    ordenarEmails() {
      this.emailsFiltrados.sort((a, b) => {
        const descricaoA = a.descricao.toLowerCase();
        const descricaoB = b.descricao.toLowerCase();
        if (a.tipoEmail === 1 && b.tipoEmail === 1 || a.tipoEmail !== 1 && b.tipoEmail !== 1) {
          return descricaoA.localeCompare(descricaoB);
        }
        return a.tipoEmail === 1 ? -1 : 1;
      });
    }
    

    preencherCampos(){
      this.nomeCadastro = this.contribuinteFormatado.cadastro.dscNomeCadastro;
      this.cpfCnpj = this.mascaraCpfCnpj(this.contribuinteFormatado.cadastro.cpfCnpj);
      this.nomeFantasia = this.contribuinteFormatado.dscNomeFantasia;
      this.razaoSocial = this.contribuinteFormatado.dscRazaoSocial;
      this.inscricaoEstadual = this.contribuinteFormatado.dscIe;
    }    

    inserirEmailTemp() {
      console.log(this.priorEmail);
      if (this.novoEmail !== undefined && this.novoEmail !== null && this.priorEmail !== 0) {
        if (this.priorEmail == 1) {
          const possuiEmailPrimario = this.emailsTemp.some(email => email.tipoEmail === 1);
          if (possuiEmailPrimario) {
            Swal.fire({
              icon: 'error',
              text: 'Não é permitido adicionar outro e-mail primário'
            });
            return;
          }
        }
        if (this.emailsTemp.some(email => email.descricao === this.novoEmail && email.tipoEmail == this.priorEmail)) {
          Swal.fire({
            icon: 'error',
            text: 'Não é possível inserir um e-mail existente com a mesma prioridade'
          });
          return;
        }
        const novoItemEmail = { descricao: this.novoEmail, tipoEmail: this.priorEmail };
        this.emailsTemp.push(novoItemEmail);
        this.dadoAlterado = true;
        this.emailAdicionado = true;
        this.atualizarEmailsLocais();
      }
    }
    

    atualizarEmailsLocais() {
      this.emailsFiltrados = this.emailsTemp.slice();
      this.ordenarEmails();
    }
    
    mascaraCpfCnpj(cpfCnpj){
      this.cpfCnpj = cpfCnpj.replace(/\D/g, '');

      if (this.cpfCnpj.length == 11) {
          this.cpfCnpj = this.cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
      } else {
          this.cpfCnpj = this.cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");
      }
      return this.cpfCnpj;
    }

    preencherTabela(coluna: string, valor: any, codigo?: number): string {
      switch (coluna) {
        case 'descricao':
          return valor;
        case 'tipoEmail':
          const tipoEmail = typeof valor === 'string' ? parseInt(valor, 10) : valor;
          const tipoEmailString = tipoEmail.toString();
          switch (tipoEmailString) {
            case '1':
              return 'Primário';
            case '2':
              return 'Secundário';
            default:
              return 'Desconhecido';
          }
        case 'codigo':
          return codigo !== undefined ? codigo.toString() : '';
        default:
          return '';
      }
    }

    apagarEmailTemp(index: number) {
      const emailRemovido: { descricao: string; tipoEmail: number; codigo: number } = this.emailsTemp.splice(index, 1)[0] as any;
      this.emailsApi.push(emailRemovido);
      this.emailsApagar.push(emailRemovido);
      this.dadoAlterado = true;
      this.atualizarEmailsLocais();
    }

    validarEmail(){
      this.emailNovoInvalido = this.validate(this.novoEmail);
    }

    validate(novoEmail: string){
      const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const tamanho = novoEmail ? novoEmail.length : 0;
      return tamanho == 0 || regex.test(String(novoEmail).toLowerCase());
    }

    desistir() {
      Swal.fire({
        icon: 'question',
        text: 'Atenção! Desistir neste momento implica em descartar todos os dados digitados e não salvos. Confirma?',
        confirmButtonText: 'Sim',
        showCancelButton: true,
        cancelButtonText: 'Não'
      }).then(result => {
        if (result.isConfirmed) {
          this.emailsTemp = this.emailsApi.slice();
          this.router.navigate(['inicio']);
        } else if (result.isDenied) {
          Swal.close();
        }
      })
    }

    salvarDados() {
      this.loading = true;
      const possuiEmailPrimario = this.emailsTemp.some(email => email.tipoEmail == 1);
      if (!possuiEmailPrimario) {
        Swal.fire({
          icon: 'error',
          text: 'Não é possível salvar os dados sem um e-mail '
        });
        this.loading = false;
        return;
      }

      if (this.emailsApagar.length > 0) {
        const codigosRemovidos: number[] = [];
    
        for (const emailRemovido of this.emailsApagar) {
          if (emailRemovido.codigo !== undefined) {
            codigosRemovidos.push(emailRemovido.codigo);
          }
        }
        this.PerfilContribuinteService.desativarEmails(this.contribuinteId, codigosRemovidos).subscribe(
          (res) => {
            res.forEach((resposta, index) => {
              console.log(`${codigosRemovidos[index]}`, resposta);
            });
            this.emailsApagar = [];
            Swal.fire({
              icon: 'success',
              text: 'Dados Alterados com sucesso'
            }).then(result => {
              if (result.isConfirmed) {
                Swal.close();
              }
            })
          },
          (err) => {
            Swal.fire({
              icon: 'error',
              text: 'Ocorreu um erro ao salvar os dados'
            })
          },
          () => {
            this.loading = false;
          }
        );
      }
    
      if (this.emailAdicionado === true) {
        const dados = {
          contribuinte: this.contribuinteId,
          cadastroEmails: this.emailsTemp
          .filter(email => email.codigo === undefined)
          .map(email => ({
            descricao: email.descricao,
            tipoEmail: email.tipoEmail
          }))
        };
    
        this.PerfilContribuinteService.salvarDados(this.contribuinteId, dados.cadastroEmails).subscribe(
          (res) => {
            console.log('E-mails salvos com sucesso', res);
            Swal.fire({
              icon: 'success',
              text: 'Dados Alterados com sucesso'
            }).then(result => {
              if (result.isConfirmed) {
                Swal.close();
              }
            })
            this.dadoAlterado = false;
            this.getEmailsTabela();
          },
          (err) => {
            console.error('Erro', err);
          },
          () => {
            this.loading = false;
          }
        );
      }
      this.atualizarEmailsLocais()
    }
    
}
