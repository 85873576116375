<section class="margem-top container">
  <h3>Acompanhamento</h3>
  <div class="row">
    <div class="br-input col-3 mt-1">
      <label id="protocolo">Protocolo da solicitação</label>
      <input 
        type="text"
        name="protocolo"
        id="protocolo"
        [(ngModel)]="protocoloFiltro"
        (blur)="buscarProtocolo()"
        maxlength="10" 
      />
      <button class="br-button" type="button" [disabled]="">
        <fa-icon [icon]="['fas', 'search']"></fa-icon>
      </button>
    </div>
    <div class="col-6">
      <button class="br-button secondary mt-5" (click)="limparFiltro()" *ngIf="filtroAtivo">Limpar Filtro</button>
    </div>
    <div class="invalid-feedback d-block ml-4">
      <div *ngIf="protocoloFiltro.length == 0">
        Informe o protocolo da solicitação para pesquisa
      </div>
    </div>
  </div>
  <div class="br-table mt-3">
    <div class="table-header">
      <div class="top-bar">
        <div class="table-title">Solicitações</div>
      </div>
    </div>
    <table>
      <thead>
        <tr>
          <th>Protocolo</th>
          <th>Data Inicial</th>
          <th>Origem</th>
          <th>Solicitado por</th>
          <th>Solicitado para</th>
          <th>Situação</th>
          <th>Histórico</th>
        </tr>
      </thead>
      <tbody *ngIf="!filtroAtivo">
        <tr *ngFor="let item of solicitacoes">
          <td>{{ item.codigo }}</td>
          <td>{{ item.dataBic | date: 'dd/MM/yy' }}</td>
          <td>{{ item.tipoBicOrigem.descricao }}</td>
          <td>{{ item.cadastro.dscNomeCadastro }}</td>
          <td>{{ item.contribuinte.dscRazaoSocial }}</td>
          <td>{{ item.bicSituacoes[0] ? item.bicSituacoes[0].listaSituacaoBicEntity.descricao : '-' }}</td>
          <td><img src="../../../../assets/images/icones/clock-rotate-left-solid.svg" class="imagem" (click)="abrirModal(item.codigo)"></td>
        </tr>
      </tbody>
      <tbody *ngIf="filtroAtivo">
        <tr>
          <td>{{ solicitacoes.codigo }}</td>
          <td>{{ solicitacoes.dataBic | date: 'dd/MM/yy' }}</td>
          <td>{{ solicitacoes.tipoBicOrigem.descricao }}</td>
          <td>{{ solicitacoes.cadastro.dscNomeCadastro }}</td>
          <td>{{ solicitacoes.contribuinte.dscRazaoSocial }}</td>
          <td>{{ solicitacoes.bicSituacoes[0] ? solicitacoes.bicSituacoes[0].listaSituacaoBicEntity.descricao : '-' }}</td>
          <td><img src="../../../../assets/images/icones/clock-rotate-left-solid.svg" class="imagem" (click)="abrirModal(solicitacoes.codigo)"></td>
        </tr>
      </tbody>
    </table>
    <div class="paginacao mt-3" *ngIf="filtroAtivo == false">
      <button class="br-button secondary" (click)="primeiraPagina('solicitacao')" [disabled]="paginaSolicitacao == 0" [ngClass]="{ disabled: paginaSolicitacao == 0, 'botao-proibido': paginaSolicitacao == 0 }">Primeira</button>
      <button class="br-button secondary" (click)="paginaAnterior('solicitacao')" [disabled]="paginaSolicitacao == 0" [ngClass]="{ disabled: paginaSolicitacao == 0, 'botao-proibido': paginaSolicitacao == 0 }">Anterior</button>
      <p class="mt-2 paginas">{{ paginaSolicitacao + 1 }} de {{ quantidadeDePaginasSolicitacao }}</p>
      <button class="br-button secondary" (click)="proximaPagina('solicitacao')" [disabled]="paginaSolicitacao == quantidadeDePaginasSolicitacao - 1" [ngClass]="{ disabled: paginaSolicitacao == quantidadeDePaginasSolicitacao - 1, 'botao-proibido': paginaSolicitacao == quantidadeDePaginasSolicitacao - 1 }">Próxima</button>
      <button class="br-button secondary" (click)="ultimaPagina('solicitacao')" [disabled]="paginaSolicitacao == quantidadeDePaginasSolicitacao - 1" [ngClass]="{ disabled: paginaSolicitacao == quantidadeDePaginasSolicitacao - 1, 'botao-proibido': paginaSolicitacao == quantidadeDePaginasSolicitacao - 1 }">Última</button>
    </div>
  </div>
  <div class="div-botao mt-4">
    <button class="br-button secondary posicao-botao" type="button" [routerLink]="['../../../cadastro/produtor-rural']">Retornar</button>
  </div>
</section>

<div class="backdrop" *ngIf="modalAtivo">
  <div class="div br-modal medium position-modal">
    <div class="br-modal-header">Histórico
    </div>
      <div class="br-modal-body">
        <div class="br-table">
          <div class="table-header">
            <div class="top-bar">
              <div class="table-title">Solicitações</div>
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <th>Situação</th>
                <th>Data início</th>
                <th>Data fim</th>
                <th>Tempo em dias</th>
                <th>Agente Responsável</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of movimentacoes">
                <td>{{ item.listaSituacaoBicEntity.descricao }}</td>
                <td>{{ item.datRegistro | date: 'dd/MM/yyyy' }}</td>
                <td>{{ item.datFim ? (item.datFim | date: 'dd/MM/yyyy') : '-' }}</td>
                <td>{{ item.dias }}</td>
                <td>{{ '-' }}</td>
              </tr>
            </tbody>
          </table>
          <div class="paginacao mt-3">
            <button class="br-button secondary" (click)="primeiraPagina('movimentacao')" [disabled]="paginaMovimentacao == 0" [ngClass]="{ disabled: paginaMovimentacao == 0, 'botao-proibido': paginaMovimentacao == 0}">Primeira</button>
            <button class="br-button secondary" (click)="paginaAnterior('movimentacao')" [disabled]="paginaMovimentacao == 0" [ngClass]="{ disabled: paginaMovimentacao == 0, 'botao-proibido': paginaMovimentacao == 0}">Anterior</button>
            <p class="mt-2 paginas">{{ paginaMovimentacao + 1 }} de {{ quantidadeDePaginasMovimentacao }}</p>
            <button class="br-button secondary" (click)="proximaPagina('movimentacao')" [disabled]="paginaMovimentacao == quantidadeDePaginasMovimentacao - 1" [ngClass]="{ disabled: paginaMovimentacao == quantidadeDePaginasMovimentacao - 1, 'botao-proibido': paginaMovimentacao == quantidadeDePaginasMovimentacao - 1 }">Próxima</button>
            <button class="br-button secondary" (click)="ultimaPagina('movimentacao')" [disabled]="paginaMovimentacao == quantidadeDePaginasMovimentacao - 1" [ngClass]="{ disabled: paginaMovimentacao == quantidadeDePaginasMovimentacao - 1, 'botao-proibido': paginaMovimentacao == quantidadeDePaginasMovimentacao - 1 }">Última</button>
          </div>
        </div>
      </div>
      <div class="br-modal-footer justify-content-center">
        <button class="br-button secondary mr-4" type="button" (click)="fecharModal()">Fechar</button>
      </div>
  </div>
</div>

<div class="carregamento" *ngIf="loading">
  <img src="../../../assets/images/icones/logo-animado.svg">
</div>