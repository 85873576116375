import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

import { environment } from "src/environments/environment";
import { ResponseApi } from "../features/common/interface/responseApi";

@Injectable({
  providedIn: 'root'
})
export class IntegracaoService {

  private apiUrl = environment.API_URL;
  private dadosIntegracaoSubject = new Subject<any>();
  dadosIntegracao$ = this.dadosIntegracaoSubject.asObservable();

  constructor(private http: HttpClient) { }

  integracaoGovBr(code:any, state:any): Observable<ResponseApi> {
    return this.http.get<ResponseApi>(`${this.apiUrl}/integracao/${code}/${state}`);
  }

  sendDadosIntegracao() {
    this.dadosIntegracaoSubject.next();
  }

}
