import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable, forkJoin } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PerfilContribuinteService {

  ApiUrl = environment.API_URL;

  constructor(
    private http: HttpClient
  ) { }

  getEmails(contribuinteId: any): Observable<any[]>{
    const url = `${this.ApiUrl}/contribuinte/emails/listar/${contribuinteId}`;
    return this.http.get<any[]>(url);
  }

  excluirEmail(codigo: number): Observable<any> {
    const url = `${this.ApiUrl}/contribuinte/emails/desativar/${codigo}`;
    return this.http.put(url, {codigo});
  }

  salvarDados(contribuinte: number, cadastroEmails: any[]): Observable<any> {
    const dados = { contribuinte, cadastroEmails };
    return this.http.put<any>(`${this.ApiUrl}/contribuinte/emails/atualizar`, dados);
  }

  desativarEmails(codigoContribuinte: number, codigosEmail: number[]): Observable<any> {
    const requests = codigosEmail.map(codigoEmail => {
      const url = `${this.ApiUrl}/contribuinte/emails/desativar/${codigoContribuinte}/${codigoEmail}`;
      return this.http.put<any>(url, { codigoContribuinte, codigoEmail });
    });
  
    return forkJoin(requests);
  }
}
